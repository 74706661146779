import React, { useEffect, useState } from "react";
import productImportIcon from "../../../assets/product-icon.svg";
import arrowDownIcon from "../../../assets/arrow-down-icon.svg";
import { Button, Dropdown, Input, Space, Spin, Table, Tooltip } from "antd";
import { DownOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import modalDownloadIcon from "../../../assets/modaldownloadicon.svg";
import alertIcon from "../../../assets/alert-icon.svg";
import questionMark from "../../../assets/question-mark-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTemplateView, setcolorPalette, setColorPaletteColors } from "../../../redux/slices/template-slice";

import ColorPalletSelecter from './ColorPalletSelecter';

const ProductSelector = ({
  orderFulfillment,
  setSelectedProduct,
  handleConfirm,
  selectedLanguage,
  setSelectedLanguage,
  successMessage,
  formWarning,
  productLink,
  setProductLink,
  tone,
  setTone,
  productsList,
  selectedProduct,
  productTitle,
  setProductTitle,
  setPagination,
  pagination,
  getTableData,
  isLoading
}) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { colorPalette } = useSelector((store) => store.templateSlice);
  const [selectedColorPalette, setSelectedColorPalette] = useState(
    colorPalette ? colorPalette.charAt(0).toUpperCase() + colorPalette.slice(1) : "Default");

  const ColumnNameTitle = () => {
    return (
      <div className="flex flex-row items-center">
        <span className="w-[10%]">Product Title</span>
        <Input
          placeholder="Search Title"
          value={searchValue}
          onChange={e => {
            const currValue = e.target.value;
            setSearchValue(currValue);
          }}
          suffix={<Spin spinning={isLoading} size="small" />}
        />
      </div>
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setPagination(1)
      getTableData({ currentPage: 1, searchTitle: searchValue.length >= 1 ? searchValue : '' });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchValue]);

  const columns = [
    {
      title: ColumnNameTitle,
      dataIndex: "product_title",
      key: "Product Title",
      width: "60%",
      render: (text, record) => (
        <div className="product-column-title-container">
          <span className="product-title-image-container">
            <img src={record?.product_image} alt="" />
          </span>
          <>{text}</>
          {record.disabled && (
            <Tooltip
              placement="top"
              title="This product is already imported to sofia!"
              color="#FEF3F2"
            >
              <img className="alert-icon" src={alertIcon} alt="alert icon" />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirm();
    }
  };

  const handleMenuClick = (e) => {
    setSelectedLanguage(e.key);
  };
  const handleColorPaleteClick = (e) => {
    setSelectedColorPalette(e.key)
    dispatch(setcolorPalette(e.key));
  }


  const languageDropdownProps = {
    items: [
      {
        key: "English",
        label: "English",
      },
      {
        key: "Spanish",
        label: "Spanish",
      },
    ],
    onClick: handleMenuClick,
  };


  const handlePagination = (move) => {
    console.log(pagination, "pagination===>");
    getTableData({ currentPage: pagination.current + move, searchTitle: searchValue.length >= 1 ? searchValue : '' });
  }

  return (
    <div className="pr-[10px] w-full mb-5">
      <div className="w-full border border-[#E0E0E0] rounded-lg p-4 flex flex-col gap-2">
        <div
          className=
          {`sticky flex flex-row justify-between items-center pr-4 select-none gap-4 ${orderFulfillment ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          onClick={() => orderFulfillment && setCollapsed(!collapsed)}
        >
          <div className="flex flex-row items-start gap-4">
            <img src={orderFulfillment === 'Aliexpress' ? modalDownloadIcon : productImportIcon}
              alt="orderFulfillmentIcon" />
            <div className="modal-content-container">
              <h4 className={!orderFulfillment || !selectedProduct || orderFulfillment === 'Aliexpress' ? 'mt-4' : ''}>
                {orderFulfillment === 'Aliexpress' ? 'Import product' : "Select product to import"}
              </h4>
              {selectedProduct && (
                <p
                  className={'!text-[#6941C6] !font-semibold'}
                >
                  {selectedProduct.product_title}
                </p>
              )}
            </div>
          </div>
          <img
            src={arrowDownIcon}
            alt="arrow down icon"
            className={`w-[20px] ${collapsed && "transform rotate-180"}`}
          />
        </div>
        {(collapsed && orderFulfillment === 'Aliexpress') && (
          <div className="flex flex-row items-start gap-4 ml-[71px]">
            <div>
              <div className="modal-content-container">
                <h4></h4>
                <p>
                  Paste the link of the AliExpress product you want to import.
                </p>
              </div>
              <form
                style={{ gap: "0px" }}
                onSubmit={handleConfirm}
                className="input-form"
              >
                <input
                  id="link-input"
                  value={productLink}
                  onChange={(e) => setProductLink(e.target.value)}
                  style={{ marginTop: "8px" }}
                  className="form-control"
                  type="text"
                  placeholder="https://aliexpress.com/my-product"
                  onKeyDown={handleKeyPress}
                />
                {successMessage && (
                  <span
                    className="add-product-modal-success "
                    style={{ color: "green" }}
                  >
                    {successMessage}
                  </span>
                )}
                {formWarning ? (
                  <span className="add-product-modal-warning  ">
                    {formWarning}
                  </span>
                ) : null}
                <span className="mt-[16px] font-medium">
                  Custom title (optional)
                </span>
                <input
                  id="link-input"
                  value={productTitle}
                  onChange={(e) => setProductTitle(e.target.value)}
                  style={{ marginTop: "8px" }}
                  className="form-control mb-[16px]"
                  type="text"
                  placeholder="Enter Product Title"
                  onKeyDown={handleKeyPress}
                />
                <span className="font-medium">
                  Describe the sales angle for this product
                </span>
                <input
                  id="link-input"
                  value={tone}
                  onChange={(e) => setTone(e.target.value)}
                  style={{ marginTop: "8px" }}
                  className="form-control mb-[16px]"
                  type="text"
                  placeholder="sales angle"
                  onKeyDown={handleKeyPress}
                />
                <span className="mb-[10px] font-medium">Select language</span>
                <Dropdown
                  menu={languageDropdownProps}
                  trigger={["click"]}
                  className="language-selector-dropdown mb-[16px]"
                >
                  <Button>
                    <Space>
                      {selectedLanguage}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <ColorPalletSelecter
                  selectedColorPalette={selectedColorPalette}
                  handleColorPaleteClick={handleColorPaleteClick}
                />

              </form>
            </div>
          </div>
        )}
        {(collapsed && orderFulfillment !== 'Aliexpress') && (
          <div className="modal-content-container wide flex flex-col overflow-scroll w-full p-2">
            <div className="flex flex-row">
              <img className="alert-icon ml-2 mr-2" src={questionMark} alt="question icon" />
              <span className="font-bold">
                {`If you don't see your product, please import the product to your store through ${orderFulfillment} and then come back to this page`}
              </span>
            </div>
            <Table
              onRow={(record) => {
                return {
                  onClick: () => {
                    if (record.disabled) {
                      return;
                    }
                    setSelectedProduct(record);
                    setCollapsed(false);
                  },
                };
              }}
              rowClassName={(record) => {
                if (record.disabled) {
                  return "cursor-not-allowed"
                }
                if (record?.product_id === selectedProduct?.product_id) {
                  return "cursor-pointer !bg-red-50"
                } else {
                  return "cursor-pointer"
                }
              }}
              // pagination={pagination}
              pagination={false}
              // onChange={handlePagination}
              style={{ width: "100%" }}
              columns={columns}
              dataSource={productsList}
              rowKey="product_id"
            />
            {(pagination.hasPreviousPage || pagination.hasNextPage) &&
              (<div className="flex gap-2 items-center justify-end">
                <button disabled={!pagination.hasPreviousPage} onClick={() => handlePagination(-1)} className="h-10 w-10 flex items-center justify-center border border-gray-300 hover:bg-gray-200 transition-all duration-300 rounded-md disabled:opacity-40 disabled:pointer-events-none">
                  <LeftOutlined />
                </button>
                <button disabled={!pagination.hasNextPage} onClick={() => handlePagination(+1)} className="h-10 w-10 flex items-center justify-center border border-gray-300 hover:bg-gray-200 transition-all duration-300 rounded-md disabled:opacity-40 disabled:pointer-events-none">
                  <RightOutlined />
                </button>
              </div>)}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductSelector;

import { signOut } from "aws-amplify/auth";
import { message } from "antd";

export const handleLogout = async (removeShopCookie = false) => {
  try {
    if (removeShopCookie) {
      document.cookie = `shop=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
    }

    await signOut();
    localStorage.removeItem("token");
    localStorage.removeItem("shopName");
    localStorage.removeItem("shopId");
    localStorage.removeItem("email");
    localStorage.removeItem("shop_owner");
    localStorage.removeItem("shopifyConnected");
    localStorage.removeItem("shopName");
    window.location.href = "/";
  } catch (error) {
    console.error("Error logging out:", error);
    message.error(`cannot logout due to: ${error?.mess}`, 2);
  }
};

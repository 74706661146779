const ProTemplateCollpseContentEditComponent = ({
  FAQData,
  setFAQData,
  collapseIndex,
  setSectionClick
}) => {
  console.log('data.prod',collapseIndex, FAQData);
  
  return (
    <>
      <div className="title-container relative">
        <p className="w-[25%]">Collapse Content</p>
        <div
        onClick={() => {
          setFAQData((prev) => {
            const newData = [...prev.data];
            newData.splice(collapseIndex, 1);
            setSectionClick({ ...prev, ["collapseContainerSection"]: false });
            return {
              ...prev,
              data: newData,
            };
          });
        }}
        class="flex w-full justify-right text-center !text-red-600"
      >
        <h4
          class="text-lg font-bold cursor-pointer !text-red-600"
        >
          Delete FAQ
        </h4>
      </div>
      </div>
      <div className="input-main-container">
        <div className="input-container">
          <label className="input-label">Heading</label>
          <input
            className="form-input"
            type="text"
            value={FAQData?.data[collapseIndex]?.title}
            onChange={(e) =>
              setFAQData((prev) => {
                const newData = [...prev.data]; 
                newData[collapseIndex] = { 
                  ...newData[collapseIndex],  
                  title: e.target.value,  
                };
                return {
                  ...prev,
                  data: newData,  
                };
              })
            }
          />
        </div>
        <div className="input-container">
          <label className="input-label">Detail</label>
          <input
            className="form-input"
            type="text"
            value={FAQData?.data[collapseIndex]?.details}
            onChange={(e) =>
              setFAQData((prev) => {
                const data = prev.data;
                data[collapseIndex]={
                  ...data[collapseIndex],
                  details :e.target.value
                };
                return {
                  ...prev,
                  data: data,
                };
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default ProTemplateCollpseContentEditComponent;

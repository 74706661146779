export const processContent = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const paragraphs = tempDiv.getElementsByTagName("p");
  const spans = paragraphs[0].getElementsByTagName("span");
  const allTags = paragraphs[0].querySelectorAll("*");
  Array.from(allTags).forEach((tag) => {
    if (tag.tagName.toLowerCase() !== "span") {
      tag.removeAttribute("class");
    }
  });
  Array.from(spans).forEach((span) => {
    const spanClass = span.className;
    const spanText = span.textContent;
    span.replaceWith(spanText);
    if (!paragraphs[0].classList.contains(spanClass)) {
      if (paragraphs[0].className) {
        paragraphs[0].className += `${spanClass}`;
      } else {
        paragraphs[0].className = spanClass;
      }
    }
  });

  return tempDiv?.innerHTML;
};

import "./Sections.css";
import { Rate } from "antd";

const RatingSectionPreLander = ({
  blockId,
  rating,
  details,
  selectedBlock,
  handleSelectBlock,
}) => {
  return (
    <div
      className={`title-section mb-5 ${selectedBlock === blockId ? "outline-border-selected" : "outline-border1"}`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <div className="flex flex-row items-center">
        <Rate disabled value={rating} className="mt-1"/>
        <span className="ml-3 text-base text-[#0D8390]">{details}</span>
      </div>
    </div>
  )
}

export default RatingSectionPreLander;

import React from "react";

const OrderFulfillmentBlock = ({orderFulfillment, currentFulfillment, handleSelect}) => {
 return (
   <div
     className={`modal-order-fulfillment-block ${currentFulfillment === orderFulfillment.name ? '!border-[#7F56D980]' : ''}`}
     onClick={() => handleSelect(orderFulfillment.name)}
   >
     {orderFulfillment.icon ?
       <img src={orderFulfillment.icon} alt="orderFulfillmentIcon" /> :
       <span>Other</span>
     }

   </div>
 )
}

export default OrderFulfillmentBlock;

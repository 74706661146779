import React, { useEffect, useState } from "react";
import { message, notification, Spin, Table, Tabs } from "antd";
import importIcon from "../../../assets/importIcon.svg";
import "./ProductsStatusTable.css";
import NoDataComponent from "../NoDataComponent";
import AddProductModal from "../AddProductModal";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import TablePagination from "../../Commons/TablePagination";
import currentAuthenticatedUser from "../../../authUser";
import draftArrow from "../../../assets/draftArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setOldIdsOfOfMyProducts,
  setPublishFalse,
} from "../../../redux/slices/publish-product-slice";
import CustomLoader from "../../CustomLoader";
import trashbin from "../../../assets/trashbin.svg";
import copy from "../../../assets/copy.svg";

import linkImg from "../../../assets/link_img.png";
import DeleteProductModal from "./DeleteProductModal";
import CopyProductModal from "./CopyProductModal";
import NoteProductModal from "./NoteProductModal";
import { EditOutlined } from "@ant-design/icons";

import saveIDInLocalStorageOfPublishProduct from "../../../Utilities/saveIDInLocalStorageOfPublishProduct";
import getPublishStatusAndId from "../../../Utilities/getPublishStatusAndId";
import ProductSlotCounterButton from "../ProductSlotCounterButton";
import OpenNotification from "../../OpenNotification/OpenNotification";

const ProductsStatusTable = ({
  subscriptionStatus,
  importedProductsCount,
  showLimitBar,
  handleCheckSubsCriptionPlan,
}) => {
  const { isPublish, currentEditingProductId, publisPayload } = useSelector(
    (store) => store.publishProduct
  );
  // redux fetcher
  const { switchToVariant } = useSelector((store) => store.productVariants);
  const { shopifyShopDetails } = useSelector((store) => store.authUser);
  const { name: shopName } = shopifyShopDetails || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [productCounts, setproductCounts] = useState({
    all: 0,
    draft: 0,
    active: 0,
  });
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [rowsPerPageNumber, setRowsPerPageNumber] = useState(10);
  const [tab, setTab] = useState({ key: 1, name: "all" });
  const [currentTotal, setCurrentTotal] = useState(10);
  const [loading, setLoading] = useState(true);
  const [
    authCheckLoadingForEditingProduct,
    setAuthCheckLoadingForEditingProduct,
  ] = useState(false);
  const [publishingId, setPublishingId] = useState();
  const [showtemplates, setShowTemplates] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    productDetails: {},
  });
  const [openCopyModal, setOpenCopyModal] = useState({
    open: false,
    productDetails: {},
  });

  const [openNoteModal, setOpenNoteModal] = useState({
    open: false,
    productDetails: {},
  });

  const [api, notificationContext] = notification.useNotification();

  // get publishing product details
  useEffect(() => {
    if (products.length > 0) {
      const { id } = getPublishStatusAndId();

      setPublishingId(id !== "" ? id : products[0]?.product_id);
    }
  }, [products]);

  const handleOldIdsRecord = () => {
    const ids = products.map((p) => p.product_id);

    dispatch(setOldIdsOfOfMyProducts(ids));
  };

  const handleEditProduct = async (id) => {
    setAuthCheckLoadingForEditingProduct(true);
    saveIDInLocalStorageOfPublishProduct("edit", id);

    try {
      const response = await callBackendAPI(
        "aliexpress/check-access-validity",
        "GET",
        {}
      );

      if (!response?.status) {
        setAuthCheckLoadingForEditingProduct(false);
        OpenNotification({ api: api });
      } else {
        setAuthCheckLoadingForEditingProduct(false);
        navigate(`/edit-product/${id}`);
      }
    } catch (error) {
      message.error("Authorization failed", error.message);
    }
  };
  const productTabs = [
    {
      key: "1",
      label: (
        <div className="tab-label-container">
          <div>All products</div>
          <span className="products-number-container">
            {productCounts?.all}
          </span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="tab-label-container">
          <div>Published</div>
          <span className="products-number-container">
            {productCounts?.active}
          </span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="tab-label-container">
          <div>Draft</div>
          <span className="products-number-container">
            {productCounts?.draft}
          </span>
        </div>
      ),
    },
  ];
  const columns = [
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "Product ID",
      width: "150px",
    },
    {
      title: "Product Title",
      dataIndex: "product_title",
      key: "Product Title",
      width: "50%",
      render: (text, record) => (
        <div className="product-column-title-container">
          <span className="product-title-image-container">
            <img src={record?.product_image} alt="" />
          </span>
          <>{text}</>
          <span
            onClick={(e) => {
              e.stopPropagation();
              const userName = shopifyShopDetails.name.split(".");
              window.open(
                `https://admin.shopify.com/store/${userName[0]}/products/${record.shopify_product_id}`
              );
            }}
            className="product-link-btn"
          >
            <img src={linkImg} alt="openLink" />
          </span>
        </div>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "Notes ",
      width: "500px",
      render: (text, record) => (
        <div
          className="notes-column-title-container max-w-[300px]"
          onClick={(e) => {
            e.stopPropagation();
            setOpenNoteModal({ open: true, productDetails: record });
          }}
        >
          {text?.trim() ? (
            <>
              {text} <EditOutlined />
            </>
          ) : (
            <span className="!font-medium bg-violet-50 hover:bg-violet-100 duration-300 px-2.5 py-1.5 rounded-md !text-[13px] ">Add Note</span>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "528.5px",
      render: (status, record) =>
        record.product_id === publishingId && isPublish ? (
          <span className="!flex !justify-center !items-center  !gap-2 !w-[230px] !bg-[#F4F6F7] product-active-status ">
            <CustomLoader width="20px" color="#6941C6" />
            {/*  */}
            <p className="!font-semibold !text-[--primary-color]">
              Uploading product...
            </p>
          </span>
        ) : status === "publish" ? (
          <button
            onClick={() => handleEditProduct(record.product_id)}
            className="product-active-status   "
          >
            Published
          </button>
        ) : status === "error_publishing" ? (
          <button
            onClick={() => handleEditProduct(record.product_id)}
            className="product-draft-status !text-[white] !w-[230px] !bg-[#d81159]"
          >
            Error when publishing
          </button>
        ) : (
          <button
            onClick={() => handleEditProduct(record.product_id)}
            className="product-draft-status"
          >
            Draft
            <span className="product-draft-status-edit">
              Edit <img src={draftArrow} alt="" />
            </span>
          </button>
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "150px",
      render: (status, record) => (
        <div className="flex gap-2 items-center">
          <span
            onClick={(e) => {
              e.stopPropagation();
              setOpenCopyModal({ open: true, productDetails: record });
            }}
            className="product-copy-btn !border-violet-400 hover:!border-violet-700"
          >
            <CopyOutlined strokeWidth={2} className=" text-violet-700" />
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteModal({ open: true, productDetails: record });
            }}
            className="product-delete-btn"
          >
            <img src={trashbin} alt="" />
          </span>
        </div>
      ),
    },
  ];
  const onTabChange = (key) => {
    if (key === "1") {
      setTab({ ...tab, key: "1", name: "all" });
    } else if (key === "2") {
      setTab({ ...tab, key: "2", name: "publish" });
    } else if (key === "3") {
      setTab({ ...tab, key: "3", name: "draft" });
    }

    setPaginationNumber(1);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updateAble";

  useEffect(() => {
    if (shopName && shopName !== "") {
      if (showLimitBar) {
        if (!isPublish) {
          fetchProducts();
        } else {
          fetchProducts();
        }
      }
    } else {
      setLoading(false);
    }
  }, [tab, paginationNumber, rowsPerPageNumber, shopName, showLimitBar]);
  useEffect(() => {
    if (shopName && shopName !== "") {
      handleCheckSubsCriptionPlan();
    } else {
      setLoading(false);
    }
  }, [shopName]);
  useEffect(() => {
    if (!loading && isPublish) {
      if (switchToVariant == "Yes") {
        publishProduct();
      } else {
        dispatch(setPublishFalse());
      }
    }
  }, [loading]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  // when the product is published
  const publishProduct = async () => {
    try {
      const response = await callBackendAPI(
        "template/v1_upload_images_onShopify",
        "POST",
        {},
        {
          payload: publisPayload,
        }
      );

      if (response?.status) {
        messageApi.open({
          key: "success",
          type: "success",
          content: "Successfully uploaded!",
          duration: 4,
        });
      } else {
        messageApi.open({
          key: "error",
          type: "error",
          content: "could not upload!",
          duration: 4,
        });
      }
    } catch (error) {
      messageApi.open({
        key: "error",
        type: "error",
        content: "could not upload!",
        duration: 4,
      });
      console.error("Error:", error);
    } finally {
      window.removeEventListener("beforeunload", alertUser);
      messageApi.destroy(key);
      dispatch(setPublishFalse());
    }
  };
  //saved products fetcher method
  const fetchProducts = async () => {
    if (isPublish) {
      // messageApi.open({
      //   key,
      //   type: 'loading',
      //   content: 'Uploading product...',
      //   duration: 100000
      // });
      // avoid unexpected closing the tab and browser
      window.addEventListener("beforeunload", alertUser);
    }
    setLoading(true);
    setProducts([]);
    const { userId } = await currentAuthenticatedUser();
    try {
      const response = await callBackendAPI(
        "product/get_saved_products",
        "GET",
        {
          userId: userId,
          shop: shopName,
          status: tab.name,
          page: paginationNumber,
          limit: rowsPerPageNumber,
        }
      );

      if (response?.status) {
        switch (tab.name) {
          case "all":
            setCurrentTotal(response?.counts?.all);
            break;
          case "active":
            setCurrentTotal(response?.counts?.publish);
            break;
          case "draft":
            setCurrentTotal(response?.counts?.draft);
            break;
        }

        setproductCounts(response?.counts);

        const newProducts = response?.data?.map((product) => ({
          product_id: product?.id,
          product_title: product?.product_title,
          status: product?.status,
          product_image: product?.product_image,
          is_from: product?.is_from,
          shopify_product_id: product?.product_id,
          external_product_id: product?.external_product_id,
          notes: product?.notes,
        }));
        setProducts(newProducts);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // setRetry(true)
    }
  };
  const handleDeleteProductFrontend = () => {
    const { productDetails } = openDeleteModal;
    setProducts((prev) => {
      return prev.filter((p) => p.product_id !== productDetails.product_id);
    });

    fetchProducts();
    setOpenDeleteModal({ open: false, productDetails: {} });
  };

  const handleCopyProductFrontend = () => {
    fetchProducts();
    setOpenCopyModal({ open: false, productDetails: {} });
  };

  const handleNoteProductFrontend = () => {
    fetchProducts();
    setOpenNoteModal({ open: false, productDetails: {} });
  };

  const antIcon = (
    <LoadingOutlined
      className="custom-spin-icon"
      style={{ fontSize: "50px" }}
      spin
    />
  );

  const handleImportButtonClick = async () => {
    if (loading) {
      message.error("Please wait for the products to load");
      return;
    }

    if (importedProductsCount >= 5 && !subscriptionStatus) {
      window.location.href = "/subscriptions";
      return;
    }

    saveIDInLocalStorageOfPublishProduct("importing", "");

    setShowAddProductModal(true);
    // here will be the data about templates
    setShowTemplates(true); //this will be set based on the package of the user
  };

  return (
    <div className="products-table-main-container">
      {contextHolder}
      {notificationContext}
      {tab && (
        <Tabs defaultActiveKey="1" items={productTabs} onChange={onTabChange} />
      )}

      <div className="import-product-btn-row">
        {shopName &&
        shopName !== "" &&
        showLimitBar &&
        !loading &&
        subscriptionStatus !== null &&
        !subscriptionStatus ? (
          <ProductSlotCounterButton allProductCount={importedProductsCount} />
        ) : (
          <div></div>
        )}
        <button
          className={`${
            !showLimitBar && "cursor-not-allowed"
          } import-product-btn`}
          type="button"
          onClick={handleImportButtonClick}
          disabled={!showLimitBar}
        >
          {"Import product"}
          <img src={importIcon} alt="icon" />
        </button>
      </div>

      <Spin
        indicator={antIcon}
        spinning={loading || authCheckLoadingForEditingProduct}
        style={{ width: "100%" }}
      >
        <div style={{ width: "100%" }}>
          <Table
            onRow={(record) => {
              return {
                onClick: () => {
                  if (
                    currentEditingProductId === record.product_id &&
                    isPublish
                  ) {
                    return;
                  }
                  handleEditProduct(record.product_id);
                },
              };
            }}
            pagination={false}
            style={{ width: "100%" }}
            rowClassName={(record) =>
              record.product_id === currentEditingProductId && isPublish
                ? " !cursor-not-allowed"
                : "table-row"
            }
            columns={columns}
            dataSource={products}
            locale={{
              emptyText: (
                <NoDataComponent
                  authorizationCheckLoading={false}
                  handler={handleImportButtonClick}
                  showLimitBar={showLimitBar}
                />
              ),
            }}
            rowKey="product_id"
          />

          {currentTotal > 0 ? (
            <TablePagination
              total={currentTotal}
              pageSize={rowsPerPageNumber}
              currentPage={paginationNumber}
              onPageChange={setPaginationNumber}
              onPageSizeChange={setRowsPerPageNumber}
            />
          ) : (
            ""
          )}
        </div>
      </Spin>

      <AddProductModal
        fetchProducts={fetchProducts}
        OpenModal={showAddProductModal}
        setOpModal={setShowAddProductModal}
        handleOldIdsRecord={handleOldIdsRecord}
        showtemplates={showtemplates}
        subscriptionStatus={subscriptionStatus}
      />
      {openDeleteModal?.open && (
        <DeleteProductModal
          handleDeleteFromEnd={handleDeleteProductFrontend}
          OpenModal={openDeleteModal}
          setOpModal={setOpenDeleteModal}
        />
      )}

      {openCopyModal?.open && (
        <CopyProductModal
          handleCopyFromEnd={handleCopyProductFrontend}
          OpenModal={openCopyModal}
          setOpModal={setOpenCopyModal}
        />
      )}

      {openNoteModal?.open && (
        <NoteProductModal
          handleNoteFromEnd={handleNoteProductFrontend}
          OpenModal={openNoteModal}
          setOpModal={setOpenNoteModal}
        />
      )}
    </div>
  );
};

export default ProductsStatusTable;

import React, { useState, useEffect } from "react";
import { Modal, Tabs, Upload, Spin, message } from "antd";
import uploadicon from "../../../../assets/uploadIcon.svg";
import { callBackendAPI } from "../../../../helpers/apiCalls";
import "./ImagesGalleryModal.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setMediaImages,
} from "../../../../redux/slices/product-variants-slice";

const ProTemplateImageEditModal = ({
  openModal,
  setIsImagesModalVisible,
  multipleFlag,
  trueKey,
  sectionsImages,
  setSectionsImages,
  imageindex,
  setProductImages,
  setMultipleFlag,
  productImageFlag,
  toWhich
}) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const { mediaImages } = useSelector(
    (store) => store?.productVariants
  );
  const { staticMediaImages } = useSelector(
    (store) => store?.staticMediaImagesSlice
  );

  useEffect(() => {
    const modal = document.querySelector('.ant-modal-content')
    if (modal) {
      modal.setAttribute('style', 'padding: 20px !important;');
    }
  }, [openModal])
  const dispatch = useDispatch();

  const mappingArray = Array.isArray(staticMediaImages) ? staticMediaImages : Object.values(staticMediaImages);

  const handleOk = () => {
    if (selectedImages.length > 0) {
      if (trueKey) {
        setSectionsImages({ ...sectionsImages, [trueKey]: selectedImages[0] });
        setIsImagesModalVisible(false);
      } else {
        if (toWhich === "carousel") {
          setProductImages((prev) => {
            const newArray = [...prev];
            newArray[imageindex] = {
              ...newArray[imageindex],
              img: selectedImages[0]
            };
            return newArray;
          });
        } else if (toWhich === "textOnImage") {
          setProductImages(prev => ({ ...prev, img: selectedImages[0] }))
        } else if (toWhich === "reviews") {
          setProductImages(prev => {
            const previousData = { ...prev }
            previousData.reviewImages[imageindex].img = selectedImages[0]
            return previousData
          });
        }
        setIsImagesModalVisible(false);
      }
    }
  };

  const handleCancel = () => {
    setIsImagesModalVisible(false);
    setSelectedImages([]);
    setActiveTab("1");
    setMultipleFlag(false);
  };

  const uploadImages = async () => {
    if (!loading) {
      setLoading(true);
      const images = [];
      for (let file of defaultFileList) {
        const base64Image = await readFileAsBase64(file.originFileObj);
        images.push({ image: base64Image, fileType: file.type });
      }
      setLoading(true);
      const endpoint = "template/v1-upload-images-r2";
      const method = "POST";
      const payload = {
        payload: {
          id: parseInt(id),
          images: images,
        },
      };

      try {
        const response = await callBackendAPI(endpoint, method, {}, payload);
        if (response?.status) {
          images.length = 0;
          setLoading(false);
          if (productImageFlag) {
            if (toWhich === "carousel") {

              setProductImages((prev) => {
                dispatch(setMediaImages(response?.data));
                return prev.map((item, index) => index === imageindex ? { ...item, img: response?.data[0] } : item
                );
              });
            } else if (toWhich === "textOnImage") {
              setProductImages(prev => ({ ...prev, img: response?.data[0] }))

            } else if (toWhich === "reviews") {
              setProductImages(prev => {
                const previousData = { ...prev }
                previousData.reviewImages[imageindex].img = response?.data[0]
                return previousData
              });
            }
            setDefaultFileList([]);
            handleCancel();
          }
          message.success("Image uploaded  successfully...", 2);
        }
      } catch (error) {
        setLoading(false);
        message.error(`Cannot upload due to ${error.message}`);
      }
    }
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const base64String = reader.result.toString().split(",")[1];
          resolve(base64String);
        } else {
          reject(new Error("Failed to read file"));
        }
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSelectImage = (newImage) => {
    if (selectedImages.includes(newImage)) {
      setSelectedImages((prevArray) =>
        prevArray.filter((item, index) => item !== newImage)
      );
    } else {
      if (multipleFlag === true) {
        setSelectedImages([...selectedImages, newImage]);
      } else {
        setSelectedImages([newImage]);
      }
    }
  };

  const handleOnChange = ({ file, fileList, event }) => {
    if (multipleFlag) {
      setDefaultFileList(fileList);
    } else {
      setDefaultFileList([file]);
    }
  };

  const tabItems = [
    {
      key: "1",
      label: "AliExpress",
      children: (
        <div className="shopify-images-container">
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Spin/>
            </div>
          ) : (
            mappingArray?.map((image, index) => {
              const isSelected = selectedImages.includes(image);
              return (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSelectImage(image)}
                  key={index}
                  src={image}
                  alt={`aliexpress ${index}`}
                  className={`gallery-image ${isSelected ? "outline-border-selected" : ""
                  }`}
                />
              );
            })
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "Media Images",
      children: (
        <div className="aliexpress-images-container">
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Spin/>
            </div>
          ) : (
            mediaImages?.map((image, index) => {
              const isSelected = selectedImages.includes(image);
              return (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSelectImage(image)}
                  key={index}
                  src={image}
                  alt={`media ${index}`}
                  className={`gallery-image ${isSelected ? "outline-border-selected" : ""
                  }`}
                />
              );
            })
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: "Upload",
      children: (
        <Upload.Dragger
          name="files"
          accept=".jpg, .jpeg, .png"
          multiple={multipleFlag}
          onChange={handleOnChange}
          showUploadList={false}
          customRequest={uploadImages}
          fileList={[]}
        >
          {loading ? (
            <Spin/>
          ) : (
            <>
              <p className="ant-upload-drag-icon">
                <img src={uploadicon} alt="upload"/>
              </p>
              <p className="drap-and-drop-text-p">
                <span>Click to upload </span>
                or drag and drop
              </p>
            </>
          )}
        </Upload.Dragger>
      ),
    },
  ];

  return (
    <Modal
      open={openModal}
      title="Select Media"
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      closable={false}
      footer={
        <div className="custom-modal-footer">
          <div className="selected-images-count"></div>
          <div className="custom-modal-footer-rigt-container">
            <button
              key="cancel"
              onClick={handleCancel}
              className="custom-modal-button custom-modal-button-cancel-white">
              Cancel
            </button>
            <button
              key="confirm"
              type="primary"
              onClick={handleOk}
              disabled={!selectedImages}
              className="custom-modal-button custom-modal-button-primary-purple"
            >
              Insert
            </button>
          </div>
        </div>
      }
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems}/>
    </Modal>
  );
};

export default ProTemplateImageEditModal;

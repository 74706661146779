import "./Sections.css";

const ButtonSection = ({
  blockId,
  text,
  url,
  style,
  selectedBlock,
  handleSelectBlock,
  disabled,
  className = '',
  classNameText = '',
}) => {
  return (
    <div
      className={`title-section mb-5 ${className} ${!disabled && (selectedBlock === blockId ? "outline-border-selected" : "outline-border1")}`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <button
        style={style}
        className={`button-pre-lander ${classNameText}`}
      >
        {text}
      </button>
    </div>
  )
}

export default ButtonSection;


import "./Sections.css";

const TitleSection = ({
  blockId,
  settings,
  text,
  selectedBlock,
  handleSelectBlock,
}) => {
  return (
    <div
      className={`title-section mb-5 ${selectedBlock === blockId ? "outline-border-selected" : "outline-border1"}`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <h3 style={{ color: settings.text_color, fontSize: `${settings.text_size}px`}}>{settings.title}</h3>
    </div>
  )
}

export default TitleSection;

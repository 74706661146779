import React, { useEffect, useState } from "react";
import editpencil from "../../../../assets/editpencil.svg";
import ImagesGalleryModal from "../ImagesGalleryModal";
import { callBackendAPI } from "../../../../helpers/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import {
  setToUpdateImageDetails,
  updateVariantImageFromCanvaEdit,
} from "../../../../redux/slices/product-variants-slice";
import LazyBackgroundImg from "../../../LazyBackgroundImg";
import { Spin } from "antd";
import getUpdatedArraysAfterCanvaEditing from "../../../../Utilities/getUpdatedArraysAfterCanvaEditing";
import canvaIcon from "../../../../assets/canva.svg";

const SectionEditImageContainer = ({
  productImages,
  setProductImages,
  imageUrl,
  sectionsImages,
  setSectionsImages,
  trueKey,
  fromPrelander,
  setReviews,
  setTestimonials,
  setTextOnImageDetails,
  handleChangePageData,
  selectedEditBlock,
  dataType,

  aliexpressImages,
  isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  toUpdateImageDetails,
}) => {

  const dispatch = useDispatch();
  const { templateType } = useSelector((store) => store.templateSlice);
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);

  const [multipleFlag, setMultipleFlag] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleImageEdit = () => {
    if (isEditCanvaLoading) return;
    setIsImagesModalVisible(true);
    setMultipleFlag(false);
  };

  const handleCanva = () => {
    if (isEditCanvaLoading) return;
    else if (awaitingRefresh) {
      setIsEditCanvaLoading(true);

      callBackendAPI("canva/design", "GET", {
        designId: awaitingRefresh.designId,
        update: true,
      })
        .then((response) => {
          if (response.status === "success") {
            if (response.accessToken) {
              localStorage.setItem("canva_access_token", response.accessToken);
            }
            // setSectionsImages({ ...sectionsImages, [toUpdateImageDetails?.key]: response?.url });
            console.log(" <= Canva return success in section images => ");
            // update in all matching places
            const prevUrl = toUpdateImageDetails?.imageUrl;
            const newUrl = response?.url;

            if (fromPrelander) {
              handleChangePageData(selectedEditBlock, dataType, newUrl, 'image');
            }

            const { updatedProductImages, updatedSectionsImages } =
              getUpdatedArraysAfterCanvaEditing({
                productImages,
                sectionsImages,
                imageUrl: prevUrl,
                updatedUrl: newUrl,
              });
            if (templateType === "pro") {
              setTestimonials((prevTestimonials) =>
                prevTestimonials.map((testimonial) =>
                  testimonial.img === prevUrl
                    ? { ...testimonial, img: newUrl }
                    : testimonial
                )
              );
              setReviews((prevReviews) => ({
                ...prevReviews,
                reviewImages: prevReviews.reviewImages.map((reviewImage) =>
                  reviewImage.img === prevUrl
                    ? { ...reviewImage, img: newUrl }
                    : reviewImage
                ),
              }));
              setTextOnImageDetails((prevDetails) => ({
                ...prevDetails,
                img: prevDetails.img === prevUrl ? newUrl : prevDetails.img,
              }));
            }
            setProductImages(updatedProductImages);
            setSectionsImages(updatedSectionsImages);
            // if (trueKey === "section6Image") {
            //   setTextOnImageDetails((prev) => ({
            //     ...prev,
            //     img: response?.url,
            //   }));
            // }
            dispatch(
              updateVariantImageFromCanvaEdit({
                existingUrl: toUpdateImageDetails?.imageUrl,
                newUrl: response?.url,
              })
            );
          }
          setAwaitingRefresh(false);
        })
        .finally(() => {
          setIsEditCanvaLoading(false);
        });
      return;
    }

    if (isCanvaConnected) {
      setIsEditCanvaLoading(true);

      const image = new Image();

      image.onload = function () {
        callBackendAPI("canva/edit", "GET", {
          imageUrl,
          width: image.width,
          height: image.height,
        })
          .then((response) => {
            console.log("resopnse ===>", response);

            if (response.status === "success") {
              dispatch(
                setToUpdateImageDetails({
                  index: 0,
                  to: "sectionImages",
                  key: trueKey,
                  imageUrl,
                })
              );

              if (response.accessToken) {
                localStorage.setItem(
                  "canva_access_token",
                  response.accessToken
                );
              }
              setAwaitingRefresh({
                designId: response.designId,
                imageUrl,
              });
              console.log(
                " section images correlation state:",
                imageUrl.match(
                  /(?:alicdn\.com|sofia-app\.io).*\/(.*?)\./
                )?.[1] ||
                  imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*)$/)?.[1]
              );

              window.open(
                `${response.editUrl}&correlation_state=${
                  imageUrl.match(
                    /(?:alicdn\.com|sofia-app\.io).*\/(.*?)\./
                  )?.[1] ||
                  imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*)$/)?.[1]
                }`,
                "_blank"
              );
            } else {
              alert("Please reconnect to Canva");
            }
          })
          .catch((error) => {
            console.log(error);
            alert("Please reconnect to Canva");
          })
          .finally(() => {
            setIsEditCanvaLoading(false);
          });
      };

      image.src = imageUrl;
    } else {
      alert("Please connect to Canva first");
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setAwaitingRefresh(false);
  }, [imageUrl]);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Spin spinning={isEditCanvaLoading}>
        <LazyBackgroundImg
          img={imageUrl}
          className={`image-edit-container ${
            isEditCanvaLoading && "flex justify-center items-center opacity-30"
          }`}
        >
          {
            // isEditCanvaLoading ? (
            //   <CustomLoader width="30px" color="blue" />
            // ) :
            isHovered && (
              <div className="background-image-overlay-edit-image-section ">
                <div className="edit-image-upper-portion flex !justify-between flex-col h-[100%] ">
                  <button
                    className="image-edit-button !h-[38px]"
                    onClick={handleImageEdit}
                  >
                    <img src={editpencil} alt="edit" />
                  </button>
                  {!!isCanvaConnected &&
                    (imageUrl.includes("app.sofia-app.io") ||
                      imageUrl.includes("sofia-app.io") ||
                      imageUrl.includes("alicdn.com")) && (
                      <button
                        className="gallery-image-edit-button bg-white !w-auto !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7] "
                        onClick={handleCanva}
                      >
                        <img
                          className="m-[8px]"
                          width="125px"
                          src={canvaIcon}
                          alt="Sync Canva edits"
                        />
                        {awaitingRefresh ? (
                          <p className="text-[13px] font-semibold">
                            Sync Canva edits
                          </p>
                        ) : (
                          <p className="text-[13px] font-semibold">
                            Edit in Canva
                          </p>
                        )}
                      </button>
                    )}
                </div>
              </div>
            )
          }

          <ImagesGalleryModal
            multipleFlag={multipleFlag}
            trueKey={trueKey}
            imageUrl={imageUrl}
            setProductImages={setProductImages}
            fromPrelander={fromPrelander}
            handleChangePageData={handleChangePageData}
            selectedEditBlock={selectedEditBlock}
            dataType={dataType}
            sectionsImages={sectionsImages}
            setSectionsImages={setSectionsImages}
            setTextOnImageDetails={setTextOnImageDetails}
            openModal={isImagesModalVisible}
            setIsImagesModalVisible={setIsImagesModalVisible}
            aliexpressImages={aliexpressImages}
            productImages={sectionsImages}
            setMultipleFlag={setMultipleFlag}
            isLoading={isEditCanvaLoading}
          />
          {/* </div> */}
        </LazyBackgroundImg>
      </Spin>
    </div>
  );
};

export default SectionEditImageContainer;

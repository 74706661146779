import React, { useState } from "react";
import OrderFulfillmentBlock from "./OrderFulfillmentBlock";
import orderFulfillmentIcon from "../../../../assets/order_fulfillment_icon.svg";
import arrowDownIcon from "../../../../assets/arrow-down-icon.svg";
import autodsIcon from "../../../../assets/autods-logo.svg";
import dsersIcon from "../../../../assets/dsers-logo.svg";
import zenDropIcon from "../../../../assets/zendrop-logo.svg";
import dropiIcon from "../../../../assets/dropi-logo.svg";
import aliexpressIcon from "../../../../assets/aliexpress-logo.svg";

const OrderFulfillments = [
  {
    name: 'AutoDs',
    icon: autodsIcon,
  },
  {
    name: 'DSers',
    icon: dsersIcon,
  },
  {
    name: 'Zendrop',
    icon: zenDropIcon,
  },
  {
    name: 'dropi',
    icon: dropiIcon,
  },
  {
    name: 'Aliexpress',
    icon: aliexpressIcon,
  },
  {
    name: 'Other',
    icon: null,
  },
]

const OrderFulfillmentSelector = ({
  selectedOrderFulfillment,
  setOrderFulfillment,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleOrderFulfillmentSelect = (orderFulfillment) => {
    setOrderFulfillment(orderFulfillment);
    setCollapsed(false);
  };

  return (
    <div className="pr-[10px] w-full mb-5">
      <div className="w-full border border-[#E0E0E0] rounded-lg p-4 flex flex-col gap-2">
        <div
          className="sticky flex flex-row justify-between items-center pr-4 cursor-pointer select-none gap-4"
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className="flex flex-row items-start gap-4">
            <img src={orderFulfillmentIcon} alt="orderFulfillmentIcon" />
              <div className="modal-content-container">
                <h4 className={`${!selectedOrderFulfillment && 'mt-4'}`}>What do you use for order fulfillment?</h4>
                {selectedOrderFulfillment && (
                  <p
                    className={'!text-[#6941C6] !font-semibold'}
                  >
                    {selectedOrderFulfillment}
                  </p>
                )}
              </div>
          </div>
          <img
            src={arrowDownIcon}
            alt="arrow down icon"
            className={`w-[20px] ${collapsed && "transform rotate-180"}`}
          />
        </div>
        {collapsed && (
          <div className="modal-content-container wide flex flex-col overflow-scroll w-full p-2">
            <div className="flex flex-row gap-4 flex-wrap">
              {OrderFulfillments.map((orderFulfillment) => (
                <OrderFulfillmentBlock
                  orderFulfillment={orderFulfillment}
                  currentFulfillment={selectedOrderFulfillment}
                  handleSelect={handleOrderFulfillmentSelect}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderFulfillmentSelector;

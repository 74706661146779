import { createSlice } from "@reduxjs/toolkit"; 

const templateSlice = createSlice({
    name: "templateSlice", 

    initialState: {
        templateType: 'pro',
        colorPalette: 'default',
        colorPaletteColors: {
            "default": {
                "purple":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"55,59,66",
                    button_text_color:"255,255,255",
                    section_1_background:"0, 0, 0",
                    section_1_text_color:"255,255,255"
                },
                "green":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"49,87,44",
                    button_text_color:"255,255,255",
                    section_1_background:"49,87,44",
                    section_1_text_color:"255,255,255"
                },
                "custom":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"55,59,66",
                    button_text_color:"255,255,255",
                    section_1_background:"0, 0, 0",
                    section_1_text_color:"255,255,255"
                },
            },
            "pro": {
                "purple":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"105,65,198",
                    button_text_color:"255,255,255",
                    feature_heading_color:"105,65,198",
                    active_tab_color:"105,65,198"
        
                },
                "green":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"49,87,44",
                    button_text_color:"255,255,255",
                    feature_heading_color:"49,87,44",
                    active_tab_color:"49,87,44"

        
                },
                "custom":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"105,65,198",
                    button_text_color:"255,255,255",
                    feature_heading_color:"105,65,198",
                    active_tab_color:"105,65,198"
                },
            },
            "kids": {
                "purple":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"105,65,198",
                    button_text_color:"255,255,255",
                    feature_heading_color:"105,65,198",
                    dark_section_heading_color:"255,255,255",
                    dark_section_background:"127,86,217",
                    testimonial_section_heading_color:"#FFFFFF",
                    testimonial_section_background:"#7F56D9",

                    testimonial_customer_color:"#7F56D9",

                    sale_section_heading_color:"255,255,255",
                    sale_section_background:"127,86,217",

                    recommend_heading_background:"105,65,198",
                    recommend_heading_color:"255,255,255",

                    
                    steps_section_background:"177,140,217",
                    steps_section_text_color:"255,251,255",
                    shipping_badge_background:"243,237,255"
        
                },
                "green":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"49,87,44",
                    button_text_color:"255,255,255",
                    feature_heading_color:"49,87,44",
                    dark_section_heading_color:"255,255,255",
                    dark_section_background:"49,87,44",
                    testimonial_section_heading_color:"#FFFFFF ",
                    testimonial_section_background:"#31572C",

                    testimonial_customer_color:"#31572C",

                    sale_section_heading_color:"255,255,255",
                    sale_section_background:"49,87,44",
                    
                    recommend_heading_background:"255,255,255",
                    recommend_heading_color:"49,87,44",


                    steps_section_background:"79,119,45",
                    steps_section_text_color:"255,251,255",
                    shipping_badge_background:"236,243,158"
        
                },
                "custom":{
                    text_color:"55,59,66",
                    background_color:"255,255,255",
                    button_background:"105,65,198",
                    button_text_color:"255,255,255",
                    feature_heading_color:"105,65,198",
                    dark_section_heading_color:"255,255,255",
                    dark_section_background:"127,86,217",
                    testimonial_section_heading_color:"#FFFFFF",
                    testimonial_section_background:"#7F56D9",

                    testimonial_customer_color:"#7F56D9",

                    sale_section_heading_color:"255,255,255",
                    sale_section_background:"127,86,217",

                    recommend_heading_background:"105,65,198",
                    recommend_heading_color:"255,255,255",

                    
                    steps_section_background:"177,140,217",
                    steps_section_text_color:"255,251,255",
                    shipping_badge_background:"243,237,255"
        
                },
            }
        },
        customColors:{
            color1:"#ffffff",
            color2:"#ffffff",
            color3:"#ffffff",
            color4:"#ffffff",

        }
    },
    reducers: {
        setCustomColors: (state, action) => {
         
            console.log( action.payload,'in rrrr')
            const { color, selectedIndex } = action.payload;
            // Dynamically update the color based on the selected index
            switch (selectedIndex) {
                case 0:
                state.customColors.color1 = color;
                break;
                case 1:
                state.customColors.color2 = color;
                break;
                case 2:
                state.customColors.color3 = color;
                break;
                case 3:
                state.customColors.color4 = color;
                break;
                default:
                break;
            }
            
        },
        setTemplateView: (state, action) => {
            state.templateType = action.payload
        },
        setcolorPalette: (state, action) => {
            state.colorPalette = action.payload
        },
        setColorPaletteColors: (state, action) => {
            
            //state.colorPaletteColors[state.templateType][state.colorPalette.toLocaleLowerCase()] = action.payload
            const { templateType, colorPalette } = state;
            const updatedColors = action.payload;

            console.log(templateType,'templateType',colorPalette,updatedColors)

            // Only update the specific color property provided in the payload
            if (updatedColors) {
                // Merge the updated color properties into the current color settings
                state.colorPaletteColors[templateType][colorPalette.toLowerCase()] = {
                    ...state.colorPaletteColors[templateType][colorPalette.toLowerCase()],
                    ...updatedColors
                };
            }

            console.log(state.colorPaletteColors[templateType])
        },
        setFullColorPaletteColors: (state, action) => {
            const { templateType, colorPalette } = state;
//console.log(templateType,colorPalette,'p',state.colorPaletteColors[templateType][colorPalette.toLowerCase()])
            state.colorPaletteColors[templateType][colorPalette.toLowerCase()]= action.payload
           // console.log(state.colorPaletteColors,'state.colorPaletteColors')
        }

    }

});

export const {
    setTemplateView,
    setcolorPalette,
    setColorPaletteColors,
    setCustomColors,
    setFullColorPaletteColors
} = templateSlice.actions;

export default templateSlice.reducer;


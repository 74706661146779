import { LoadingOutlined } from "@ant-design/icons";
import {
  setProductDataInState,
  resetProductDataInState,
  setEditViewStyle,
  setSwitchToVariant
} from "../../redux/slices/product-variants-slice";
import { useEffect, useState } from "react";
import { callBackendAPI } from "../../helpers/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { message, Spin } from "antd";
import ProductEditView from "./ProductEditView";
import { useNavigate, useParams } from "react-router";
import { setCountryMethod } from "../../redux/slices/shipping-config-slice";
import ProTemplateEditView from "./ProTemplateEditView";
import { setTemplateView } from "../../redux/slices/template-slice";
import PetsKidsTemplateEditView from "./PetsKidsTemplateEditView";
import { setPreLanderId, setProductHandler } from "../../redux/slices/prelander-page-slice";
import {  setcolorPalette, setColorPaletteColors} from "../../redux/slices/template-slice";

const ProductEditViewLoading = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // const [proTemplate, setProTemplate] = useState(true);
  const { templateType } = useSelector((store) => store.templateSlice);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEditProduct = async (id) => {
    setLoading(true);

    try {
      const response = await callBackendAPI(
        "product/get_product_by_id",
        "GET",
        {
          shop: localStorage?.getItem("shopName"),
          id: id,
        }
      );

      if (response?.status) {
        const { data } = response;
        const { productTemplate, shippingConfiguration } = data;
        const { product_variants } = data;
        const { variantDisplayStyle, color,  colorPaletteColors} = productTemplate;
        dispatch(resetProductDataInState({}));
        dispatch(setProductDataInState(response ? response : {}));
        dispatch(setSwitchToVariant(data.productData.isSwitchVariant));
        dispatch(setPreLanderId(response.prelander_id));
        dispatch(setcolorPalette(data.productTemplate.color))
        dispatch(setProductHandler(data.productHandler));
        // template selection will be here
        dispatch(setTemplateView(response.template_type || response.templateType));

        dispatch(setcolorPalette(color))
        if(colorPaletteColors){
          dispatch(setColorPaletteColors(colorPaletteColors))
        }else{
          dispatch(setColorPaletteColors({}))

        }

        dispatch(
          setCountryMethod(
            shippingConfiguration
              ? shippingConfiguration
              : [{ id: 0, country: "", method: {}, issue: null }]
          )
        );
        
        // dispatch(setTemplateView('PetsAndKids'));

        if (variantDisplayStyle && product_variants.length > 1) {
          const val = variantDisplayStyle === "button" ? "Pills" : "Dropdown";
          dispatch(setEditViewStyle(val));
        } else if (product_variants.length === 1) {
          dispatch(setEditViewStyle(""));
        } else {
          dispatch(setEditViewStyle("Dropdown"));
        }

        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      message.error("Something went wrong!", 4);
      navigate("/");
      // setLoading(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    handleEditProduct(id);
  }, []);

  const antIcon = (
    <LoadingOutlined
      className="custom-spin-icon"
      style={{ fontSize: "50px" }}
      spin
    />
  );

  return loading ? (
    <div className="spinner-overlay">
      <div className="spinner-container">
        <Spin className="custom-spin" indicator={antIcon} />
      </div>
    </div>
  ) : error ? (
    <>
      <h1>Something went Wrong!</h1>
    </>
  ) : templateType==='pro' ? (
     <ProTemplateEditView />
  ) : templateType==='kids' ?(
    <PetsKidsTemplateEditView/>
  ): (
    <ProductEditView />
  );
};
export default ProductEditViewLoading;

// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { setVariantProperties } from "../../redux/slices/product-variants-slice";

const useVariantsData = (productData) => {
  if (productData) {
    const { data } = productData
    // const { product_variants,variantsArrangedIndexesObject } = data
    const { product_variants } = data
    const { variant_properties } = product_variants[0] || [];
    const variantsArrangedIndexesObject = { "39": 0, "40": 1, "41": 2, "42": 3, "43": 8, "44": 9, "Black": 3, "Grey": 0, "khaki": 1 }
    // filtered 'ships from' from properties
    const props = variant_properties?.filter((p) => p.shopify_property_label !== 'Ships From')
    const propertiesArray = props?.map(p => p.shopify_property_label)
    const Skus = product_variants?.map(variant => variant.shopify_sku)
   const  shopifySkusObj = Skus?.reduce((acc, property) => {
      acc[property] = property;
      return acc;
    }, {})
    console.log('SkusSkusSkus',shopifySkusObj);

    // shipping countries array..
    const shipsTo = product_variants?.flatMap(variant =>
      variant?.variant_properties?.filter(property => property.shopify_property_label === 'Ships From').map(property => (property.shopify_property_value))
    );
    const shipsFromArray = [...new Set(shipsTo)]


    //  const propertiesArray = variant_properties.map(p=>p.shopify_property_label)
    //obtained variant Details 2
    const variantsDetails = product_variants?.map((variant) => {
      const { variant_properties } = variant
      const propertyValues = variant_properties?.map((p, i) => p.shopify_property_value)
      return {
        offer_sale_price: variant.offer_sale_price,
        sku_attr: variant.sku_attr,
        sku_id: variant.sku_id,
        sku_price: variant.sku_price,
        sku_stock: variant.sku_stock,
        variant_properties: propertyValues,
      }
    })

    // uniqueValuesObject 3
    let aliexpressSkuObject = {};
    product_variants.forEach((variant, index) => {
      variant?.variant_properties?.forEach(property => {
        if (!aliexpressSkuObject[property.shopify_property_value]) {

          // aliexpressSkuObject[property.shopify_property_value] = variant.aliexpress_sku
          aliexpressSkuObject[property.shopify_property_value] = null
        }
      })
    })
    const uniqueValuesObject = propertiesArray?.length ? propertiesArray.reduce((acc, prop) => {
      const imagesArray = [];
      const values = [];
    
      const v = product_variants?.flatMap?.((variant, index) => {
        return variant?.variant_properties?.filter(property => property.shopify_property_label === prop)
          .map(property => {
            if (property?.image) {
              imagesArray.push({
                shopify_property_value: property.shopify_property_value,
                shopify_property_label: prop,
                image: property.image
              });
            }
            if (!values.includes(property.shopify_property_value)) {
              aliexpressSkuObject[property.shopify_property_value] = index;
              values.push(property.shopify_property_value);
            }
            return property.shopify_property_value;
          });
      });
    
      acc[prop] = values;
      return acc;
    }, { images: [] }) : {};
    
    uniqueValuesObject.images = uniqueValuesObject.images || [];
      
    // Destructuring safely
    const { images = [] } = uniqueValuesObject;
    
    // Function to check if an object has an "image" key
    function hasImageKey(obj) {
      if (obj && typeof obj === 'object') {
        if ('image' in obj) return true;
        return Object.values(obj).some(value => hasImageKey(value));
      }
      return false;
    }
    
    // Creating imagesObject safely
    const imagesObject = images.reduce((acc, item) => {
      const variantHasImage = product_variants?.some(variant =>
        variant.variant_properties?.some(property => hasImageKey(property))
      );
      if (variantHasImage) {
        const color = item.shopify_property_value;
        const image = item.image;
        if (!acc[color]) {
          acc[color] = new Set();
        }
        acc[color].add(image);
      }
      return acc;
    }, {});
        

    const formattedImagesObject = Object.keys(imagesObject).reduce((acc, color) => {
      acc[color] = Array.from(imagesObject[color]);
      return acc;
    }, {});

    const ArrayOfImages = Object.values(formattedImagesObject)
    const productimages = ArrayOfImages.map(image => image[0])
    const newImagesObject = product_variants.reduce((acc, variant) => {
      variant.variant_properties.forEach(property => {
        const { shopify_property_label, shopify_property_value, image } = property;
        if (image) {
          if (!acc[shopify_property_label]) {
            acc[shopify_property_label] = {};
          }

          // Add the value and image (if present) to the corresponding property label object
          // acc[shopify_property_label].push({   [shopify_property_value]:  image || null });
          acc[shopify_property_label][shopify_property_value] = image || null
        }

      });

      return acc;
    }, {});
    const keys = Object.keys(newImagesObject)
    const imageKey = keys[0]

    return { propertiesArray, shipsFromArray, variantsDetails, uniqueValuesObject, formattedImagesObject, productimages, newImagesObject, imageKey, aliexpressSkuObject ,shopifySkusObj}
  }
  else {
    let propertiesArray, variantsDetails, uniqueValuesObject, formattedImagesObject, productimages, newImagesObject, shipsFromArray, aliexpressSkuObject,shopifySkusObj;
    return { propertiesArray: [], variantsDetails: [], uniqueValuesObject: {}, formattedImagesObject: {}, productimages: [], newImagesObject: {}, shipsFromArray: [], aliexpressSkuObject: {},shopifySkusObj:{} }

  }
}
export default useVariantsData

// 5:203078810#4XL;14:193#Black
// 5:203078810#4XL;14:193#Black
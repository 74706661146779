import React from 'react';
import { Space } from 'antd';
import { URLS } from '../../URLS'
import currentAuthenticatedUser from "../../authUser";

// re authorize hanlder
const reAuthorizeAliExpress = async () => {
  // // for ali express
  const appKey = "506940";
  const callbackUrl = URLS.BACKEND_API + URLS.ALIEXP_CALLBACK_URL;

  // message
  const {userId} = await currentAuthenticatedUser();
  window.location.href = `https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${callbackUrl}&client_id=${appKey}&state=${userId}`;
};

const OpenNotification = ({api}) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Space>
      <button
        onClick={() => api.destroy()}
        className={`replace-variant-btn  !bg-transparent !border-[--primary-color] !text-[--primary-color]
                disabled:cursor-not-allowed
                 disabled:text-[#f1edf9] 
                 disabled:bg-[#9e75ff] disabled:border-[#9e75ff]
                 h-[ 40px]
                 `}
      >
        Cancel
      </button>
      <button
        onClick={() => {
          reAuthorizeAliExpress();
          api.destroy();
        }}
        className={`replace-variant-btn 
                disabled:cursor-not-allowed
                 disabled:text-[#f1edf9] 
                 disabled:bg-[#9e75ff] disabled:border-[#9e75ff]
                 h-[ 40px]
                 `}
      >
        Authorize again
        <svg
          style={{transform: "rotate(180deg)"}}
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 5L1.5 5M1.5 5L5 8.5M1.5 5L5 1.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </Space>
  );
  api.error({
    message: "AliExpress Authorization",
    description: "Please Authorize again!",
    btn,
    key,
    placement: "top",
  });
};

export default OpenNotification;

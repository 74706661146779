import React, { useState, useEffect} from "react";
import { Upload, message, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { callBackendAPI } from "../../../../helpers/apiCalls";
import { useParams } from "react-router";
import ColorsPicker  from '../ColorPickerSection/ColorsPicker';
import { useDispatch, useSelector } from "react-redux";

export default function ShippingRiskFreeReturnsComponent({
    productData,
    handleInputChange,
    customBadgeText,
    customTextData,
    setCustomBadgeText,
    handleCustomBadgeChange,
    UploadImage,
    fileList1,
    setFileList1,
    previewImage1,
    setPreviewImage1,
    fileList2,
    setFileList2,
    previewImage2,
    setPreviewImage2,
    fileList3,
    setFileList3,
    previewImage3,
    setPreviewImage3,
    colorsPallet
}) {
      const { colorPalette } = useSelector((store) => store.templateSlice);
    
    // const [badgeText, setBadgeText] = useState({ text1: "", text2: "", text3: "" });

    // useEffect(() => {
    //     console.log('customTextData: useEffect', customTextData);
    //     // Populate initial state with customTextData or default values
    //     setBadgeText({
    //         text1: customBadgeText?.text1 || "Free Shipping",
    //         text2: customBadgeText?.text2 || "60 days risk free",
    //         text3: customBadgeText?.text3 || "Easy Returns",
    //     });
    // }, [customBadgeText]);
    console.log('customBadgeText: shipp', customBadgeText);

    // useEffect(() => {
    //     setCustomBadgeText(badgeText);
    // }, [badgeText])

    const handleBadgeChange = (e, key) => {
        const value = e.target.value;
        setCustomBadgeText((prev) => ({ ...prev, [key]: value }));
        handleCustomBadgeChange(e, key); // Ensure the parent handler is called
    };

    return (
        <div>
            <div className="title-container">Custom Badges</div>
            <div className="input-main-container">
                {/* Badge 1 */}
                <div className="input-container">
                    <label className="input-label">Custom Badge Text 1</label>
                    <input
                        className="form-input"
                        type="text"
                        value={customBadgeText.text1}
                        onChange={(e) => handleBadgeChange(e, "text1")}
                    />
                </div>

                {/* Badge 2 */}
                <div className="input-container">
                    <label className="input-label">Custom Badge Text 2</label>
                    <input
                        className="form-input"
                        type="text"
                        value={customBadgeText.text2}
                        onChange={(e) => handleBadgeChange(e, "text2")}
                    />
                </div>

                {/* Badge 3 */}
                <div className="input-container">
                    <label className="input-label">Custom Badge Text 3</label>
                    <input
                        className="form-input"
                        type="text"
                        value={customBadgeText.text3}
                        onChange={(e) => handleBadgeChange(e, "text3")}
                    />
                </div>


                {colorPalette.toLowerCase()!='default' && ( 
                    <>
                    <ColorsPicker
                        title="Backgournd Color"
                        name="shipping_badge_background"
                        defaultValue={colorsPallet?.shipping_badge_background}
                    />
                    
                   
                    </>
                )}

            </div>
        </div>
    );
}

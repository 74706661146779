import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/Wrapper";
import SubscriptionCard from "./SubcriptionCard";
import "./subscription.css";
import { callBackendAPI } from "../../helpers/apiCalls";
import { message } from "antd";
import CustomLoader from "../CustomLoader/CustomLoader";
import { useNavigate, useSearchParams } from "react-router-dom";

const subscriptionsData = [
  {
    templateName: "Basic",
    price: "Free",

    features: [
      { featureDetails: "Product import" },
      { featureDetails: "AI landing page creator" },
      { featureDetails: "Order Fulfillment" },
      { featureDetails: "Free up to 5 Product imports" },
    ],
  },
  {
    templateName: "Unlimited",
    price: 14.99,
    features: [
      { featureDetails: "Everything included in Free plan" },
      { featureDetails: "Advanced templates" },
      { featureDetails: "Unlimited product imports" },
    ],
  },
];

const Subscriptions = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [selected, setSelected] = useState("Basic");
  const [templateIndex, setTemplateIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const charge_id = searchParams.get("charge_id");
  const packageMessage = searchParams.get("message");

  useEffect(() => {
    console.log("status changeed:", message, charge_id);
    if (packageMessage) {
      if (packageMessage === "paymentSuccessfull") {
        message.success("Successfully subscribed to Pro plan!", 3);
      } else {
        message.error("Could not subscribe to Pro plan!", 3);
      }
      navigate("/subscriptions");
    }
  }, [packageMessage, charge_id]);
  // check previous selected plans
  const handleCheckSubsCriptionPlan = async () => {
    setFetchLoading(true);
    try {
      const endpoint = "store/check_subscription_status/";
      const response = await callBackendAPI(endpoint, "GET", {}, {});
      if (response?.status) {
        setFetchLoading(false);
        setSelected(response.data.length > 0 || response.override ? "Unlimited" : "Basic");
      }
    } catch (error) {
      // setFetchLoading(false);
      console.log("api failure", error);
    } finally {
      setFetchLoading(false);
    }
  };
  useEffect(() => {
    handleCheckSubsCriptionPlan();
  }, []);

  // handle selection change
  const handlePlanSelect = async (templateName, index) => {
    setTemplateIndex(index);
    setLoading(true);

    try {
      const endpoint = `store/plan_subscription_request/?type=${
        templateName === "Basic"
          ? "free"
          : templateName === "Unlimited"
          ? "pro"
          : ""
      }`;
      const payload = {};
      const response = await callBackendAPI(endpoint, "GET", {}, payload);
      if (response?.status) {
        if (templateName == "Basic") {
          if (response.data.length === 0) {
            setSelected("Basic");
            message.success(response.message, 3);
          } else {
            message.error(response.message, 3);
          }
        } else {
          const {
            data: {
              appSubscriptionCreate: { confirmationUrl },
            },
          } = response;

          window.open(confirmationUrl);
        }
        // message.success(`${response.message}`)
        // setSelected(templateName)
      } else {
        message.error(response.message);
        console.log("error while selecting plan", response);
      }
    } catch (error) {
      console.log("api failure", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      {fetchLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CustomLoader width="50px" color="#7f56d9" />
        </div>
      ) : (
        <div className="bg-white">
          <SubscriptionHeading
            {...{
              isMonthly,
              setIsMonthly,
            }}
          />
          <div className="subscription-cards-container">
            {subscriptionsData.map((data, index) => {
              return (
                <SubscriptionCard
                  {...{
                    ...data,
                    selected,
                    setSelected,
                    isMonthly,
                    loading,
                    templateIndex,
                    setTemplateIndex,
                    index,
                    handlePlanSelect,
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </Wrapper>
  );
};
export default Subscriptions;

const SubscriptionHeading = ({ isMonthly, setIsMonthly }) => {
  return (
    <div className="flex flex-col items-center justify-center mb-5">
      {/* Heading Section */}
      <div className="text-center">
        <h1 className="text-2xl font-bold text-gray-800">Welcome back!</h1>
        <p className="text-gray-600 mt-2">
          Pick a plan below and start importing products in minutes
        </p>
      </div>

      {/* *************** Toggle Section for monthly and anually plans don remove it********************/}
      {/*
      <div className="flex items-center space-x-1 bg-white rounded-full  border border-gray-200 mt-6">
                <button
                    className={`px-6 py-2  subscription-monthly-borders ${isMonthly ? "bg-black text-white" : "text-gray-700"
                        }`}
                    onClick={() => setIsMonthly(true)}
                >
                    MONTHLY
                </button>
                <button
                    className={`px-6 py-2 subscription-yearly-borders ${!isMonthly ? "bg-black text-white" : "text-gray-700"
                        }`}
                    onClick={() => setIsMonthly(false)}
                >
                    ANNUAL <span className="text-green-500">(SAVE 25%)</span>
                </button>
      </div> 
      */}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import "react-color-palette/css";
import ColorsPicker from "./ColorsPicker";
const ButtonPickerSection = ({
  colorsPallet
}) => {

  console.log(colorsPallet,'in button s')

  return (
    <>
       <div className="title-container">Select Colors</div>
          <div className="input-main-container">
            <ColorsPicker
              title="Button Text Color"
              name="button_text_color"
              defaultValue={colorsPallet.button_text_color}
            />

          <ColorsPicker
              title="Button Background"
              name="button_background"
              defaultValue={colorsPallet.button_background}
            />

          </div>
    </>
  )
};

export default ButtonPickerSection

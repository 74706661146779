import React, { useEffect, useState } from "react";
import {Modal } from "antd";
import joinImg from '../../../assets/join-popup.jpg'
const JoinModal = ({ OpenModal, setOpModal }) => {

  useEffect(() => {

    if (document.getElementById('link-input')) {
      document.getElementById('link-input').focus()
    }
    const modal = document.querySelector('.ant-modal-content')
    if (modal) {
      modal.setAttribute('style', 'padding: 20px !important;');

    }
  }, [OpenModal]);



  const handleCancel = () => {
    setOpModal({ open: false });

  };
 
  return (
    <div>
      <Modal
        closable={false}
        open={OpenModal}
        onCancel={handleCancel}
        //okButtonProps={{ style: { display: 'none' } }}
        footer={null}
        cancelText="Cancel"
      
        cancelButtonProps={{
          className: "product-modal-cancel-button",
        }}
        width={800}
      >
        <div className="join-product-modal-main-container">
        <p className="modal-header" >Come Hang Out with Us on Discord! 💬</p>
          
          <p className="modal-description" >
            Have a feature in mind that would make Sofia even better? Need help getting the most out of the app? Or just want to chat with other users and the team behind Sofia?
          </p>
          <p className="modal-header" >
            Our Discord community is the place to be. You can:
          </p>
          <ul className="modal-list" >
            <li> Suggest features and improvements</li>
            <li> Get real-time support</li>
            <li> See what we're working on next</li>
            <li> Chat directly with our team</li>
            <li> Connect with other like-minded users</li>
          </ul>
          <p className="modal-header" >
            We'd love to have you there. Join us and be part of shaping the future of Sofia!
            <a href="https://discord.com/invite/tfxu3UnFHK" target="_blank" rel="noopener noreferrer" className="modal-img"  
             onClick={handleCancel}>
            <img className="w-[250px]" src={joinImg} alt="trashbin" />
          
            </a>
          </p>
         
        
        </div>
      </Modal>
    </div>
  );
  // 
};

export default JoinModal;

import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import canvaIcon from '../../../assets/canva.svg'
 

const FirstContentBox = ({
  text,
  heading,
  handleSectionEditChange,
  image,
  section1Image,
  salesTextHeadline1,
  colorsPallet
}) => {
  const [content, setcontent] = useState();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setcontent({
      text: text ,
      heading: heading,
    });
  }, [text, heading, image]);
  const handleSectionImageClick = () => {
    handleSectionEditChange(["section1Image"]);
  };
  const sectionText = DOMPurify.sanitize(content?.text);
  return (
    <div className="first-content-box-main-container">
      <div
        onClick={() =>
          handleSectionEditChange(["salesTextHeadline1", "salesText1"])
        }
       
        className="first-content-box-left-container"
        style={{
          cursor: "pointer",
          ...(colorsPallet.section_1_background && {
            backgroundColor: `rgb(${colorsPallet.section_1_background})`,
          }),
          
        }}

      >
        <div
          onClick={() =>
            handleSectionEditChange(["salesTextHeadline1", "salesText1"])
          }
          style={{ cursor: "pointer" }}
          className={`first-container-left-box ${
            salesTextHeadline1 ? "outline-border-selected" : "outline-border"
          }`}
          id="salesTextHeadline1"
        >
          {content?.heading ? <h3   style={{
             
             ...(colorsPallet.section_1_text_color
               ? {
                   color: `rgb(${colorsPallet.section_1_text_color})`,
                 }
               : {
                   color: "white", // Fallback to white if section_1_text_color is not present
                 }),
           }}>{content?.heading}</h3> : ""}
           {content?.text ? (
            <div   className="edit-formatted-text"
            style={{
             
              ...(colorsPallet.section_1_text_color
                ? {
                    color: `rgb(${colorsPallet.section_1_text_color})`,
                  }
                : {
                    color: "white", // Fallback to white if section_1_text_color is not present
                  }),
            }}
            >
              {/* {content?.text?.split("\n").map((line, index) => (
                <p key={index} className="edit-formatted-line">
                  {line}
                </p>
              ))} */}
              <div className="edit-formatted-line  " dangerouslySetInnerHTML={{ __html: sectionText }}></div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer" }}
        className={`first-content-box-right-container ${
          section1Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
         
          <div onMouseEnter={() => {
            setIsHovered(true);
          }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer',margin:0, height:'600px' }}>
            <img

              // onClick={handleSectionImageClick}
              style={{ cursor: "pointer" }}
              className="first-content-box-right-container-image !mb-0"
              src={image}
              alt=""
            />
            {isHovered && (
              <div
                className="background-gallery-image-overlay-edit-image-section  !rounded-none"
                style={{
                  position: 'absolute',
                 
                }}
              >
                <div className="edit-image-upper-portion !items-center !justify-between"></div>
                <div className="edit-image-lower-portion">
                  <button
                    className="gallery-image-edit-button bg-white !w-auto !py-0 !pl-0 !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7]"
                    onClick={handleSectionImageClick}
                  >
                    <img className="m-[8px]" width="125px" src={canvaIcon} alt="Sync Canva edits" />
                    <p className="text-[13px] font-semibold">Change media</p>
                  </button>
                </div>
              </div>
            )}
          </div>

        )}
      </div>
    </div>
  );
};

export default FirstContentBox;

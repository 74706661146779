import React, { useState } from "react";
import open from "../../assets/plus-collapse.svg";
import close from "../../assets/minus-collapse.svg";
const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    cursor: "pointer",
    backgroundColor: "#f5f5f5",
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  content: {
   
  },
};
const PetsKidsTemplateFAQ = ({
  collapseHeadingSection,
  collapseContainerSection,
  handleSectionEditChange,
  hanldeCollapseContentClick,
  FAQData,
  colorsPalette,
  setProductData
}) => {
  const [openIndex, setOpenIndex] = useState(null); // Track which collapsible is open

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Close if already open, open if not
  };

  return (
    <div className="pro-temp-collapse-container">
      <div
        class="flex w-full !justify-center text-center"
      >
        <h2
          // onClick={() => handleSectionEditChange(["collapseHeadingSection"])}
          // className={`pro-tmp-heading 
          //   ${collapseHeadingSection ? "outline-border-selected" : "outline-border"
          //   }
          //   `}
          className="pro-tmp-heading" 
        >
          Frequently Asked Questions
        </h2>
      </div>

      {FAQData?.map((item, index) => (
        <div
        key={index}
        className={ `p-1 mb-2 ${collapseContainerSection
          ? "outline-border-selected"
          : "outline-border"
        }`}
      onClick={() =>
        hanldeCollapseContentClick({
          keys: ["collapseContainerSection"],
          index,
        })
      }
        >

        <div
          className={ `pets-collapse-outer-con   `}
          
        >
          <Collapsible
            title={item.question}
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index)}
            iconOpen={close}
            iconClose={open}
          >
            <p className="text-[#0008]">{item.answer}</p>
          </Collapsible>
        </div>
        </div>
      ))}
            <div
        class="flex w-full !justify-center text-center pt-4"
      >
        <h4
        onClick={() => {
          setProductData((prev) => ({
            ...prev,
            frequentlyAskedQuestions: [
              ...prev.frequentlyAskedQuestions,
              {
                question: "",
                answer: "",
              },
            ]
          })) 
          setOpenIndex(FAQData?.length)
          hanldeCollapseContentClick({
            keys: ["collapseContainerSection"],
            index: FAQData?.length
          })
        }} 
          class="text-lg font-bold border-solid border-2 border-black cursor-pointer p-2 rounded-md"
        >
          + Add FAQs
        </h4>
      </div>
              <div class="flex justify-center pt-[30px] "><a href="javascript:void(0);" class="custom-content-button"
                onClick={() => handleSectionEditChange(["buttonColors"])}
              style={
                colorsPalette.button_background
                  ? {
                      backgroundColor: `rgb(${colorsPalette.button_background})`,
                      color: `rgb(${colorsPalette.button_text_color})`,
                    }
                  : {}
              }
              >Add to Cart</a></div>

    </div>
  );
};

export default PetsKidsTemplateFAQ;

const Collapsible = ({
  title,
  isOpen,
  onToggle,
  iconOpen,
  iconClose,
  children,
}) => (
  <div className="" style={styles.container}>
    {/* Header with title and toggle icon */}
    <div className="pets-temp-faq-header" onClick={onToggle}>
      <span className="text-[#000]">{title}</span>
      <img
        src={isOpen ? iconOpen : iconClose}
        alt="Toggle Icon"
        style={styles.icon}
      />
    </div>

    {/* Collapsible content */}
    {isOpen && <div className="pets-temp-faq-opened-content-container">{children}</div>}
  </div>
);

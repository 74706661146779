import React, { useEffect, useState } from "react";
import trashbin from "../../../../assets/trashbin.svg";
import editpencil from "../../../../assets/editpencil.svg";
import cameraIcon from "../../../../assets/cameraIcon.svg";
import ImagesGalleryModal from "../ImagesGalleryModal";
import { callBackendAPI } from "../../../../helpers/apiCalls";
import { Spin, Segmented } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setToUpdateImageDetails,
  updateVariantImageFromCanvaEdit,
} from "../../../../redux/slices/product-variants-slice";
import LazyBackgroundImg from "../../../LazyBackgroundImg";
import getUpdatedArraysAfterCanvaEditing from "../../../../Utilities/getUpdatedArraysAfterCanvaEditing";

import canvaIcon from "../../../../assets/canva.svg";
import { useDrag, useDrop } from "react-dnd";
import {
  setSwitchToVariant,
} from "../../../../redux/slices/product-variants-slice";


const GalleryEditImageContainer = ({
  productImages,
  setProductImages,
  sectionsImages,
  setSectionsImages,
  aliexpressImages,

  setReviews,
  setTestimonials,
  setTextOnImageDetails,

  isCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  toUpdateImageDetails,
  imageCache,
}) => {
  console.log(" images array s", productImages, aliexpressImages);
  const [canvaReturn, setCanvaReturn] = useState(false);
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
  const [editImageIndex, setEditImageIndex] = useState();
  const [multipleFlag, setMultipleFlag] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hovered index
  const { templateType } = useSelector((store) => store.templateSlice);
  const {switchToVariant } = useSelector((store) => store.productVariants);

console.log(switchToVariant,'switchToVariantswitchToVariant')
  const dispatch = useDispatch();
  useEffect(() => {}, [productImages]);
  const handleImageEdit = (index) => {
    if (isEditCanvaLoading) return;

    setEditImageIndex(index);
    setIsImagesModalVisible(true);
  };

  const handleCanva = (imageUrl, index) => {
    if (isEditCanvaLoading) return;
    else if (awaitingRefresh) {
      setIsEditCanvaLoading(true);

      callBackendAPI("canva/design", "GET", {
        designId: awaitingRefresh.designId,
        update: true,
      })
        .then((response) => {
          if (response.status === "success") {
            if (response.accessToken) {
              localStorage.setItem("canva_access_token", response.accessToken);
            }
            setCanvaReturn(true);
            console.log(" <= Canva return success in product gallery => ");
            console.log("canva/design", response);
            console.log("awaitingRefresh", awaitingRefresh);

            const prevUrl = toUpdateImageDetails?.imageUrl;
            const newUrl = response?.url;
            const { updatedProductImages, updatedSectionsImages } =
              getUpdatedArraysAfterCanvaEditing({
                productImages,
                sectionsImages,
                imageUrl: prevUrl,
                updatedUrl: newUrl,
              });
            if (templateType === "pro") {
              setTestimonials((prevTestimonials) =>
                prevTestimonials.map((testimonial) =>
                  testimonial.img === prevUrl
                    ? { ...testimonial, img: newUrl }
                    : testimonial
                )
              );
              setReviews((prevReviews) => ({
                ...prevReviews,
                reviewImages: prevReviews.reviewImages.map((reviewImage) =>
                  reviewImage.img === prevUrl
                    ? { ...reviewImage, img: newUrl }
                    : reviewImage
                ),
              }));
              setTextOnImageDetails((prevDetails) => ({
                ...prevDetails,
                img: prevDetails.img === prevUrl ? newUrl : prevDetails.img,
              }));
            }

            setProductImages(updatedProductImages);
            setSectionsImages(updatedSectionsImages);
            dispatch(
              updateVariantImageFromCanvaEdit({
                existingUrl: toUpdateImageDetails?.imageUrl,
                newUrl: response?.url,
              })
            );

          }
          setAwaitingRefresh(false);
        })
        .finally(() => {
          setIsEditCanvaLoading(false);
        });

      return;
    }
    dispatch(
      setToUpdateImageDetails({
        key: "",
        to: "productImages",
        index: index,
        imageUrl,
      })
    );
    if (isCanvaConnected) {
      setIsEditCanvaLoading(true);

      const image = new Image();

      image.onload = function () {
        callBackendAPI("canva/edit", "GET", {
          imageUrl,
          width: image.width,
          height: image.height,
        })
          .then((response) => {
            console.log("product images resopnse=>", response);
            if (response.status === "success") {
              if (response.accessToken) {
                localStorage.setItem(
                  "canva_access_token",
                  response.accessToken
                );
              }
              setAwaitingRefresh({
                designId: response.designId,
                imageUrl,
              });

              console.log(
                " Gallery images correlation state:",
                imageUrl.match(
                  /(?:alicdn\.com|sofia-app\.io).*\/(.*?)\./
                )?.[1] ||
                  imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*)$/)?.[1]
              );

              window.open(
                `${response.editUrl}&correlation_state=${
                  imageUrl.match(
                    /(?:alicdn\.com|sofia-app\.io).*\/(.*?)\./
                  )?.[1] ||
                  imageUrl.match(/(?:alicdn\.com|sofia-app\.io).*\/(.*)$/)?.[1]
                }`,
                "_blank"
              );
            } else {
              alert("Please reconnect to Canva");
            }
          })
          .catch((error) => {
            console.log(error);
            alert("Please reconnect to Canva");
          })
          .finally(() => {
            setIsEditCanvaLoading(false);
          });
      };

      image.src = imageUrl;
    } else {
      alert("Please connect to Canva first");
    }
  };
  const handleImageDelete = (indexToRemove) => {
    if (isEditCanvaLoading) return;

    setProductImages((prevArray) =>
      prevArray.filter((item, index) => index !== indexToRemove)
    );
  };
  const handleAddMediaClick = () => {
    if (isEditCanvaLoading) return;

    setMultipleFlag(true);
    setIsImagesModalVisible(true);
  };
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const moveImage = React.useCallback((dragIndex, hoverIndex) => {
    setProductImages((prevCards) => {
      const clonedCards = [...prevCards];
      const removedItem = clonedCards.splice(dragIndex, 1)[0];
      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);

  return (
    <>
      <p className="input-label" style={{ fontSize: "12px" }}>
        Drag images to reorder them in the gallery. 
      </p>
       <div>
              <h6 className="variant-view-text">Switch to variant image when picked</h6>
              <Segmented
                style={{ width: "100%" }}
                className="variant-view-styles"
                defaultValue={switchToVariant}
                onChange={(value) => dispatch(setSwitchToVariant(value))}
                options={["Yes", "No"]}
              />
            </div>

      <div className="gallery-edit-images-main-box">
        {React.Children.toArray(
          productImages?.map((image, index) => (
            <ImageDragCard moveImage={moveImage} id={index + 1} index={index}>
              <div
                className="aspect-[1/1] above-spinner"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Spin
                  spinning={
                    isEditCanvaLoading && toUpdateImageDetails.index === index
                  }
                >
                  <LazyBackgroundImg
                    cached={imageCache[image]?.src ? true : false}
                    // canvaReturn={toUpdateImageDetails?.index === index && canvaReturn}
                    img={image}
                    key={index}
                    className={`${
                      isEditCanvaLoading &&
                      toUpdateImageDetails.index === index &&
                      "flex justify-center items-center opacity-30"
                    } !h-full !w-full gallery-image-edit-container`}
                  >
                    {hoveredIndex === index && (
                      <div className="background-gallery-image-overlay-edit-image-section">
                        <div className="edit-image-upper-portion !items-center !justify-between">
                          <button
                            className="gallery-image-edit-button"
                            onClick={() => handleImageEdit(index)}
                          >
                            <img src={editpencil} alt="edit" />
                          </button>
                          <button
                            className="gallery-image-delete-button"
                            onClick={() => handleImageDelete(index)}
                            danger
                          >
                            <img src={trashbin} alt="delete" />
                          </button>
                        </div>
                        <div className="edit-image-lower-portion">
                          {!!isCanvaConnected &&
                            (image.includes("app.sofia-app.io") ||
                              image.includes("sofia-app.io") ||
                              image.includes("cdn.shopify.com") ||
                              image.includes("alicdn.com")) && (
                              <button
                                className="gallery-image-edit-button bg-white !w-auto  !py-0 !pl-0 !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7] "
                                onClick={() => {
                                  handleCanva(image, index);
                                }}
                              >
                                <img
                                  className="m-[8px]"
                                  width="125px"
                                  src={canvaIcon}
                                  alt="Sync Canva edits"
                                />
                                {awaitingRefresh ? (
                                  <p className="text-[11px] font-semibold">
                                    Sync Canva edits
                                  </p>
                                ) : (
                                  <p className="text-[13px] font-semibold">
                                    Edit in Canva
                                  </p>
                                )}
                              </button>
                            )}
                        </div>
                      </div>
                    )}
                  </LazyBackgroundImg>
                </Spin>
              </div>
            </ImageDragCard>
          ))
        )}

        <div
          onClick={handleAddMediaClick}
          className="add-image-gallery-container"
        >
          <img src={cameraIcon} alt="Camera" />
          <p>Add media</p>
        </div>
        <ImagesGalleryModal
          aliexpressImages={aliexpressImages}
          multipleFlag={multipleFlag}
          openModal={isImagesModalVisible}
          productImages={productImages}
          setProductImages={setProductImages}
          setIsImagesModalVisible={setIsImagesModalVisible}
          imageindex={editImageIndex}
          setMultipleFlag={setMultipleFlag}
          productImageFlag={true}
        />
      </div>
    </>
  );
};

export default GalleryEditImageContainer;

const ImageDragCard = ({ children, id, index, moveImage }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: "image",
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // moveImage(dragIndex, hoverIndex);
      // item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex !== hoverIndex) {
        moveImage(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  useEffect(() => {
    if (isDragging) {
      document.body.style.setProperty("cursor", "grabbing", "important");
    } else {
      document.body.style.setProperty("cursor", "default", "important");
    }
    return () => {
      document.body.style.setProperty("cursor", "default", "important");
    };
  }, [isDragging]);

  return (
    <div
      ref={ref}
      style={{ opacity, cursor: isDragging ? "grabbing" : "grab" }}
    >
      {children}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";

import { callBackendAPI } from "../../../helpers/apiCalls";
import CustomLoader from "../../CustomLoader";

const NoteProductModal = ({ OpenModal, setOpModal, handleNoteFromEnd }) => {
  const {
    product_id: productId,
    product_title: productName,
    notes,
  } = OpenModal?.productDetails;

  const [loading, setLoading] = useState(false);
  const [note, setProductNote] = useState(notes);

  useEffect(() => {
    if (document.getElementById("link-input")) {
      document.getElementById("link-input").focus();
    }
    const modal = document.querySelector(".ant-modal-content");
    if (modal) {
      modal.setAttribute("style", "padding: 20px !important;");
    }
  }, [OpenModal]);

  const ConfirmButton = () => {
    return (
      <span className="">
        {!loading ? (
          // Render content when not loading
          <div>Save</div>
        ) : (
          <span className="flex justify-center items-center gap-2">
            <p>Saving...</p>
            <CustomLoader width="20px" />
          </span>
        )}
      </span>
    );
  };

  const handleCancel = () => {
    setOpModal({ open: false, productDetails: {} });
    // handleNoteFromEnd();
  };
  const handleConfirm = async () => {
    setLoading(true);
    try {
      // Make API call to backend
      const response = await callBackendAPI(
        "product/save-notes",
        "POST",
        {},
        { payload: { id: productId, notes: note } } // Pass your request body here
      );

      if (!response?.error && response?.product_id) {
        setLoading(false);
        message.success("Notes added successfully!");
      } else {
        setLoading(false);
        return message.error(
          response?.message ||
            "Something went wrong while duplicate the product!"
        );
      }
    } catch (error) {
      setLoading(false);
      console.log("api failed:", error);
      return message.error(`api failed: ${error.message} `);
    } finally {
      handleNoteFromEnd();
      handleCancel();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirm();
    }
  };

  return (
    <div>
      <Modal closable={false} open={OpenModal} footer={null} width={800}>
        <div className="">
          <div className="">
            <h4 className="text-lg font-bold font-sans">
              {notes?.trim() ? "Update" : "Add"} Notes for{" "}
              <span className="note-clr-heading">"{productName}" </span>
            </h4>
            <form
              style={{ gap: "0px" }}
              onSubmit={handleConfirm}
              className="input-form"
            >
              <textarea
                id="link-input"
                value={note}
                onChange={(e) => setProductNote(e.target.value)}
                style={{ marginTop: "8px", height: "150px" }}
                className="form-control font-sans resize-none mb-4"
                type="text"
                placeholder="Enter the product notes here"
                rows="4"
                cols="50"
              />

              <div className="flex items-center gap-4 justify-end">
                <button
                  onClick={handleCancel}
                  type="button"
                  className={`product-modal-cancel-button !w-[150px]  ${
                    loading
                      ? "product-modal-cancel-button-disabled !w-[150px]"
                      : ""
                  }`}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirm}
                  type="button"
                  className={`modal-import-button !w-[150px]  ${
                    loading ? "modal-import-button-disabled !w-[150px]" : ""
                  }`}
                  disabled={loading}
                >
                  <ConfirmButton />
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NoteProductModal;

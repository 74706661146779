import React, { useEffect, useState } from "react";
import { message, Modal, notification } from "antd";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { useNavigate } from "react-router-dom";
import {
  setProductDataInState,
  resetProductDataInState,
  setEditViewStyle,
} from "../../../redux/slices/product-variants-slice";
import { useDispatch, useSelector } from "react-redux";
import { setCountryMethod } from "../../../redux/slices/shipping-config-slice";
import CustomLoader from "../../CustomLoader";
import { setTemplateView, setcolorPalette, setColorPaletteColors, setFullColorPaletteColors} from "../../../redux/slices/template-slice";
import { URLS } from "../../../URLS";
import TemplateSelector from "./TemplateSelector";
import OrderFulfillmentSelector from "./OrderFulfillmentSelector/OrderFulfillmentSelector";
import ProductSelector from "./ProductSelector";
import ProductDetails from "./ProductDetails";
import currentAuthenticatedUser from "../../../authUser";
import OpenNotification from "../../OpenNotification/OpenNotification";
import { colors } from '../../../constantData/colorsData'
import GenerateLanderSelector from "./GenerateLanderSelector/GenerateLanderSelector";
import { setGlobalLanguage, setGlobalTone } from "../../../redux/slices/publish-product-slice";
import { setProductHandler } from "../../../redux/slices/prelander-page-slice";

const AddProductModal = ({
  OpenModal,
  setOpModal,
  fetchProducts,
  handleOldIdsRecord,
  showtemplates,
  subscriptionStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colorPalette, customColors , colorPaletteColors} = useSelector((store) => store.templateSlice);

  const [importButtonLoading, setImportButtonLoading] = useState(false);
  const [productLink, setProductLink] = useState("");
  const [tone, setTone] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("English");


  const [formwarning, setFormwarning] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [template, setTemplate] = useState(null);
  const [orderFulfillment, setOrderFulfillment] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [authorizationCheckLoading, setAuthorizationCheckLoading] = useState(false);
  const [api, notificationContext] = notification.useNotification();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    startCursor: null,
    endCursor:null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getData = async ({currentPage, searchTitle}) => {
    console.log(currentPage,"currentPage55========>", pagination);
    setIsLoading(true)
    let isNext = currentPage > pagination.current ? true : false;
    const userData = await currentAuthenticatedUser();
    if (userData?.userId && orderFulfillment) {
      try {
        console.log(productsList,"productsList===>56");
        const response = await callBackendAPI(
          "shopify/get-products",
          "GET",
          {
            userId: userData.userId,
            page: currentPage === 1 ? null : isNext ? pagination.endCursor : pagination.startCursor,
            perPage: 10,
            searchTitle: searchTitle || "",
            isNext: isNext,
            currentPage: currentPage
          }
          );
        const tableData = response.data.map((data) => ({
          product_title: data.title,
          product_id: data.id,
          product_image: data.imageUrl,
          disabled: data.disabled,
        }))
        setPagination((prevState) => ({...prevState,
                                          current: currentPage,
                                          total: response.count,
                                          startCursor:response.startCursor,
                                          endCursor:response.endCursor,
                                          hasNextPage: response.hasNextPage,
                                          hasPreviousPage: response.hasPreviousPage }))
        setProductsList(tableData);
      } catch (e) {
        setProductsList([]);
        console.log(e);
      }finally {
        setIsLoading(false)
      }
    }
  }

  const checkAliexpress = async () => {
    try {
      setAuthorizationCheckLoading(true);
      const response = await callBackendAPI(
        "aliexpress/check-access-validity",
        "GET",
        {}
      );

      if (!response?.status) {
        OpenNotification({api: api});
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(`Api faied: ${error.message}`);
      setOrderFulfillment(null);
      setOpModal(false);
    } finally {
      setAuthorizationCheckLoading(false);
    }
  }

  useEffect(() => {
    setSelectedProduct(null);
    setProductsList([]);
    if (orderFulfillment === 'Aliexpress') {
      checkAliexpress();
    }
    if (orderFulfillment !== 'Aliexpress') {
      getData({currentPage: 1, searchTitle: ''});
    }
  }, [orderFulfillment]);

  useEffect(() => {
    setProductLink("");
    setFormwarning("");

    if (document.getElementById("link-input")) {
      document.getElementById("link-input").focus();
    }
    const modal = document.querySelector(".ant-modal-content");
    if (modal) {
      modal.setAttribute("style", "padding: 20px !important;");
    }
  }, [OpenModal]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (importButtonLoading) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [importButtonLoading]);

  const ConfirmButton = () => {
    return (
      <span>
        {!importButtonLoading ? (
          "Import product"
        ) : (
          <span className="flex justify-center items-center gap-2">
            <p>Importing product</p>
            <CustomLoader width="20px" />
          </span>
        )}
      </span>
    );
  };

  const handleCancel = () => {
    setOpModal(false);
    setSuccessMessage("");
    setProductLink("");
    setFormwarning("");
    setTemplate(null);
  };

  // importProductHandler
  const importProductHandler = async () => {
    //calling api
    if (orderFulfillment === 'Aliexpress') {
      try {
        const response = await callBackendAPI(
          "aliexpress/v1_fetch_product",
          "GET",
          {
            url: productLink,
            templateType: template.value,
            tone: tone,
            language: selectedLanguage,
            productTitle: productTitle || "",
            is_from: 'Aliexpress',
          }
        );

        if (!response?.error && response?.status) {
          handleOldIdsRecord();
          setProductLink("");
          setFormwarning("");

          const { data } = response;
          const {
            productTemplate,
            product_variants,
            shippingConfiguration,
            variantsArrangedObject,
          } = data;
          const { variantDisplayStyle } = productTemplate;

          dispatch(resetProductDataInState({}));

          dispatch(setGlobalTone(tone));
          dispatch(setGlobalLanguage(selectedLanguage));

          dispatch(
            setCountryMethod(
              shippingConfiguration
                ? shippingConfiguration
                : [{ id: 0, country: "", method: [], issue: null }]
            )
          );
          dispatch(
            setProductDataInState(
              response
                ? {
                  ...response,
                  isFrom: orderFulfillment,
                  variantsArrangedObject: variantsArrangedObject
                    ? variantsArrangedObject
                    : {},
                }
                : {}
            )
          );
          //
          dispatch(setTemplateView(response.templateType));
          // edit view style
          if (variantDisplayStyle !== undefined && product_variants.length > 1) {
            const val = variantDisplayStyle === "button" ? "Pills" : "Dropdown";
            dispatch(setEditViewStyle(val));
          } else {
            dispatch(setEditViewStyle("Dropdown"));
          }
          navigate(`/edit-products/${response?.product_id}`);

          // Close the modal after 2 seconds
          setTimeout(() => {
            fetchProducts();
            setSuccessMessage("");
            setOpModal(false);
          }, 1000);
        } else {
          setFormwarning(response.message);
        }
      } catch (error) {
        setFormwarning(error.message);
        console.error("Error:", error);
      } finally {
        setImportButtonLoading(false);
      }
    } else {
      const userData = await currentAuthenticatedUser();
      try {
        const response = await callBackendAPI(
          "shopify/shopify_fetch_product",
          "GET",
          {
            id: selectedProduct.product_id,
            userId: userData.userId,
            orderFulfillment: orderFulfillment,
            templateType: template.value,
            tone: tone,
            language: selectedLanguage,
            productTitle: productTitle || "",
            importedFrom: 'shopify',
          }
        );

        if (!response?.error && response?.status) {
          handleOldIdsRecord();
          setProductLink("");
          setFormwarning("");

          const { data } = response;
          const {
            productTemplate,
            product_variants,
            shippingConfiguration,
            variantsArrangedObject,
          } = data;
          const { variantDisplayStyle } = productTemplate;

          dispatch(resetProductDataInState({}));
          dispatch(setProductHandler(data.shopify_response.handle));
          dispatch(setGlobalTone(tone));
          dispatch(setGlobalLanguage(selectedLanguage));

          dispatch(
            setProductDataInState(
              response
                ? {
                  ...response,
                  isFrom: orderFulfillment,
                  variantsArrangedObject: variantsArrangedObject
                    ? variantsArrangedObject
                    : {},
                }
                : {}
            )
          );
          //
          dispatch(setTemplateView(response.templateType));
          // edit view style
          if (variantDisplayStyle !== undefined && product_variants.length > 1) {
            const val = variantDisplayStyle === "button" ? "Pills" : "Dropdown";
            dispatch(setEditViewStyle(val));
          } else {
            dispatch(setEditViewStyle("Dropdown"));
          }
          navigate(`/edit-products/${response?.product_id}`);

          // Close the modal after 2 seconds
          setTimeout(() => {
            fetchProducts();
            setSuccessMessage("");
            setOpModal(false);
          }, 1000);
        } else {
          message.error(response.message || "Something went wrong");
          setFormwarning(response.message);
        }
      } catch (error) {
        setFormwarning(error.message);
        message.error(error.message || "Something went wrong");
      } finally {
        setImportButtonLoading(false);
      }
    }

  };
  // check if the user selects the pro template and eligible for it
  const checkValidityOfProTemplate = async () => {
    try {
      const endpoint = "store/check_subscription_status/";
      const response = await callBackendAPI(endpoint, "GET", {}, {});
      if (response?.status) {
        if (response.redirect === true && !response.override) {
          message.error("Choose your plan first");
          navigate("/subscriptions");
        } else {
          importProductHandler();
        }
      } else {
        console.log("error while selecting plan", response);
        setImportButtonLoading(false);
      }
    } catch (error) {
      setImportButtonLoading(false);

      message.error("something went wrong api failed!", error.message);
      console.log("error while selecting plan", error);
      // setLoading(false);
    }
  };

  const hexToRgb = hex =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
               ,(m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16))
      .join(','); // Join the values with commas


  const assignCustomColorToSections = () =>{
   
    if(template.value=='pro'){

      dispatch(setFullColorPaletteColors({
        text_color:"55,59,66",
        background_color:"255,255,255",
        button_background:hexToRgb(customColors.color3),
        button_text_color:"255,255,255",
        feature_heading_color:hexToRgb(customColors.color4),
        active_tab_color:hexToRgb(customColors.color3),
      }))

    }else if(template.value=='kids'){

      dispatch(setFullColorPaletteColors({
        text_color:"55,59,66",
        background_color:"255,255,255",
        button_background:hexToRgb(customColors.color3),
        button_text_color:"255,255,255",
        feature_heading_color:hexToRgb(customColors.color4),
        dark_section_heading_color:hexToRgb(customColors.color1),
        dark_section_background:hexToRgb(customColors.color2),
        testimonial_section_heading_color:customColors.color1,
        testimonial_section_background:customColors.color2,
        testimonial_customer_color:customColors.color4,
        sale_section_heading_color:hexToRgb(customColors.color1),
        sale_section_background:hexToRgb(customColors.color2),
        recommend_heading_background:hexToRgb(customColors.color2),
        recommend_heading_color:hexToRgb(customColors.color1),
        steps_section_background:hexToRgb(customColors.color2),
        steps_section_text_color:hexToRgb(customColors.color1),
        shipping_badge_background:hexToRgb(customColors.color2),
      }))

    }else{

      dispatch(setFullColorPaletteColors({
        text_color:"55,59,66",
        background_color:"255,255,255",
        button_background:hexToRgb(customColors.color3),
        button_text_color:"255,255,255",
        section_1_text_color:hexToRgb(customColors.color1),
        section_1_background:hexToRgb(customColors.color2),
      }))
    
    }
    
    
  }


  const handleConfirm = async () => {
    if (!productLink && orderFulfillment === 'Aliexpress') {
      setFormwarning("Please enter url of product");
    } else if (!template) {
      message.error("Please select a template");
    } else if (orderFulfillment !== 'Aliexpress' && !selectedProduct) {
      message.error("Please select a product");

    }else if (!colors[template.value][colorPalette.toLowerCase()] && colorPalette.toLowerCase()!='default' && colorPalette.toLowerCase()!='custom'){
      console.log(template.value,colorPalette.toLowerCase())
      message.error("Selected template does not have "+colorPalette+" Color Palette");

    } else {
      if(colorPalette.toLowerCase()=='custom'){
        assignCustomColorToSections();
      }
     
      setFormwarning("");
      setImportButtonLoading(true);
      if (template.value !== "default") {
        checkValidityOfProTemplate();
      } else {
        importProductHandler();
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirm();
    }
  };

  const handleMenuClick = (e) => {
    setSelectedLanguage(e.key);
  };


  const languageDropdownProps = {
    items: [
      {
        key: "English",
        label: "English",
      },
      {
        key: "Spanish",
        label: "Spanish",
      },
    ],
    onClick: handleMenuClick,
  };




  return (
    <div style={{ position: "relative" }} className="add-product-modal-wrapper">
      {notificationContext}
      <Modal
        closable={false}
        open={OpenModal}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText={<ConfirmButton />}
        cancelText="Cancel"
        okButtonProps={{
          className: (importButtonLoading || authorizationCheckLoading)
            ? "modal-import-button modal-import-button-disabled"
            : "modal-import-button",
          disabled: importButtonLoading || authorizationCheckLoading,
        }}
        cancelButtonProps={{
          className: importButtonLoading
            ? "product-modal-cancel-button-disabled"
            : "product-modal-cancel-button",
          disabled: importButtonLoading,
        }}
        maskClosable={!importButtonLoading}
        width={1300}
        className="add-product-modal !h-[70vh]"
      >
        {importButtonLoading && <div className="fake-loading-bar"></div>}
        <div className="add-product-modal-main-container !h-[70vh] !overflow-y-scroll !overflow-x-hidden">
          <OrderFulfillmentSelector
            selectedOrderFulfillment={orderFulfillment}
            setOrderFulfillment={setOrderFulfillment}
          />
          <ProductSelector
            orderFulfillment={orderFulfillment}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            productLink={productLink}
            setProductLink={setProductLink}
            successMessage={successMessage}
            formWarning={formwarning}
            tone={tone}
            setTone={setTone}
            handleConfirm={handleConfirm}
            productsList={productsList}
            productTitle={productTitle}
            setProductTitle={setProductTitle}
            setPagination={setPagination}
            pagination={pagination}
            getTableData={getData}
            isLoading={isLoading}
          />
          {orderFulfillment !== 'Aliexpress' && (
            <ProductDetails
              handleConfirm={handleConfirm}
              setTone={setTone}
              tone={tone}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              productTitle={productTitle}
              setProductTitle={setProductTitle}
            />
          )}
          {(orderFulfillment !== "Aliexpress" && !selectedProduct) ?
            <></>
          : (
            <TemplateSelector
              subscriptionStatus={subscriptionStatus}
              template={template}
              setTemplate={setTemplate}
            />
          )}
          <GenerateLanderSelector />
        </div>
      </Modal>
    </div>
  );
};

export default AddProductModal;

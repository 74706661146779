import React from "react";
import { useSelector } from "react-redux";
import ColorsPicker  from '../ColorPickerSection/ColorsPicker';

const ProTemplateFeaturesEditComponent = ({ features, setFeatures,colorsPalette }) => {
    const { colorPalette } = useSelector((store) => store.templateSlice);
  
  // const handleUpdate = ({ key, index, value }) => {
  //   const newContent = [...features.data];
  //   newContent[index][key] = value;
  //   setFeatures(prev=>({ ...prev, data: newContent }));
 
  // };
  const handleUpdate = ({ key, index, value }) => {
    const newContent = [...features.data];
    
    newContent[index] = { 
      ...newContent[index], 
      [key]: value  
    };
  
    setFeatures((prev) => ({
      ...prev,
      data: newContent,
    }));
  };
  return (
    <>
      <div className="title-container">Features</div>
      <div className="input-main-container">
        {features.data.map((item, index) => {
          return (
            <div className="input-container" key={index}>
              <label className="input-label">{`Feature ${index + 1}`}</label>
              <input
                className="form-input"
                type="text"
                value={item.title}
                onChange={(e) =>
                  handleUpdate({ key: "title", index, value: e.target.value })
                }
              />
              <textarea
                rows="6"
                className="form-input"
                value={item.details}
                onChange={(e) =>
                  handleUpdate({ key: "details", index, value: e.target.value })
                }
              />
            </div>
          );
        })}
        {colorPalette.toLowerCase()!='default' && (
         <ColorsPicker
            title="Feature Heading color"
            name="feature_heading_color"
            defaultValue={colorsPalette?.feature_heading_color}
        />
        )}
      </div>
    </>
  );
};

export default ProTemplateFeaturesEditComponent;

import { CameraOutlined } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCustomColors } from "../../redux/slices/template-slice";
import { ColorPicker, Space } from "antd";
function CustomColorPicker({ setEditColor }) {
  const [color, setColor] = useState("#561ecb");

  //   const onChangeCompleteColor = (color: IColor) => {
  //      if(color){
  //       console.log(color)
  //         //setEditColor(`rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`)
  //         setEditColor(`${color.hex}`)
  //     }
  //  }

  const rgbToHex = (rgb) => {
    if (rgb.includes("#")) {
      return rgb;
    } else {
      const result = rgb.match(/\d+/g);
      return result
        ? `#${result.map((x) => (+x).toString(16).padStart(2, "0")).join("")}`
        : "#ffffff";
    }
  };
  useEffect(() => {
    if (color.metaColor) {
      let clr = color.metaColor;
      let rgb = `rgb(${parseInt(clr?.r)},${parseInt(clr?.g)},${parseInt(
        clr?.b
      )})`;
      setEditColor(rgbToHex(rgb));
    }
  }, [color]);
  return (
    <>
      <Space direction="vertical">
        <ColorPicker
          defaultValue={color}
          showText
          onChangeComplete={setColor}
        />
      </Space>

      {/* <ColorPicker color={color} onChange={setColor} hideInput={["rgb", "hsv"]} onChangeComplete={onChangeCompleteColor} /> */}
    </>
  );
}

export default function ImageColorPicker() {
  const [image, setImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedColors, setSelectedColors] = useState([
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
  ]);
  const [selectedColorsLabels, setSelectedColorsLabels] = useState([
    "Content",
    "Background",
    "Accent/Buttons",
    "Titles",
  ]);
  const [hoverColor, setHoverColor] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [editingIndex, setEditingIndex] = useState(null);
  const [editColor, setEditColor] = useState("");
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        imageRef.current &&
        !imageRef.current.contains(event.target) &&
        !event.target.closest(".color-picker-button")
      ) {
        // setSelectedIndex(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getColorFromImage = (event) => {
    if (imageRef.current) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = imageRef.current;
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const rect = img.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCursorPosition({ x: x, y: y });
      const pixel = ctx.getImageData(x, y, 1, 1).data;
      return `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
    }
    return null;
  };

  const rgbToHex = (rgb) => {
    if (rgb.includes("#")) {
      return rgb;
    } else {
      const result = rgb.match(/\d+/g);
      return result
        ? `#${result.map((x) => (+x).toString(16).padStart(2, "0")).join("")}`
        : "#ffffff";
    }
  };

  const handleImageClick = (event) => {
    if (selectedIndex !== null) {
      const color = getColorFromImage(event);
      if (color) {
        setSelectedColors((prev) => {
          const newColors = [...prev];
          newColors[selectedIndex] = rgbToHex(color);
          return newColors;
        });

        console.log(selectedIndex, "selectedIndex");
        dispatch(
          setCustomColors({
            color: rgbToHex(color),
            selectedIndex: selectedIndex,
          })
        );
      }
    }
  };

  const handleSaveColorFromPicker = (color) => {
    if (color && selectedIndex !== null) {
      setSelectedColors((prev) => {
        const newColors = [...prev];
        newColors[selectedIndex] = rgbToHex(color);
        return newColors;
      });

      dispatch(
        setCustomColors({
          color: rgbToHex(color),
          selectedIndex: selectedIndex,
        })
      );
    } else {
      alert("Please select a color first or Invalid Select Index");
    }
  };

  const handleImageHover = (event) => {
    const color = getColorFromImage(event);
    setHoverColor(color);
  };

  const handleMouseLeave = () => {
    setHoverColor(null);
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    //const color = rgbToHex(selectedColors[index]);
    const color = selectedColors[index];
    setEditColor(color);
  };

  const handleSaveEdit = () => {
    if (editColor?.startsWith("#") && editColor.length === 7) {
      setSelectedColors((prev) => {
        const newColors = [...prev];
        newColors[editingIndex] = editColor;
        return newColors;
      });
      dispatch(
        setCustomColors({ color: editColor, selectedIndex: editingIndex })
      );
      setEditingIndex(null);
    } else {
      alert("Please Enter a valid HEX color code");
    }
  };

  return (
    <div
      className="flex flex-col items-start gap-3 relative"
      style={{ marginTop: "15px" }}
    >
      <p className="!text-gray-600 text-sm font-sans">
        Specify colors by thier HEX code or upload your logo to pick colors from
        there.
      </p>

      <div className="flex items-center gap-8 relative">
        <div className="flex flex-col gap-2">
          <figure className="w-[250px] h-[250px] border border-gray-300 rounded-xl relative group">
            {image ? (
              <img
                ref={imageRef}
                src={image}
                alt="Preview"
                className="w-full h-full object-fill cursor-pointer rounded-xl"
                onClick={handleImageClick}
                onMouseMove={handleImageHover}
                onMouseLeave={handleMouseLeave}
              />
            ) : (
              <label
                htmlFor="customLogo"
                className="w-full h-full flex flex-col gap-2 items-center justify-center cursor-pointer duration-300"
              >
                <span className="bg-purple-50 group-hover:bg-purple-200 h-16 w-16 flex items-center justify-center rounded-full duration-300">
                  <CameraOutlined
                    style={{ fontSize: "24px" }}
                    className="text-purple-600 group-hover:text-purple-800"
                  />
                </span>
                <span className="text-sm font-semibold text-purple-600">
                  Colors from Image
                </span>
                <input
                  id="customLogo"
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </label>
            )}
          </figure>
          {image && (
            <label className=" bg-violet-100 text-violet-700 hover:bg-violet-200 duration-300 px-2.5 py-1.5 rounded-md !text-[13px] font-semibold cursor-pointer flex items-center justify-center gap-2">
              Choose Image
              <input
                id="customLogo"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleImageChange}
              />
            </label>
          )}
        </div>

        {hoverColor && selectedIndex !== null ? (
          <div
            className="absolute w-6 h-6 border border-gray-700 rounded-full pointer-events-none"
            style={{
              backgroundColor: hoverColor,
              left: cursorPosition.x + 10,
              top: cursorPosition.y + 10,
            }}
          ></div>
        ) : null}
        <div className="flex flex-col gap-2">
          {selectedColors.map((color, index) => (
            <div key={index} className="flex items-center gap-2">
              <span className="font-medium font-sans w-[150px]">
                {selectedColorsLabels[index]}
              </span>

              {editingIndex === index ? (
                <>
                  <div
                    className={`bg-slate-100 px-2 py-1.5 rounded-md flex items-center gap-2 min-w-[120px] ${
                      selectedIndex === index || editingIndex === index
                        ? "ring-2 ring-[#7f56d9]"
                        : ""
                    }`}
                  >
                    <button
                      type="button"
                      className={`color-picker-button w-5 h-5 rounded-md border border-gray-300`}
                      style={{ backgroundColor: rgbToHex(color) }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingIndex(null);
                        setSelectedIndex(index);
                      }}
                    ></button>
                    <div>
                      <input
                        className="w-16 bg-transparent border-none outline-none"
                        value={editColor}
                        onChange={(e) => setEditColor(e.target.value)}
                        ref={inputRef}
                      />
                    </div>
                  </div>
                  <button
                    onClick={handleSaveEdit}
                    className="px-2 py-1 bg-green-500 text-white rounded-md"
                    type="button"
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <div
                    className={`bg-slate-100 px-2 py-1.5 rounded-md flex items-center gap-2 min-w-[120px] ${
                      selectedIndex === index || editingIndex === index
                        ? "ring-2 ring-[#7f56d9]"
                        : ""
                    }`}
                  >
                    <ColorPicker
                      value={rgbToHex(color)}
                      onChange={(colorObj, colorValue) => {
                        handleSaveColorFromPicker(colorValue);
                      }}
                    >
                      <button
                        type="button"
                        className={`color-picker-button w-5 h-5 rounded-md border border-gray-300`}
                        style={{ backgroundColor: rgbToHex(color) }}
                        onClick={(e) => {
                          setEditingIndex(null);
                          setSelectedIndex(index);
                        }}
                      ></button>
                    </ColorPicker>
                    <div>
                      <span
                        className="text-sm font-medium font-sans text-gray-700"
                        onClick={() => {
                          handleEditClick(index);
                          setSelectedIndex(null);
                          setTimeout(() => {
                            inputRef.current.focus();
                          }, 300);
                        }}
                      >
                        {rgbToHex(color)}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

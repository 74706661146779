import React, { useState } from "react";
import AllowPermisionModal from "../../AllowPermissionModal";
import { useNavigate } from "react-router";
import { callBackendAPI } from "../../../helpers/apiCalls";
import {
  setLastPublishProduct,
  setPreLanderData,
  setPreLanderId,
  setProductHandler
} from "../../../redux/slices/prelander-page-slice";
import { useDispatch, useSelector } from "react-redux";

const PreLandedTopBar = ({
  pageData,
  rightBarData,
  bottomBlock,
  topNotifyBlock,
  templateDefaultData,
  setSavePreLander,
  savePreLander,
}) => {
  // const { templateType } = useSelector((store) => store.templateSlice);
  const { lastPublishProduct } = useSelector((store) => store.preLanderPageSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const prepareData = () => {
    return {
      ...templateDefaultData.sections,
      top_notify: topNotifyBlock[1],
      main_layout: {
        type: templateDefaultData['sections']['main_layout'].type,
        settings: templateDefaultData['sections']['main_layout'].settings,
        block_order: templateDefaultData['sections']['main_layout'].block_order,
        blocks: {...pageData, rightbar: rightBarData },
      },
      sticky_bar: bottomBlock[1],
    }
  }

  const handlePublish = async () => {
    setSavePreLander(true);
    try {
      await callBackendAPI(
        "prelander/save-template",
        "POST",
        {},
        {
          payload: {
            content: {
              "sections": prepareData(),
              "order": templateDefaultData['order'],
            },
            type: 'health_prelander',
            product_id: Number(lastPublishProduct),
          }
        }
      );
      dispatch(setPreLanderData(null));
      dispatch(setPreLanderId(null));
      dispatch(setLastPublishProduct(null));
      dispatch(setProductHandler(null));
      setSavePreLander(false);
      navigate('/');
    } catch (e) {
      setSavePreLander(false);
      console.log(e)
    }
  }

  return (
    <>
      <div className="top-bar-main-container">
        <div className="flex gap-2 items-center">
          <button
            type="button"
            className="top-bar-main-container-white-button"
            onClick={() => {
              dispatch(setPreLanderData(null));
              dispatch(setPreLanderId(null));
              dispatch(setLastPublishProduct(null));
              dispatch(setProductHandler(null));
              navigate('/');
            }}
            disabled={savePreLander}
          >
            Cancel
          </button>
        </div>

        <div className="steps-buttons">
          <button
            disabled
            type="button"
            className="top-bar-main-container-middle-buttons"
          >
            Pre-lander template
          </button>
        </div>

        <div className="top-container-right-btns-container">
          <button
            type="button"
            className="top-container-right-btns-container-button"
            onClick={() => handlePublish()}
            disabled={savePreLander}
          >
            Publish
          </button>
        </div>
      </div>
    </>
  );
};

export default PreLandedTopBar;

import React from 'react'
import StepSvgContainer from './StepSvgContainer'
 

const PetsKidsTemplateSteps = ({
    howToUseTitle,
    howToUseSteps,
    howToUseStepsSection,
    howToUseTitleSection,
    handleSectionEditChange,
    section8Image,
    section9Image,
    section10Image,
    colorsPalette

}) => {
    return (
        <div style={{ backgroundColor: 'rgb(238, 238, 238)' }} 
       
        className='w-full flex flex-col justify-center items-center px-[50px] py-[60px]  h-[500px]'
        >
            <div class="flex justify-center">
                <h2
                    onClick={() => handleSectionEditChange(["howToUseTitleSection"])}
                    className={`cursor-pointer pro-tmp-heading ${howToUseTitleSection ? "outline-border-selected"
                        : "outline-border"
                        } `}
                >{howToUseTitle}</h2>
            </div>
            <div
                onClick={() => handleSectionEditChange(["howToUseStepsSection"])}
                className={`cursor-pointer w-full grid grid-cols-3 gap-2 p-1 ${howToUseStepsSection ? "outline-border-selected"
                    : "outline-border"
                    } `}
            >
                {
                 howToUseSteps&& howToUseSteps.length>0&&  howToUseSteps?.map((step, index) => {
                 
                        return (
                            <div key={index} className="custom-slider-type-2-inner grid-col-1 min-h-[300px]"
                            style={
                                colorsPalette.steps_section_background
                                  ? {
                                      backgroundColor: `rgb(${colorsPalette.steps_section_background})`,
                                    }
                                  : {}
                              }
                              >
                                {/* <img src={index == 0 ? section8Image : index == 1 ? section9Image : section10Image} width={200} height={200} loading="lazy" /> */}
                               <StepSvgContainer index={index} color={colorsPalette.steps_section_text_color?`rgb(${colorsPalette.steps_section_text_color})`:'#ffffff'} />
                                <div className="user-info">
                                    <div className="user-details">
                                        <h3 className="name-title" 
                                         style={
                                            colorsPalette.steps_section_text_color
                                              ? {
                                                  color: `rgb(${colorsPalette.steps_section_text_color})`,
                                                }
                                              : {}
                                          }
                                          >{step.title} </h3>
                                    </div>
                                </div>
                                <p className='text-center' style={
                                            colorsPalette.steps_section_text_color
                                              ? {
                                                  color: `rgb(${colorsPalette.steps_section_text_color})`,
                                                }
                                              : {}
                                          }>{step.description}</p>
                            </div>

                        )
                    })
                }

            </div>

        </div>

    )
}

export default PetsKidsTemplateSteps
import { createSlice } from "@reduxjs/toolkit";

const publishProductSlice = createSlice({
  name: "publishProductSlice",

  initialState: {
    isPublish: false,
    generatePreLander: false,
    tone: '',
    language: '',
    currentEditingProductId: '',
    oldIdsOfMyProducts: [],
    publisPayload: {},
  },
  reducers: {
    setPublishTrue: (state, action) => {
      state.isPublish = true
      state.publisPayload = action.payload
    },
    setPublishFalse: (state) => {
      state.isPublish = false
    },
    publisProductId: (state, action) => {
      state.currentEditingProductId = action.payload
    },
    setOldIdsOfOfMyProducts: (state, action) => {
      state.oldIdsOfMyProducts = action.payload
    },
    setGeneratePreLander: (state, action) => {
      state.generatePreLander = action.payload
    },
    setGlobalTone: (state, action) => {
      state.tone = action.payload
    },
    setGlobalLanguage: (state, action) => {
      state.language = action.payload
    },
  }

});

export const {
  setPublishFalse,
  setPublishTrue,
  publisProductId,
  setOldIdsOfOfMyProducts,
  setGeneratePreLander,
  setGlobalTone,
  setGlobalLanguage,
} = publishProductSlice.actions;

export default publishProductSlice.reducer;


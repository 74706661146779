import React, { useState } from "react";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { useNavigate } from "react-router";
import { message } from "antd";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  publisProductId,
  setPublishTrue,
} from "../../../redux/slices/publish-product-slice";
import { colors } from '../../../constantData/colorsData'
import { setLastPublishProduct, setProductHandler } from "../../../redux/slices/prelander-page-slice";
import AllowPermisionModal from "../../AllowPermissionModal";

function convertHTMLToJSON(htmlString) {
  htmlString = htmlString?.replace("<ul>", "").replace("</ul>", "");
  var liItems = htmlString?.split("</li>");
  liItems = liItems?.filter((item) => item.trim() !== "");
  liItems = liItems?.map((item, index) => {
    return item?.replace("<li>", "");
  });
  return liItems;
}
const TopBar = ({
  customBadgeText,
  proTemplatePayload,
  productData: otherThanVariantsData,
  productPrice,
  showSalePrice,
  id,
  sectionsImages,
  step,
  setStep,
  propertiesLength,
  shipsFrom,
  productImages,
  loading,
  setLoading,
  setSaveOrPublish,
  showGenerateLanding,
  setShowGenerateLanding,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // redux fetcher
  const {
    productData,
    mediaImages,
    productBulletBenefits,
    totalEnabledVariants,
    totalAllowedVariants,
    editViewStyle,
    switchToVariant,
    variantsArrangedIndexesObject,
  } = useSelector((store) => store.productVariants);
  const { staticMediaImages } = useSelector((store) => store?.staticMediaImagesSlice);
  const { countryMethod } = useSelector((store) => store.shippingConfiguration);
  const { templateType, colorPalette , colorPaletteColors} = useSelector((store) => store.templateSlice);
  
  const variantsArrangedActiveObject = useSelector(
    (state) => state.productVariants.variantsArrangedActiveObject // Update to match your slice
  );
  const { generatePreLander } = useSelector((store) => store.publishProduct);
  const { preLanderId } = useSelector((store) => store.preLanderPageSlice);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  const updateProduct = async (key) => {
    // if the allowed variants limit exceed
    if (totalEnabledVariants > totalAllowedVariants) {
      message.error("Enabled variants exceed the limit allowed!");
      return;
    }

    // set alert if the user tries to refresh the page while saving the product
    window.addEventListener("beforeunload", alertUser);
    setSaveOrPublish(key);

    dispatch(publisProductId(id));
    const { productTemplate, product_variants } = productData.data;
    
    for(const variant of product_variants){
      if(!variant?.is_disabled && variant?.price){
        if((variant?.enableSalePrice && !!(Number(variant?.discounted_price) <= 0))){
          message.error("Sale price must be greater than 0!");
        return;
      }
      if((variant?.enableSalePrice && (Number(variant?.discounted_price) > Number(variant?.price)))){
        message.error("Sale price cannot be greater than the price");
        return;
      }
    }
    }
    let updatedBulletBenefits;
    if (typeof productTemplate?.bulletBenefits === "string") {
      updatedBulletBenefits = convertHTMLToJSON(productTemplate.bulletBenefits);
    } else {
      updatedBulletBenefits = productTemplate?.bulletBenefits;
    }

    // updated Variant variant
    const updatedVariant = product_variants.map((variant) => {
      console.log('variant.pric: ', variant.price);
      console.log('showSalePrice109: ', variant?.enableSalePrice);
      if(!variant.is_disabled) {
        return {
          ...variant,
          price: parseFloat(variant.price) || 0,
          discounted_price: parseFloat(variant.discounted_price) || 0,
          enableSalePrice: variant?.enableSalePrice,
        }}
        }
    ).filter(Boolean);
    console.log('product_variants: 105', updatedVariant);
    // edit view style
    const editView = editViewStyle === "Dropdown" ? "dropdown" : "button";

    //toggle switch variant image

    const isSwitchVariant = switchToVariant === 'Yes' ? true :false;

    console.log('isSwitchVariantisSwitchVariant',isSwitchVariant)

    console.log('templateType: 124', templateType);

    // get product template data based on the template selection
    const templateDataForDifferenTemplates =
      templateType === "pro"
        ? {
            ...proTemplatePayload,
            // from productData
            salesText1: otherThanVariantsData.salesText1,
            salesText2: otherThanVariantsData.salesText2,
            salesText3: otherThanVariantsData.salesText3,
            salesTextHeadline1: otherThanVariantsData.salesTextHeadline1,
            salesTextHeadline2: otherThanVariantsData.salesTextHeadline2,
            salesTextHeadline3: otherThanVariantsData.salesTextHeadline3,
            productName: otherThanVariantsData.productName,
            shippingLink: otherThanVariantsData.shippingLink,
            productSubTitle: otherThanVariantsData.productSubTitle,
          }
        : templateType === "kids"
        ? {
            ...otherThanVariantsData,
          }
        : {
            ...otherThanVariantsData,
            bulletBenefits:   typeof productBulletBenefits !== "string"
            ?  productBulletBenefits.map((d) =>   d.heading  )  : convertHTMLToJSON(productBulletBenefits),
          };
    // for multiple variants

    const templateData = {
      ...productData.data,
      external_images: [...productImages, ...mediaImages],
      media_images: staticMediaImages,
      product_variants: updatedVariant,
      productTemplate: {
        ...templateDataForDifferenTemplates,
        variantDisplayStyle: editView,
        isSwitchVariant:isSwitchVariant,
        customBadge: customBadgeText,
        colorPalette:colorPalette,
        colorPaletteColors:colorPaletteColors[templateType][colorPalette.toLowerCase()],
      },
      sectionImages:
        templateType === "pro"
          ? {
              ...sectionsImages,
              section4Image: proTemplatePayload.textOnImageDetails.img ?? sectionsImages?.section1Image,
            }
          : templateType === "kids"
          ? {
              section1Image: sectionsImages.mainTestimonialSectionImage,
              section2Image: sectionsImages.secondSectionImage ?? sectionsImages.mainTestimonialSectionImage,
              section3Image: sectionsImages.testimonialImage1 ?? sectionsImages.mainTestimonialSectionImage,
              section4Image: sectionsImages.testimonialImage2 ?? sectionsImages.mainTestimonialSectionImage,
              section5Image: sectionsImages.testimonialImage3 ?? sectionsImages.mainTestimonialSectionImage,
              section6Image: sectionsImages.customSalesSectionImage ?? sectionsImages.mainTestimonialSectionImage,
              section7Image: sectionsImages.lastSectionImage ?? sectionsImages.mainTestimonialSectionImage,
              section8Image: sectionsImages.section8Image ?? sectionsImages.mainTestimonialSectionImage,
              section9Image: sectionsImages.section9Image ?? sectionsImages.mainTestimonialSectionImage,
              section10Image: sectionsImages.section10Image ?? sectionsImages.mainTestimonialSectionImage,
            }
          : sectionsImages,
    };
    console.log("templateData", templateData);
    // for single variant product
    const singleVariant = {
      ...productData.data,
      product_variants: updatedVariant,
      external_images: [...productImages, ...mediaImages],
      media_images: staticMediaImages,
      productTemplate: {
        ...templateDataForDifferenTemplates,
        customBadge: customBadgeText,
        isSwitchVariant:isSwitchVariant,
        colorPalette:colorPalette,
        colorPaletteColors:colorPaletteColors[templateType][colorPalette.toLowerCase()],
      },
      sectionImages:
        templateType === "pro"
          ? {
              ...sectionsImages,
              section4Image: proTemplatePayload.textOnImageDetails.img ?? sectionsImages?.section1Image,
            }
          : templateType === "kids"
          ? {
              section1Image: sectionsImages.mainTestimonialSectionImage,
              section2Image: sectionsImages.secondSectionImage ?? sectionsImages.mainTestimonialSectionImage,
              section3Image: sectionsImages.testimonialImage1 ?? sectionsImages.mainTestimonialSectionImage,
              section4Image: sectionsImages.testimonialImage2 ?? sectionsImages.mainTestimonialSectionImage,
              section5Image: sectionsImages.testimonialImage3 ?? sectionsImages.mainTestimonialSectionImage,
              section6Image: sectionsImages.customSalesSectionImage ?? sectionsImages.mainTestimonialSectionImage,
              section7Image: sectionsImages.lastSectionImage ?? sectionsImages.mainTestimonialSectionImage,
              section8Image: sectionsImages.section8Image ?? sectionsImages.mainTestimonialSectionImage,
              section9Image: sectionsImages.section9Image ?? sectionsImages.mainTestimonialSectionImage,
              section10Image: sectionsImages.section10Image ?? sectionsImages.mainTestimonialSectionImage,
            }
          : sectionsImages,
    };
    // decide whether single variant or multiple
    const finalData = propertiesLength > 1 ? templateData : singleVariant;

    setLoading(true);
    //console.log(templateType,colorPalette.toLowerCase())
    //return false
    //console.log('sssss',colors[templateType][colorPalette.toLowerCase()]);
    //return false
    const endpoint =
      templateType === "pro"
        ? "template/edit_save_pro_template"
        : templateType === "kids"
        ? "template/kids_pets_edit_save_template"
        : "template/v1_edit_save_template";
    const method = "POST";
    const payload = {
      payload: {
        shipsFrom: shipsFrom ? shipsFrom : null,
        isFrom: productData.isFrom,
        ...finalData,
        external_images: finalData?.external_images.filter((image) => !!image),
        media_images: finalData?.media_images.filter((image) => !!image),
        shippingConfiguration: countryMethod,
        variantsArrangedObject: variantsArrangedIndexesObject,
        variantsArrangedActiveObject: variantsArrangedActiveObject,
        templateType: templateType,
        // colorPalette:colorPalette,
        // colorPaletteColors:colors[templateType][colorPalette.toLowerCase()],
        action: key,
      },
    };

    // console.log(payload,'payloadpayload')
    // return false;

    try {
      const response = await callBackendAPI(endpoint, method, {}, payload);
    
      if (response?.status) {
        setLoading(false);
        if (key === "publish") {
          const { data, shopifyUploadedImages } = response;
          let { imageUpload } = data;
          imageUpload = {
            ...imageUpload,
            shopify_ali_uploaded_images: shopifyUploadedImages,
          }
          dispatch(setPublishTrue(imageUpload));
        }
        if (generatePreLander && !productData?.data?.productData?.id) {
          if (response.data.product.handle) {
            dispatch(setProductHandler(response.data.product.handle));
          }
          dispatch(setLastPublishProduct(response.data.id))
          setShowGenerateLanding(true);
        } else {
          navigate("/");
        }
      } else if (!response.status && response?.error === "ACCESS_DENIED") {
        setLoading(false);
        const match = response.message.match(/`([^`]*)`/);
        const result = match ? match[1] : null;
      } else {
        message.error(
          response?.message ? response?.message : "Error publishing product!"
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      message.error(`Cannot update due to ${error.message}`, 2);
    } finally {
      window.removeEventListener("beforeunload", alertUser);
    }
  };

  const showDeleteConfirmationModal = () => {
    if (productData?.productStatus === "import") {
      setIsModalVisible(true);
    } else {
      navigate("/");
    }
  };
  const openProductInAliExpress = () => {
    window.open(
      `https://www.aliexpress.com/item/${productData?.data?.productData?.external_product_id}.html?`
    );
  };

  return (
    <>
      <div className="top-bar-main-container">
        <div className="flex gap-2 items-center">
          <button
            onClick={showDeleteConfirmationModal}
            type="button"
            className="top-bar-main-container-white-button "
          >
            Cancel
          </button>


          {productData?.data?.aliexpress_response?.ae_item_base_info_dto && (
          <button
            onClick={openProductInAliExpress}
            type="button"
            className="top-container-right-btns-container-button  "
          >
            Open in AliExpress
          </button>
          )}
        </div>
        {propertiesLength > 1 ? (
          <div className="steps-buttons">
            <button
              onClick={() => setStep("Product template")}
              disabled={step === "Product template"}
              type="button"
              className={` ${
                step === "Product template"
                  ? "top-bar-main-container-middle-buttons"
                  : "top-bar-main-container-middle-buttons-override"
              }`}
            >
              <p>
                <b>Step 1 {"  "}</b>
                Product template
              </p>
            </button>
            <span className="top-bar-main-container-middle-buttons-vector"></span>

            <button
              onClick={() => setStep("Variant pricing")}
              disabled={step === "Variant pricing"}
              type="button"
              className={` ${
                step === "Variant pricing"
                  ? "top-bar-main-container-middle-buttons"
                  : "top-bar-main-container-middle-buttons-override"
              }`}
            >
              <p>
                <b>Step 2 </b>Variant pricing
              </p>
            </button>
            {productData?.data?.productData?.is_from === 'Aliexpress' && (
              <>
                <span className="top-bar-main-container-middle-buttons-vector"></span>
                <button
                  onClick={() => setStep("Shipping configuration")}
                  disabled={step === "Shipping"}
                  type="button"
                  className={` ${
                    step === "Shipping configuration"
                      ? "top-bar-main-container-middle-buttons"
                      : "top-bar-main-container-middle-buttons-override"
                  }`}
                >
                  <p>
                    <b>Step 3 </b>
                    Shipping configuration
                  </p>
                </button>
              </>
            )}
          </div>
        ) : (
          <div className="steps-buttons">
            <div className="steps-buttons">
              <button
                onClick={() => setStep("Product template")}
                disabled={step === "Product template" || productData?.data?.productData?.is_from !== 'Aliexpress' }
                type="button"
                className={` ${
                  step === "Product template"
                    ? "top-bar-main-container-middle-buttons"
                    : "top-bar-main-container-middle-buttons-override"
                }`}
              >
                <p>{productData?.data?.productData?.is_from === 'Aliexpress' && <b>Step 1 </b>}Product template</p>
              </button>
            </div>
            {productData?.data?.productData?.is_from === 'Aliexpress' && (
              <>
                <span className="top-bar-main-container-middle-buttons-vector"></span>
                <button
                  onClick={() => setStep("Shipping configuration")}
                  disabled={step === "Shipping"}
                  type="button"
                  className={` ${
                    step === "Shipping configuration"
                      ? "top-bar-main-container-middle-buttons"
                      : "top-bar-main-container-middle-buttons-override"
                  }`}
                >
                  <p>
                    <b>Step 2 </b>
                    Shipping configuration
                  </p>
                </button>
              </>
            )}
          </div>
        )}

        <div className="top-container-right-btns-container">
          {preLanderId && (
            <button
              onClick={() => {
                dispatch(setLastPublishProduct(id));
                navigate('/pre-lander')
              }}
              type="button"
              className="top-bar-main-container-white-button"
              disabled={loading}
            >
              Edit prelander page
            </button>
          )}
          {productData?.product_status !== "publish" && 
            <button
              onClick={() => updateProduct("draft")}
              type="button"
              className="top-bar-main-container-white-button"
              disabled={loading}
            >
              Save as draft
            </button>}
          <button
            onClick={() => {
              if (propertiesLength > 1) {
                switch (step) {
                  case "Product template": setStep("Variant pricing");
                  break;

                  case "Variant pricing": productData?.data?.productData?.is_from === 'Aliexpress' ? setStep("Shipping configuration") : updateProduct("publish");
                    break;

                  case "Shipping configuration": updateProduct("publish");
                    break;
                }
              } else {
                updateProduct("publish");
              }
            }}
            type="button"
            className="top-container-right-btns-container-button"
            disabled={loading}
          >
            {productData?.data?.productData?.is_from === 'Aliexpress' && (propertiesLength === 1
              ? "Publish"
              : propertiesLength > 1 && step === "Shipping configuration"
              ? "Publish"
              : "Next")}
            {productData?.data?.productData?.is_from !== 'Aliexpress' && (propertiesLength === 1
              ? "Publish"
              : propertiesLength > 1 && step === "Variant pricing"
                ? "Publish"
                : "Next")}
          </button>
        </div>
      </div>
      {isModalVisible && (
        <DeleteConfirmationModal
          OpenModal={isModalVisible}
          setOpModal={setIsModalVisible}
          id={id}
        />
      )}
    </>
  );
};

export default TopBar;

import React from "react";
import DOMPurify from "dompurify";
import { reformatText } from "../PreLanderEditView";

const TextSection = ({
  blockId,
  settings,
  selectedBlock,
  handleSelectBlock,
}) => {
  const sectionText = DOMPurify.sanitize(reformatText(settings.text));

  return (
    <div
      className={`edit-formatted-text-pre-lander mb-5 ${selectedBlock === blockId ? "outline-border-selected" : "outline-border1"}`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <div style={{ fontSize: settings.text_size, color: settings.text_color }} dangerouslySetInnerHTML={{ __html: sectionText }}></div>
    </div>
  )
}

export default TextSection;

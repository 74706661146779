import React from 'react'

const PetsKidsTemplateLastSalesSection = (
  {
    moneybackGuaranteeHeadline,
    moneybackGuaranteeText,
    moneybackGuaranteeHeadlineSection,
    moneybackGuaranteeTextSection,
    lastImageSection,
    lastSectionImage,
    handleSectionEditChange,
    colorsPalette
  }
) => {
  return (
    <section className="w-full custom-type-2-section  py-4"
    onClick={() => handleSectionEditChange(["moneybackGuaranteeColorSection"])}
    style={
      colorsPalette.dark_section_background
        ? {
            backgroundColor: `rgb(${colorsPalette.dark_section_background})`,
          }
        : {}
    }>

      <div className="w-full page-width">
        <div className="custom-type-2-container">
          <div className="custom-type-2-header text-center">
            <h2
              style={
                colorsPalette.dark_section_background
                  ? {
                      color: `rgb(${colorsPalette.dark_section_heading_color})`,
                    }
                  : {}
              }
              onClick={(e) =>  { e.stopPropagation(); handleSectionEditChange(["moneybackGuaranteeHeadlineSection"])}}
              className={`cursor-pointer custom-type-2-heading  ${moneybackGuaranteeHeadlineSection ? "border border-2 border-white"
                  : "border-dotted border-2 border-white"} `}
           >
             {moneybackGuaranteeHeadline}
            </h2>
          </div>
          <div  
          onClick={(e) =>  { e.stopPropagation(); handleSectionEditChange(["lastSectionImage"])}}
          className={`cursor-pointer custom-type-2-image-wrapper text-center p-1  ${lastImageSection ? "border border-2 border-white"
              : "border-dotted border-2 border-white"} `}
          >
            <img
              src={lastSectionImage}
              width={400} height={400} loading="lazy" />
          </div>
          <div  
           style={
            colorsPalette.dark_section_background
              ? {
                  color: `rgb(${colorsPalette.dark_section_heading_color})`,
                }
              : { color: "white"}
          }
              onClick={(e) =>  { e.stopPropagation(); handleSectionEditChange(["moneybackGuaranteeTextSection"])}}
              className={`gurantee-div cursor-pointer custom-type-2-text-wrapper text-center  ${moneybackGuaranteeTextSection ? "border border-2 border-white"
                  : "border-dotted border-2 border-white"} `}
                  dangerouslySetInnerHTML={{
                    __html: moneybackGuaranteeText.replace(
                      /<p>/g,
                      `<p style="color: ${colorsPalette.dark_section_heading_color ? `rgb(${colorsPalette.dark_section_heading_color})` : "white"};">`
                    ),
                  }}>
           </div>
        </div>
      </div>
    </section>

  )
}

export default PetsKidsTemplateLastSalesSection
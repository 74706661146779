import React, { useState } from "react";
import purpleTheme from "../../../assets/purple.png";
import customTheme from "../../../assets/Icon.png";
import greenTheme from "../../../assets/green.png";
import StoreTheme from "../../../assets/store-theme.png";
import ImageColorPicker from "../../ImageColorPicker/ImageColorPicker";

export default function ColorPalletSelecter({
  selectedColorPalette,
  handleColorPaleteClick,
}) {
  const colorPalettes = {
    items: [
      {
        key: "Default",
        label: "Default",
        icon: StoreTheme,
      },
      {
        key: "Purple",
        label: "Purple",
        icon: purpleTheme,
      },
      {
        key: "Green",
        label: "Green",
        icon: greenTheme,
      },
      {
        key: "Custom",
        label: "Custom",
        icon: customTheme,
      },
    ],
  };
  return (
    <>
      <span className="mb-[10px] font-medium">Select Color palettes</span>
      <p style={{ fontSize: "14px", marginBottom: "15px" }}>
        The color pallete you select will be used in the generated product page.
      </p>

      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {colorPalettes.items.map((palette) => (
          <div
            key={palette.key}
            onClick={() => handleColorPaleteClick(palette)}
            style={{ padding: "5px", cursor: "pointer" }}
          >
            <div
              className={`color-pallet-icon border-[3px] border-transparent ${
                selectedColorPalette == palette.key ? "active-color-pallet" : ""
              } ${"Custom" == palette.key ? "color-pallet-custom" : ""}`}
            >
              <img src={palette.icon} alt={palette.label} />
            </div>
            <span
              className={`${
                selectedColorPalette == palette.key
                  ? "active-color-pallet-text"
                  : ""
              }`}
            >
              {palette.label}
            </span>
          </div>
        ))}
      </div>

      {selectedColorPalette === "Custom" && <ImageColorPicker />}
    </>
  );
}

import React from "react";
import { Modal } from "antd";
import CustomLoader from "../../CustomLoader/CustomLoader";
import waiting from '../../../assets/waiting.png'
const SavePublishModal = ({ OpenModal,saveOrPublish }) => {
  return (
    <div>
      <Modal
        closable={false}
        width={500}
        open={OpenModal}
         footer={null}

      >
        <div className="delete-prduct-content-container px-5 !text-center !flex !flex-col !justify-center !items-center">
          <img src={waiting} className="delete-prduct-content-container-img h-[80px]" alt={'wait_icon'} />
          <CustomLoader color='#344054' width='20px' />

          <h4 style={{alignSelf:'center',textAlign:'center'}}>
            {saveOrPublish!=="publish"?` Saving product as Draft (this can take a few minutes)`:`Publishing product to Shopify (this can take a few minutes)`}
            </h4>
          <p>Do not refresh the page!</p>
        </div>
      </Modal>
    </div>
  );
};

export default SavePublishModal;

import React, { useState, useEffect, useCallback, useRef } from "react";
import "./RightSection.css";
import { message, Spin, Switch } from "antd";
import SectionEditImageContainer from "./SectionEditImageContainer/SectionEditImageContainer";
import GalleryEditImageContainer from "./GalleryEditImageContainer/GalleryEditImageContainer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ProductVariantsComponent from "../ProductVariantsSection/ProductVariantsComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  changePriceForSpecific,
  changeProductBullets,
  changeSelectedVariantImage,
  updateVariantImageFromCanvaEdit,
} from "../../../redux/slices/product-variants-slice";
import getAliExpressPrices from "../../../Utilities/productVariantsHandlers/getAliExpressPrices";
import { URLS } from "../../../URLS";
import { callBackendAPI } from "../../../helpers/apiCalls";
import getUpdatedArraysAfterCanvaEditing from "../../../Utilities/getUpdatedArraysAfterCanvaEditing";
import ConnectCanva from "../../Canva/ConvaConnect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ProTemplateCollpseContentEditComponent from "./ProTemplateEditComponents/ProTemplateCollpseContentEditComponent";
import ProTemplateCollpseHeadingEditComponent from "./ProTemplateEditComponents/ProTemplateCollpseHeadingEditComponent";

import PetsKidsFeatureUnderPNEditComponent from "./PetsKidsEditComponents/PetsKidsFeatureUnderPNEditComponent";
import PetsKidsMainTestimonialEditComponent from "./PetsKidsEditComponents/PetsKidsMainTestimonialEditComponent";
import PetsKidsTestimonialsComponent from "./PetsKidsEditComponents/PetsKidsTestimonialsComponent";
import ShippingRiskFreeReturnsComponent from "./PetsKidsEditComponents/shippingRiskFreeReturnsComponent";
import ButtonColorSection from './ColorPickerSection';
import ColorsPicker  from './ColorPickerSection/ColorsPicker';

const convertBulletPointsToHtml = (bulletPoints) => {
  const htmlString = `<ul>${bulletPoints
    .map((point) => `<li>${point}</li>`)
    .join("")}</ul>`;

  return htmlString;
};

const parseBulletPoints = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const bulletPoints = Array.from(tempDiv.querySelectorAll("li")).map(
    (li) => li.textContent
  );
  return bulletPoints;
};

const PetsKidsTemplateRightSection = ({
  productPrice,
  setProductPrice,
  productData,
  sectionClick,
  updateProductData,
  showSalePrice,
  setShowSalePrice,
  sectionsImages,
  setSectionsImages,
  productImages,
  setProductImages,
  aliexpressImages,
  setVariantViewChange,
  variantView,
  productVariants,
  shipsFrom,
  imageCache,

  textOnImageDetails,
  setTextOnImageDetails,
  FAQData,
  setFAQData,
  collapseIndex,
  handleCustomBadgeChange,
  customBadgeText,
  setCustomBadgeText,
  customTextData,
  UploadImage,
  fileList1,
  setFileList1,
  previewImage1,
  setPreviewImage1,
  fileList2,
  setFileList2,
  previewImage2,
  setPreviewImage2,
  fileList3,
  setFileList3,
  previewImage3,
  setPreviewImage3,
  colorsPalette,
  setSectionClick,
  propertiesLength
  
}) => {
  console.log("sectionClick ", sectionClick);
  // const { matchedVariant } = useSelector(store => store.productVariants)
  const dispatch = useDispatch();

  const {
    productBulletBenefits,
    productData: CompleteProductData,
    toUpdateImageDetails,
    changeVariantImageKey,
  } = useSelector((store) => store?.productVariants);
  const {isFrom } = useSelector((store) => store.productVariants.productData);
  const { colorPalette } = useSelector((store) => store.templateSlice);

  console.log("productData", productData);

  const [selectImageInfo, setSelectImageInfo] = useState({});
  const [grossProfit, setGrossProfit] = useState(0);
  const [isCanvaConnected, setIsCanvaConnected] = useState(false);
  const [canvaAuthWindow, setCanvaAuthWindow] = useState(null);
  const [isEditCanvaLoading, setIsEditCanvaLoading] = useState(false);
  const [awaitingRefresh, setAwaitingRefresh] = useState(false);
  const [updatedUrl, setUpdatedUrl] = useState("");

  useEffect(() => {
    if (updatedUrl !== "") {
      // update every where
      const { updatedProductImages, updatedSectionsImages } =
        getUpdatedArraysAfterCanvaEditing({
          productImages,
          sectionsImages,
          imageUrl: toUpdateImageDetails?.imageUrl,
          updatedUrl,
        });

      // Set the state for both
      setProductImages(updatedProductImages);
      setSectionsImages(updatedSectionsImages);
      dispatch(
        updateVariantImageFromCanvaEdit({
          existingUrl: toUpdateImageDetails?.imageUrl,
          newUrl: updatedUrl,
        })
      );
    }
  }, [updatedUrl]);

  const quillRef = useRef(null);
  const reactQuillRef = useCallback((node) => {
    if (node !== null) {
      quillRef.current = node.getEditor();
    }
  }, []);

  useEffect(() => {
    if (productData.price && productData.salePrice) {
      setShowSalePrice(!!productData.salePrice);
    }
  }, [productData, sectionClick]);

  useEffect(() => {
    if( propertiesLength <= 1 ){
    const grossPrice = getAliExpressPrices(CompleteProductData);

    if (Object.keys(grossPrice)[0]) {
      const firstKey = Object.keys(grossPrice)[0];
      const setPrice =
        showSalePrice && productPrice.salePrice > 0
          ? productPrice.salePrice - grossPrice[firstKey]?.price
          : productPrice.price > 0
          ? productPrice.price - grossPrice[firstKey]?.price
          : 0;

      setGrossProfit(setPrice.toFixed(2));
    }
    console.log("inside kids temp161")
    // if((showSalePrice || productPrice?.enableSalePrice) && productPrice?.salePrice > 0){
    //   const profit = productPrice?.price - productPrice?.salePrice;
    //   setGrossProfit(profit.toFixed(2));
    // }else {
    //   setGrossProfit(0);
    // }
    if(!showSalePrice){
      dispatch(changePriceForSpecific({
        key: 'price',
        value: productPrice.price,
        productVariants: productVariants[0],
        showSalePrice: false
      }))
    }
  }
  }, [productPrice]);
  useEffect(() => {
    const trueKey = Object.keys(sectionClick).find((key) => sectionClick[key]);
    console.log(trueKey);

    if (trueKey && sectionsImages[trueKey]) {
      setSelectImageInfo({ status: true, trueKey });
    } else {
      setSelectImageInfo({});
    }
  }, [sectionClick, sectionsImages]);
  useEffect(() => {
    const abortController = new AbortController();
    callBackendAPI("canva", "GET", {}, null, abortController.signal).then(
      (response) => {
        if (!!response.connected) {
          setIsCanvaConnected(true);
        }
      }
    );

    const canvaReturnFunc = (event) => {
      console.log("canva event on return= >", event);

      if (
        event.origin === URLS.CANVA_BACKEND_API &&
        event.data.event === "canva-return"
      ) {
        if (
          event.data.data &&
          event.data.data.design_id &&
          event.data.data.correlation_state?.length > 0
        ) {
          console.log("canva event on return if condition= >");
          setIsEditCanvaLoading(true);
          callBackendAPI("canva/design", "GET", {
            designId: event.data.data.design_id,
            update: true,
          })
            .then((response) => {
              if (response.status === "success") {
                if (response.accessToken) {
                  localStorage.setItem(
                    "canva_access_token",
                    response.accessToken
                  );
                }

                console.log("Canva return success");
                console.log("canva/design", response, toUpdateImageDetails);
                setUpdatedUrl(response?.url);
                console.log("awaitingRefresh", awaitingRefresh);
                setAwaitingRefresh(false);
              }
            })
            .finally(() => {
              setIsEditCanvaLoading(false);
            });
        }
      }
    };

    window.addEventListener("message", canvaReturnFunc);

    return () => {
      abortController.abort();

      if (canvaAuthWindow) {
        canvaAuthWindow.close();
      }
      window.removeEventListener("message", canvaReturnFunc);
    };
  }, []);

  const canvaEventFunc = (event) => {
    if (event.origin === URLS.CANVA_BACKEND_API) {
      if (event.data.event === "canva-auth" && event.data.canva) {
        switch (event.data.canva.status) {
          case "success":
            setIsCanvaConnected(true);
            break;
          case "error":
            console.error("Canva auth failed");
            console.error(event.data.canva.error);
            break;
          default:
            break;
        }
        setCanvaAuthWindow(null);
      }

      window.removeEventListener("message", canvaEventFunc);
    }
  };

  const condition_array = Object.keys(CompleteProductData?.data?.aliexpress_response || {});

  const handleCanva = () => {
    if (!!isCanvaConnected) {
      callBackendAPI("canva", "DELETE", {}).then((response) => {
        if (response.status === "success") {
          setIsCanvaConnected(false);
          setCanvaAuthWindow(null);
        }
      });
      return;
    }

    if (canvaAuthWindow) {
      canvaAuthWindow.focus();
      return;
    }

    window.addEventListener("message", canvaEventFunc);

    callBackendAPI("canva/auth", "GET", {}).then((response) => {
      const popup = window.open(
        `${URLS.CANVA_BACKEND_API}/canva/redirect?user_id=${response.userId}&code_verifier=${response.codeVerifier}&redirect_url=${response.url}`,
        "Connect to Canva",
        "toolbar=no,location=no,menubar=no,titlebar=no,scrollbars=yes,resizable=yes,width=500,height=700"
      );

      setCanvaAuthWindow(popup);

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
          window.removeEventListener("message", canvaEventFunc);
          setCanvaAuthWindow(null);
        }
      }, 1000);
    });
  };

  const handleInputChange = (key, value, index) => {
    if (key === "recommendedBullets") {
      const newBulletBenefits = [...productData.recommendedBullets];
      newBulletBenefits[index] = value;
      updateProductData((prevData) => ({
        ...prevData,
        ["recommendedBullets"]: newBulletBenefits,
      }));
      // setBulletPoints(newBulletPoints);
    }else if (key === "bulletBenefits") {
      const newBulletBenefits = [...productData.bulletBenefits];
      newBulletBenefits[index] = value;
      updateProductData((prevData) => ({
        ...prevData,
        ["bulletBenefits"]: newBulletBenefits,
      }));
    }else if (key === "customBadgeValue") {
      console.log("value ==>", value);
      // const newCustomBadgeValue = [...productData.customBadgeText];
      let newCustomBadgeValue = [];
      newCustomBadgeValue[index] = value;
      updateProductData((prevData) => ({
        ...prevData,
        ["customBadgeText"]: newCustomBadgeValue,
      }));
      console.log("newCustomBadgeValue ==>", newCustomBadgeValue);
    }

    else {
      updateProductData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };
  console.log(sectionClick);

  const handlePriceChange = (key, value) => {
    if (isNaN(value)) return;
    if (value.includes('.')) {
      value = value.replace(/^0+(?=\d*\.)/, '0');
    } else {
      value = value.replace(/^0+(?!$)/, ""); 
    }

    if (value === "" || parseFloat(value) < 0) {
      value = "0";
    } else if (parseFloat(value) > 99999) {
      return; // Cap the value at ten thousand
    }
    if (key === "salePrice") {
      setProductPrice({ ...productPrice, salePrice: value });
    } else if (key === "price") {
      setProductPrice({ ...productPrice, price: value });
    }

    dispatch(
      changePriceForSpecific({
        key,
        value,
        productVariants: productVariants[0],
        showSalePrice
      })
    );
  };

  const handleHideSalePrice = () => {
    if (showSalePrice) {
      setShowSalePrice(false);
      setProductPrice({ ...productPrice, enableSalePrice: false });
    } else {
      setShowSalePrice(true);
      setProductPrice({ ...productPrice, enableSalePrice: true });
    }
  };

  const modules = {
    toolbar: {
      container: [
        // [{ size: ["small", false, "large"] }],
        ["bold", "italic", "underline", "strike"],
      ],
    },
  };

  const formats = ["font", "size", "bold", "italic", "underline", "strike"];
  const processContent = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    const paragraphs = tempDiv.getElementsByTagName("p");
    const spans = paragraphs[0].getElementsByTagName("span");
    const allTags = paragraphs[0].querySelectorAll("*");
    Array.from(allTags).forEach((tag) => {
      if (tag.tagName.toLowerCase() !== "span") {
        tag.removeAttribute("class");
      }
    });
    Array.from(spans).forEach((span) => {
      const spanClass = span.className;
      const spanText = span.textContent;
      span.replaceWith(spanText);
      if (!paragraphs[0].classList.contains(spanClass)) {
        if (paragraphs[0].className) {
          paragraphs[0].className += `${spanClass}`;
        } else {
          paragraphs[0].className = spanClass;
        }
      }
    });

    return tempDiv?.innerHTML;
  };
  const handleEditorChange = useCallback(
    (content, delta, source, editor, key) => {
      let hasSizeAttribute = false;
      delta.ops.forEach((op) => {
        if (op.attributes && op.attributes.size) {
          // hasSizeAttribute = true;
          const finalContent = processContent(content);
          if (source === "user" && finalContent) {
            updateProductData((prevData) => ({
              ...prevData,
              [key]: finalContent || content,
            }));
          }
        }
      });

      if (!hasSizeAttribute && source === "user") {
        updateProductData((prevData) => ({
          ...prevData,
          [key]: content,
        }));
      }
    },
    [productData, updateProductData]
  );

  return (
    <div className="right-section-main-container">
      {sectionClick?.productName && (
        <>
          <div className="title-container">Title</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Header</label>
              <input
                className="form-input"
                type="text"
                value={productData?.productName}
                onChange={(e) =>
                  handleInputChange("productName", e.target.value)
                }
              />
            </div>
          </div>
        </>
      )}
      {sectionClick?.price && (
        <>
          <div className="title-container">Price</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Regular price</label>
              <input
                className="form-input"
                type="number"
                value={productPrice?.price}
                onChange={(e) => handlePriceChange("price", e.target.value)}
              />
            </div>
            <div className="sale-price-input-container">
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="input-label"
              >
                Sale price
                <Switch
                  style={{ marginLeft: "5px" }}
                  checked={showSalePrice}
                  onChange={handleHideSalePrice}
                />
              </label>
              {showSalePrice && (
                <input
                  className="form-input"
                  type="number"
                  value={productPrice?.salePrice}
                  onChange={(e) =>
                    handlePriceChange("salePrice", e.target.value)
                  }
                />
              )}
            </div>
            {isFrom !=='Other' && condition_array?.length > 0 && 
            <div className="input-container">
              <label className="input-label">Gross Profit </label>
              {/* <input
                className="form-input"
                type="number"
                value={productPrice?.price}
                // onChange={(e) => handlePriceChange("price", e.target.value)}
              /> */}

              <div className="grossprofit-con">
                <span style={{ color: grossProfit < 0 ? "red" : "grey" }}>
                  $ {grossProfit === 0 || !grossProfit ? "-" : grossProfit}
                </span>
              </div>
            </div>
            }
          </div>
        </>
      )}

      {sectionClick?.shippingLink && (
        <>
          <div className="title-container">Shipping Link</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Link</label>
              <input
                className="form-input"
                type="text"
                placeholder={'Default value: /policies/shipping-policy'}
                value={productData?.shippingLink}
                onChange={(e) =>
                  handleInputChange("shippingLink", e.target.value)
                }
              />
            </div>
          </div>
        </>
      )}

      {selectImageInfo?.status && (
        <>
          <div className="title-container">
            <div className="flex-between">
              <span>Section media</span>
              <ConnectCanva
                handleCanva={handleCanva}
                isCanvaConnected={isCanvaConnected}
              />
            </div>
          </div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Media</label>

              <SectionEditImageContainer
                productImages={productImages}
                setProductImages={setProductImages}
                toUpdateImageDetails={toUpdateImageDetails}
                trueKey={selectImageInfo?.trueKey}
                imageUrl={
                  selectImageInfo?.trueKey === "section6Image"
                    ? textOnImageDetails.img
                    : sectionsImages[selectImageInfo?.trueKey]
                }
                sectionsImages={sectionsImages}
                setSectionsImages={setSectionsImages}
                setTextOnImageDetails={setTextOnImageDetails}
                aliexpressImages={aliexpressImages}
                isCanvaConnected={isCanvaConnected}
                awaitingRefresh={awaitingRefresh}
                setAwaitingRefresh={setAwaitingRefresh}
                isEditCanvaLoading={isEditCanvaLoading}
                setIsEditCanvaLoading={setIsEditCanvaLoading}
              />
            </div>
          </div>
        </>
      )}

      {Object.keys(sectionClick).some(
        (key) => key.includes("gallery") && sectionClick[key]
      ) && (
        <>
          <div className="title-container">
            <div className="flex-between">
              <span>Section media</span>
              <ConnectCanva
                handleCanva={handleCanva}
                isCanvaConnected={isCanvaConnected}
              />
            </div>
          </div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Media</label>
              {/* <Spin spinning={isEditCanvaLoading}> */}
              <DndProvider backend={HTML5Backend}>
                <GalleryEditImageContainer
                  imageCache={imageCache}
                  toUpdateImageDetails={toUpdateImageDetails}
                  productImages={productImages}
                  setProductImages={setProductImages}
                  sectionsImages={sectionsImages}
                  setSectionsImages={setSectionsImages}
                  aliexpressImages={aliexpressImages}
                  isCanvaConnected={isCanvaConnected}
                  awaitingRefresh={awaitingRefresh}
                  setAwaitingRefresh={setAwaitingRefresh}
                  isEditCanvaLoading={isEditCanvaLoading}
                  setIsEditCanvaLoading={setIsEditCanvaLoading}
                />
              </DndProvider>
              {/* </Spin> */}
            </div>
          </div>
        </>
      )}
      {sectionClick?.productVariants && (
        <ProductVariantsComponent
          productImages={productImages}
          setProductImages={setProductImages}
          sectionsImages={sectionsImages}
          setSectionsImages={setSectionsImages}
          toUpdateImageDetails={toUpdateImageDetails}
          shipsFrom={shipsFrom}
          variantView={variantView}
          setVariantViewChange={setVariantViewChange}
          handleCanva={handleCanva}
          isCanvaConnected={isCanvaConnected}
          awaitingRefresh={awaitingRefresh}
          setAwaitingRefresh={setAwaitingRefresh}
          isEditCanvaLoading={isEditCanvaLoading}
          setIsEditCanvaLoading={setIsEditCanvaLoading}
        />
      )}

      {sectionClick?.featureUnderProductNameSection && (
        <PetsKidsFeatureUnderPNEditComponent
          {...{ productData, handleInputChange }}
        />
      )}
      {sectionClick?.shippingRiskFreeReturns && (
        // <ShippingRiskFreeReturnsComponent {...{ productData, handleInputChange, handleCustomBadgeChange, customBadgeText } } />
        <ShippingRiskFreeReturnsComponent handleCustomBadgeChange={handleCustomBadgeChange}
        setCustomBadgeText={setCustomBadgeText}
        customBadgeText={customBadgeText}
        UploadImage={UploadImage}
        customTextData={customTextData}
        fileList1={fileList1}
        setFileList1={setFileList1}
        previewImage1={previewImage1}
        setPreviewImage1={setPreviewImage1}
        fileList2={fileList2}
        setFileList2={setFileList2}
        previewImage2={previewImage2}
        setPreviewImage2={setPreviewImage2}
        fileList3={fileList3}
        setFileList3={setFileList3}
        previewImage3={previewImage3}
        setPreviewImage3={setPreviewImage3} 
        colorsPallet = {colorsPalette}/>
      )}
      {sectionClick?.testimonialMainSection && (
        <PetsKidsMainTestimonialEditComponent
          {...{
            testimonialMainSectionText: productData.testimonial,
            testimonialMainSectionName: productData.testimonialWriters[0].name,
            image: sectionsImages["mainTestimonialSectionImage"],
            setSectionsImages,
            handleInputChange,
            isCanvaConnected,
            awaitingRefresh,
            setAwaitingRefresh,
            isEditCanvaLoading,
            setIsEditCanvaLoading,
            updateProductData,
          }}
        />
      )}
      {sectionClick?.salesTextHeadline1Section && (
        <div className="input-container">
          <label className="input-label">Headling</label>
          <input
            className="form-input"
            type="text"
            value={productData["salesTextHeadline"]}
            onChange={(e) =>
              handleInputChange("salesTextHeadline", e.target.value)
            }
          />
        </div>
      )}
      {sectionClick?.salesText1Section && (
        <div className="input-container">
          <label className="input-label">Text</label>
          <ReactQuill
            ref={reactQuillRef}
            className="text-editor-input"
            value={productData["salesText"]}
            onChange={(content, delta, source, editor) =>
              handleEditorChange(content, delta, source, editor, "salesText")
            }
            placeholder="Write something..."
            modules={modules}
            formats={formats}
          />
        </div>
      )}

      {sectionClick?.testimonialsHeadingSection && (
        <div className="input-container">
          <label className="input-label">Headling</label>
          <input
            className="form-input"
            type="text"
            value={productData["testimonialHeadline"]}
            onChange={(e) =>
              handleInputChange("testimonialHeadline", e.target.value)
            }
          />
        </div>
      )}

      {sectionClick?.testimonialsColorSection  && colorPalette.toLowerCase()!='default' && (
        <>
        <div className="title-container">Select Testimonial Colors</div>
         <ColorsPicker
              title="Heading Color"
              name="testimonial_section_heading_color"
              defaultValue={colorsPalette?.testimonial_section_heading_color}
          />
          <ColorsPicker
              title="Background Color"
              name="testimonial_section_background"
              defaultValue={colorsPalette?.testimonial_section_background}
          />
        </>
      )}

      {sectionClick?.testimonialsSubHeadingSection && (
        <>
          <div className="input-container">
            <label className="input-label">Sub Headline 1</label>
            <input
              className="form-input"
              type="text"
              value={productData["testimonialSubHeadline"]}
              onChange={(e) =>
                handleInputChange("testimonialSubHeadline", e.target.value)
              }
            />
          </div>

          <div className="input-container">
            <label className="input-label">Sub Headline 2</label>
            <input
              className="form-input"
              type="text"
              value={productData["testimonialSubHeadline2"]}
              onChange={(e) =>
                handleInputChange("testimonialSubHeadline2", e.target.value)
              }
            />
          </div>
        </>
      )}

      {sectionClick?.questionHeadlineSection && (
        <div className="input-container">
          <label className="input-label">Headling </label>
          <input
            className="form-input"
            type="text"
            value={productData["questionHeadline"]}
            onChange={(e) =>
              handleInputChange("questionHeadline", e.target.value)
            }
          />
        

        </div>
        
      )}

      {sectionClick?.answerBenefitsTopLeftSection && (
        <AnswerBenefits
          {...{
            updateProductData,
            index: 0,
            title: productData["answerBenefits"][0].title,
            description: productData["answerBenefits"][0].description,
            colorPalette:colorPalette,
            colorsPalette:colorsPalette
          }}
        />
      )}
      {sectionClick?.answerBenefitsBottomLeftSection && (
        <AnswerBenefits
          {...{
            updateProductData,
            index: 1,
            title: productData["answerBenefits"][1].title,
            description: productData["answerBenefits"][1].description,
            colorPalette:colorPalette,
            colorsPalette:colorsPalette
          }}
        />
      )}
      {sectionClick?.answerBenefitsTopRightSection && (
        <AnswerBenefits
          {...{
            updateProductData,
            index: 2,
            title: productData["answerBenefits"][2].title,
            description: productData["answerBenefits"][2].description,
            colorPalette:colorPalette,
            colorsPalette:colorsPalette
          }}
        />
      )}
      {sectionClick?.answerBenefitsBottomRightSection && (
        <AnswerBenefits
          {...{
            updateProductData,
            index: 3,
            title: productData["answerBenefits"][3].title,
            description: productData["answerBenefits"][3].description,
            colorPalette:colorPalette,
            colorsPalette:colorsPalette
          }}
        />
      )}

      {/* howToUseStepsSection,
    howToUseTitleSection, */}
      {sectionClick?.howToUseTitleSection && (
        <div className="input-container">
          <label className="input-label">Headling</label>
          <input
            className="form-input"
            type="text"
            value={productData["howToUseTitle"]}
            onChange={(e) => handleInputChange("howToUseTitle", e.target.value)}
          />
        </div>
      )}

      
      {sectionClick?.howToUseStepsSection && (
        <>
          {/* <div class="title-container mb-5">Steps</div> */}
          {productData.howToUseSteps.map((step, index) => {
            return (
              <div className="input-container ">
                <label className="input-label !font-bold">
                  Step {index + 1}
                </label>
                <input
                  className="form-input"
                  type="text"
                  value={step.title}
                  onChange={(e) =>
                    updateProductData((prev) => {
                      const newHowToUseSteps = [...prev.howToUseSteps];
                      newHowToUseSteps[index] = {
                        ...newHowToUseSteps[index],
                        title: e.target.value,
                      };
                      return { ...prev, howToUseSteps: newHowToUseSteps };
                    })
                  }
                />
                <textarea
                  rows={"3"}
                  className="form-input"
                  type="text"
                  value={step.description}
                  PetsKidsTemplateCollpseContentEditComponent        onChange={(e) =>
                    updateProductData((prev) => {
                      const newHowToUseSteps = [...prev.howToUseSteps];
                      newHowToUseSteps[index] = {
                        ...newHowToUseSteps[index],
                        description: e.target.value,
                      };
                      return { ...prev, howToUseSteps: newHowToUseSteps };
                    })
                  }
                />
              </div>
            );
          })}
          { colorPalette.toLowerCase()!='default' && (
            <>
            
           <ColorsPicker
              title="Text Color"
              name="steps_section_text_color"
              defaultValue={colorsPalette?.steps_section_text_color}
          />
          <ColorsPicker
              title="Background Color"
              name="steps_section_background"
              defaultValue={colorsPalette?.steps_section_background}
          />
          </>
          )}
        </>
      )}
      {/*  ,
    recommendedBulletsSection, */}
      {sectionClick?.recommendedHeadlineSection && (
        <div className="input-container">
          <label className="input-label">Headling</label>
          <input
            className="form-input"
            type="text"
            value={productData["recommendedHeadline"]}
            onChange={(e) =>
              handleInputChange("recommendedHeadline", e.target.value)
            }
          />
          {colorPalette.toLowerCase()!='default' && (
<>
          <ColorsPicker
              title="Text Color"
              name="recommend_heading_color"
              defaultValue={colorsPalette?.recommend_heading_color}
          />
          <ColorsPicker
              title="Background Color"
              name="recommend_heading_background"
              defaultValue={colorsPalette?.recommend_heading_background}
          />
</>
)}

        </div>
      )}
 
      {sectionClick.recommendedColorSection && (
          <>

           <ColorsPicker
              title="Text Color"
                name="recommend_section_heading_color"
                defaultValue={colorsPalette?.recommend_section_heading_color}
            />
            <ColorsPicker
                title="Background Color"
                name="recommend_section_background"
                defaultValue={colorsPalette?.recommend_section_background}
            />
            </>
        )}

      {sectionClick?.recommendedBulletsSection &&
        productData.recommendedBullets.map((bullet, index) => {
          return (
            <div className="input-container">
              <label className="input-label">Bullet benefit {index + 1}</label>
              <input
                className="form-input"
                type="text"
                value={bullet}
                onChange={(e) =>
                  updateProductData((prev) => {
                    const newRecommendedBullets = [...prev.recommendedBullets];
                    newRecommendedBullets[index] = e.target.value;
                    return {
                      ...prev,
                      recommendedBullets: newRecommendedBullets,
                    };
                  })
                }
              />
            </div>
          );
        })}


        

      {/* ************** */}

      {/*
         saleSubSections
         ,
      */}
      {sectionClick.saleColorSection && colorPalette.toLowerCase()!='default'  &&(
         <>
          <div className="title-container">Select Sale Section Colors</div>

         <ColorsPicker
              title="Text Color"
              name="sale_section_heading_color"
              defaultValue={colorsPalette?.sale_section_heading_color}
          />
          <ColorsPicker
              title="Background Color"
              name="sale_section_background"
              defaultValue={colorsPalette?.sale_section_background}
          />
        </>  
      )}

      {sectionClick?.saleHeadlineSection && (
        <div className="input-container">
          <label className="input-label">Headling</label>
          <input
            className="form-input"
            type="text"
            value={productData["saleHeadline"]}
            onChange={(e) => handleInputChange("saleHeadline", e.target.value)}
          />
        </div>
      )}

      {sectionClick?.saleSubSections1 && (
        <SaleSubSectionEditComponent
          {...{
            updateProductData,
            index: 0,
            question: productData["saleSubSections"][0].question,
            answerTitle: productData["saleSubSections"][0].answerTitle,
            answerText: productData["saleSubSections"][0].answerText,
          }}
        />
      )}
      {sectionClick?.saleSubSections2 && (
        <SaleSubSectionEditComponent
          {...{
            updateProductData,
            index: 1,
            question: productData["saleSubSections"][1].question,
            answerTitle: productData["saleSubSections"][1].answerTitle,
            answerText: productData["saleSubSections"][1].answerText,
          }}
        />
      )}
      {sectionClick?.saleSubSections3 && (
        <SaleSubSectionEditComponent
          {...{
            updateProductData,
            index: 2,
            question: productData["saleSubSections"][2].question,
            answerTitle: productData["saleSubSections"][2].answerTitle,
            answerText: productData["saleSubSections"][2].answerText,
          }}
        />
      )}
      {sectionClick?.saleSubSections4 && (
        <SaleSubSectionEditComponent
          {...{
            updateProductData,
            index: 3,
            question: productData["saleSubSections"][3].question,
            answerTitle: productData["saleSubSections"][3].answerTitle,
            answerText: productData["saleSubSections"][3].answerText,
          }}
        />
      )}
      {/* ******* */}

      {sectionClick?.testimonial1Section && (
        <PetsKidsTestimonialsComponent
          {...{
            testimonialsName: productData?.testimonialWriters[1].name,
            testimonialsAge: productData?.testimonialWriters[1].age,
            testimonialsText: productData?.testimonial2,
            updateProductData,
            image: sectionsImages["testimonialImage1"],
            toWhich: "testimonialImage1",
            setSectionsImages,
            handleInputChange,
            isCanvaConnected,
            awaitingRefresh,
            setAwaitingRefresh,
            isEditCanvaLoading,
            setIsEditCanvaLoading,
            index: 2,
            colorsPalette:colorsPalette

          }}
        />
      )}
      {sectionClick?.testimonial2Section && (
        <PetsKidsTestimonialsComponent
          {...{
            testimonialsName: productData?.testimonialWriters[2].name,
            testimonialsAge: productData?.testimonialWriters[2].age,
            testimonialsText: productData?.testimonial3,
            updateProductData,

            image: sectionsImages["testimonialImage2"],
            toWhich: "testimonialImage2",
            setSectionsImages,
            handleInputChange,
            isCanvaConnected,
            awaitingRefresh,
            setAwaitingRefresh,
            isEditCanvaLoading,
            setIsEditCanvaLoading,
            index: 3,
            colorsPalette:colorsPalette
          }}
        />
      )}
      {sectionClick?.testimonial3Section && (
        <PetsKidsTestimonialsComponent
          {...{
            testimonialsName: productData?.testimonialWriters[3].name,
            testimonialsAge: productData?.testimonialWriters[3].age,
            testimonialsText: productData?.testimonial4,
            updateProductData,

            image: sectionsImages["testimonialImage3"],
            toWhich: "testimonialImage3",
            setSectionsImages,
            handleInputChange,
            isCanvaConnected,
            awaitingRefresh,
            setAwaitingRefresh,
            isEditCanvaLoading,
            setIsEditCanvaLoading,
            index: 4,
            colorsPalette:colorsPalette
          }}
        />
      )}
      {sectionClick?.collapseHeadingSection && (
        <ProTemplateCollpseHeadingEditComponent {...{ FAQData, setFAQData }} />
      )}

      {sectionClick?.collapseContainerSection && (
        <PetsKidsTemplateCollpseContentEditComponent
          {...{ FAQData, updateProductData, collapseIndex, setSectionClick }}
        />
      )}

      {/*     moneybackGuaranteeHeadlineSection
       */}
      {sectionClick?.moneybackGuaranteeHeadlineSection && (
        <div className="input-container">
          <label className="input-label">Headling</label>
          <input
            className="form-input"
            type="text"
            value={productData["moneybackGuaranteeHeadline"]}
            onChange={(e) =>
              handleInputChange("moneybackGuaranteeHeadline", e.target.value)
            }
          />
        </div>
      )}


      {sectionClick?.moneybackGuaranteeTextSection && (
        <div className="input-container">
          <label className="input-label">Text</label>
          <ReactQuill
            ref={reactQuillRef}
            className="text-editor-input"
            value={productData["moneybackGuaranteeText"]}
            onChange={(content, delta, source, editor) =>
              handleEditorChange(
                content,
                delta,
                source,
                editor,
                "moneybackGuaranteeText"
              )
            }
            placeholder="Write something..."
            modules={modules}
            formats={formats}
          />
        </div>
      )}

      {sectionClick.moneybackGuaranteeColorSection && colorPalette.toLowerCase()!='default' && (
          <>
                    <div className="title-container">Select Guarantee Section Colors</div>

          <ColorsPicker
               title="Text Color"
               name="dark_section_heading_color"
               defaultValue={colorsPalette?.dark_section_heading_color}
           />
           <ColorsPicker
               title="Background Color"
               name="dark_section_background"
               defaultValue={colorsPalette?.dark_section_background}
           />
         </>

      )}


       {sectionClick?.buttonColors  && colorPalette.toLowerCase()!='default' && (
            <ButtonColorSection 
              colorsPallet={colorsPalette}
            />
        )}

      {/* end */}
    </div>
  );
};

export default PetsKidsTemplateRightSection;

const AnswerBenefits = ({ title, description, updateProductData, index ,colorPalette, colorsPalette}) => {
  const onChange = (name, value) => {
    updateProductData((prev) => {
      const newAnserBenefits = [...prev.answerBenefits];
      newAnserBenefits[index] = { ...newAnserBenefits[index], [name]: value };
      return {
        ...prev,
        answerBenefits: newAnserBenefits,
      };
    });
  };

  return (
    <div className="input-container">
      <label className="input-label">title</label>
      <input
        className="form-input"
        type="text"
        value={title}
        onChange={(e) => onChange("title", e.target.value)}
      />
      <label className="input-label">Description</label>
      <textarea
        rows="5"
        className="form-input"
        type="text"
        value={description}
        onChange={(e) => onChange("description", e.target.value)}
      />
        {  colorPalette.toLowerCase()!='default' && (

      <ColorsPicker
          title="Heading Color of Features"
          name="feature_heading_color"
          defaultValue={colorsPalette?.feature_heading_color}
      />
      )}
    </div>
  );
};
const SaleSubSectionEditComponent = ({
  updateProductData,
  index,
  question,
  answerTitle,
  answerText,
}) => {
  const onChange = (name, value) => {
    updateProductData((prev) => {
      const saleSubSections = [...prev.saleSubSections];
      saleSubSections[index] = { ...saleSubSections[index], [name]: value };
      return {
        ...prev,
        saleSubSections: saleSubSections,
      };
    });
  };

  return (
    <div className="input-container">
      <label className="input-label">Question</label>
      <input
        className="form-input"
        type="text"
        value={question}
        onChange={(e) => onChange("question", e.target.value)}
      />
      <label className="input-label">Answer Title</label>
      <textarea
        rows="5"
        className="form-input"
        type="text"
        value={answerTitle}
        onChange={(e) => onChange("answerTitle", e.target.value)}
      />
      <label className="input-label">Answer Text</label>
      <textarea
        rows="5"
        className="form-input"
        type="text"
        value={answerText}
        onChange={(e) => onChange("answerText", e.target.value)}
      />
    </div>
  );
};
const PetsKidsTemplateCollpseContentEditComponent = ({
  FAQData,
  updateProductData,
  collapseIndex,
  setSectionClick,
}) => {

  console.log("FAQDATAA------",FAQData, collapseIndex);

  return (
    <>
      <div className="title-container">
      <p className="w-[25%]">Collapse Content</p>
        <div
        onClick={() => {
          updateProductData((prev) => {
            const newData = [...prev.frequentlyAskedQuestions];
            newData.splice(collapseIndex, 1);
            setSectionClick((prev) => ({ ...prev, collapseContainerSection: false}));
            return {
              ...prev,
              frequentlyAskedQuestions: newData,
            };  
          })
        }}
        class="flex w-full justify-right text-center !text-red-600"
      >
        <h4
          class="text-lg font-bold cursor-pointer !text-red-600"
        >
          Delete FAQ
        </h4>
      </div>
      </div>
      <div className="input-main-container">
        <div className="input-container">
          <label className="input-label">Heading</label>
          <input
            className="form-input"
            type="text"
            value={FAQData[collapseIndex].question}
            onChange={(e) =>
              updateProductData((prev) => {
                const newData = [...prev.frequentlyAskedQuestions];
                newData[collapseIndex] = {
                  ...newData[collapseIndex],
                  question: e.target.value,
                };
                return { ...prev, frequentlyAskedQuestions: newData };
              })
            }
          />
        </div>
        <div className="input-container">
          <label className="input-label">Answer</label>
          <input
            className="form-input"
            type="text"
            value={FAQData[collapseIndex].answer}
            onChange={(e) =>
              updateProductData((prev) => {
                const data = [...prev.frequentlyAskedQuestions];
                data[collapseIndex] = {
                  ...data[collapseIndex],
                  answer: e.target.value,
                };
                return { ...prev, frequentlyAskedQuestions: data };
              })
            }
          />
        </div>
      </div>
    </>
  );
};

import React, { useState } from "react";
import productDetailsIcon from "../../../assets/product-details.svg";
import arrowDownIcon from "../../../assets/arrow-down-icon.svg";
import { Button, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setTemplateView, setcolorPalette, setColorPaletteColors} from "../../../redux/slices/template-slice";

import ColorPalletSelecter from './ColorPalletSelecter';
const ProductDetails = ({
  handleConfirm,
  tone,
  setTone,
  setSelectedLanguage,
  selectedLanguage,
  productTitle,
  setProductTitle
}) => {
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const { colorPalette } = useSelector((store) => store.templateSlice);
  const [selectedColorPalette, setSelectedColorPalette] = useState(
    colorPalette ? colorPalette.charAt(0).toUpperCase() + colorPalette.slice(1) : "Default");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirm();
    }
  };

  const handleMenuClick = (e) => {
    setSelectedLanguage(e.key);
  };
    const handleColorPaleteClick = (e) => {
      console.log(e.key,'pppppppppp pp pp ')
        setSelectedColorPalette(e.key)
        dispatch(setcolorPalette(e.key));

      }

  const languageDropdownProps = {
    items: [
      {
        key: "English",
        label: "English",
      },
      {
        key: "Spanish",
        label: "Spanish",
      },
    ],
    onClick: handleMenuClick,
  };

  

  return (
    <div className="pr-[10px] w-full mb-5">
      <div className="w-full border border-[#E0E0E0] rounded-lg p-4 flex flex-col gap-2">
        <div
          className="sticky flex flex-row justify-between items-center pr-4 cursor-pointer select-none gap-4"
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className="flex flex-row items-start gap-4">
            <img src={productDetailsIcon} alt="productDetailsIcon" />
              <div className="modal-content-container">
                <h4>Product details</h4>
                <p>These details help Sofia generate text content for the product page.</p>
              </div>
          </div>
          <img
            src={arrowDownIcon}
            alt="arrow down icon"
            className={`w-[20px] ${collapsed ? "transform rotate-180" : ""}`}
          />
        </div>
        {collapsed && (
          <div className="modal-content-container wide flex flex-col overflow-scroll w-full p-2">
            <form
              style={{gap: "0px"}}
              onSubmit={handleConfirm}
              className="input-form"
            >
              <span className="mt-[16px] font-medium">
                  Describe the sales angle for this product
                </span>
              <input
                id="link-input"
                value={tone}
                onChange={(e) => setTone(e.target.value)}
                style={{marginTop: "8px", width: '50%'}}
                className="form-control mb-[16px]"
                type="text"
                placeholder="sales angle"
                onKeyDown={handleKeyPress}
              />
              <span className="mt-[16px] font-medium">
                  Custom title (optional)
                </span>
                <input
                  id="link-input"
                  value={productTitle}
                  onChange={(e) => setProductTitle(e.target.value)}
                  style={{ marginTop: "8px",width: '50%' }}
                  className="form-control mb-[16px]"
                  type="text"
                  placeholder="Enter Product Title"
                  onKeyDown={handleKeyPress}
                />
              <span className="mt-[16px] mb-[10px] font-medium">Select language</span>
              <Dropdown
                menu={languageDropdownProps}
                trigger={["click"]}
                className="language-selector-dropdown mb-[16px]"
              >
                <Button>
                  <Space>
                    {selectedLanguage}
                    <DownOutlined/>
                  </Space>
                </Button>
              </Dropdown>

              <ColorPalletSelecter 
                selectedColorPalette={selectedColorPalette}
                handleColorPaleteClick={handleColorPaleteClick}
              />


               

            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductDetails;

import { Table } from "antd"
import { useEffect, useState } from "react"
import { currencyUnit } from "../../../constantData/variantsData";
import './MissingShippingMethod.css'
import { useDispatch, useSelector } from "react-redux";
import {
  changeShippingMethod,
  setImportedShippingData,
  variantMissingShippingGetOnlyMissingMethods
} from "../../../redux/slices/replace-variant-slice";

const MissingShippingMethod = ({
  children,
  shippingData,
  completeItem,
  setSelectedShippingMethod,
  shippingLoading,
  fetchShippingInfo,
  missingShipping,
  changeCountry,
  setChangedCountry,
  itemOrderId,
  country,
}) => {
  const {data} = useSelector(store => store.replaceVariant)

  const [selectedRowKey, setSelectedRowKey] = useState([])

  useEffect(() => {
    dispatch(setImportedShippingData({itemOrderId, data: shippingData}))
  }, [shippingData])

  useEffect(() => {
    if (missingShipping) {
      const {
        aliexpres_response: aliexpress_response,
        external_product_id,
        aliexpress_product_sku,
        product_id
      } = completeItem;
      const {ae_store_info, ae_item_sku_info_dtos} = aliexpress_response
      const {store_country_code} = ae_store_info
      const {ae_item_sku_info_d_t_o} = ae_item_sku_info_dtos
      const {currency_code, sku_id} = ae_item_sku_info_d_t_o[0]
      dispatch(variantMissingShippingGetOnlyMissingMethods({
        itemOrderId,
        external_product_id,
        aliexpress_product_sku, product_id
      }))
      fetchShippingInfo({
        store_country_code,
        external_product_id,
        currency_code,
        sku_id
      })
    }
    // detch methods if exist for that item

  }, [completeItem])

  useEffect(() => {
    const element = document.querySelectorAll('.ant-table-selection-column')
    element.forEach(row => {
      if (!row.innerHTML) {
        row.innerText = 'Select'
      }
    });
    // return () => setData([])
  }, [])
  const columns = [
    {
      title: 'Shipping Service',
      dataIndex: 'shippingService',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      // width: '10%',
      render: (text, record) => <span className='flex gap-2'>
          <p>{currencyUnit[record?.costUnit] || ''}</p>{text}
      </span>,
    },
    {
      title: 'Estimated deliver time',
      dataIndex: 'estimatedDeliveryTime',
    },
    {
      title: 'Tracking',
      dataIndex: 'tracking',
    },
  ];
  const dispatch = useDispatch()

  const rowSelection = {
    selectedRowKeys: selectedRowKey,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedShippingMethod(selectedRows[0])
      setSelectedRowKey(selectedRowKeys)
      dispatch(changeShippingMethod({itemOrderId, method: selectedRows[0]}))
    },

  };
  return (
    <>
      {
        !missingShipping && data[itemOrderId]?.length === 0 ? <>{children}</> :
          <Table

            loading={shippingLoading}
            locale={{emptyText: children}}
            pagination={false}
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            columns={columns}
            dataSource={data[itemOrderId]}
          />
      }
      {
        data[itemOrderId]?.length > 0 && <button className="table-back-btn">
          <p className="cursor-pointer" onClick={() => {
            setChangedCountry(false)
            setSelectedShippingMethod(undefined)
            setSelectedRowKey([])
            dispatch(setImportedShippingData({itemOrderId, data: []}))
          }}>
            {"<- Back to product replacement"}
          </p>
          {changeCountry &&
            <p className={`${shippingLoading && 'text-[lightgrey] cursor-not-allowed'} cursor-pointer  flex justify-end`}
               onClick={() => {
                 if (shippingLoading) {
                   return
                 }
                 fetchShippingInfo(country)
                 setSelectedShippingMethod(undefined)
                 setChangedCountry(false)
                 setSelectedRowKey([])
               }}>
              {
                'Fetch Shipping Country ->'
              }
            </p>}
        </button>
      }
    </>
  )

}
export default MissingShippingMethod

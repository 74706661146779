import React from "react";
import DOMPurify from "dompurify";
import "./Sections.css";
import { reformatText } from "../PreLanderEditView";

const TextImageSection = ({
  blockId,
  settings,
  selectedBlock,
  handleSelectBlock,
}) => {
  const sectionText = DOMPurify.sanitize(reformatText(settings.text));

  return (
    <div
      className={`title-section mb-5 ${selectedBlock === blockId ? "outline-border-selected" : "outline-border1"}`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <h3 style={{ fontSize: settings.title_size, color: settings.title_color }} className="mb-5">{settings.title}</h3>
      {settings.show_image && settings.default_url &&
      <div
        style={{ 
          backgroundImage: `url(${settings.default_url})`, 
          paddingTop: '50%', 
          backgroundRepeat: 'no-repeat', 
          backgroundSize: '100%', 
          backgroundPosition: 'center' 
        }}
        >          
      </div>
      }
      {sectionText && (
        <div className="edit-formatted-text-pre-lander">
          <div style={{ fontSize: settings.text_size, color: settings.text_color }} dangerouslySetInnerHTML={{ __html: sectionText }}></div>
        </div>
      )}
    </div>
  )
}

export default TextImageSection;

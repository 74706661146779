import React, { useEffect, useState } from "react";
import "./ProductEditView.css";
import TopBar from "./TopBar";
import PrductGallery from "./PrductGallery";
import { useParams } from "react-router-dom";
import { convertResponseJsonToObject } from "../../Utilities/ConvertInObjectForm";
import { Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import VariantPricingTable from "../VariantPricingTable";
import ShippingConfigurationDetails from "../ShippingConfigurationDetails";
import { useDispatch, useSelector } from "react-redux";

import getPriceOfUniquProperties from "../../Utilities/productVariantsHandlers/getPriceOfUniquProperties";
import useVariantsData from "../hooks/useVariantsData";
import varinatsShippedFrom from "../../Utilities/productVariantsHandlers/VariantsShippedFrom";
import SavePublishModal from "./TopBar/SavePublishModal";
import { setArragedVariantsData } from "../../redux/slices/product-variants-slice";
import { setStaticMediaImagesInStateimport } from "../../redux/slices/static-media-images-slice";
import ProTemplatePriceAndHeading from "../ProTemplate/ProTemplatePriceAndHeading";
import ProductVariantsSection from "./ProductVariantsSection/ProductVariantsSection";
import ProTemplateCarousel from "../ProTemplate/ProTemplateCarousel";
import ProTemplatePrecautionDeatils from "../ProTemplate/ProTemplatePrecautionDeatils";
import ProTemplateFeatures from "../ProTemplate/ProTemplateFeatures";
import ProTemplateCollapseContainer from "../ProTemplate/ProTemplateCollapseContainer";
import ProTemplateRightSection from "./RightSection/ProTemplateRightSection";
import ProTemplateFirstContentBox from "../ProTemplate/ProTemplateFirstContentBox";
import ProTemplateSecondContentBox from "../ProTemplate/ProTemplateSecondContentBox";
import ProTemplateThirdContentBox from "../ProTemplate/ProTemplateThirdContentBox";
import ProTemplateComparisonTable from "../ProTemplate/ProtemplateComparisonTable";
import ProductFooter from "./ProductFooter";
import GeneratePreLandingConfirmModal from "./TopBar/GeneratePreLandingConfirmModal";

const details = `Lorem Ipsum is simply dummy text of the printing and 
typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
 when an unknown printer took a galley of type and scrambled it to make a type specimen book`;
const img = "https://miro.medium.com/v2/resize:fit:2400/0*hDAyhnOx767w5qma.jpg";
const imgSmall =
  "https://qtlbfsuc9dj5tkh0-61932142812.shopifypreview.com/cdn/shop/files/Crags_MetalTin_200x_a5a11298-cece-443e-9cf3-304041af7eab.png?v=1730283854&width=100";


const ProTemplateEditView = () => {
  const dispatch = useDispatch();

  // redux states fetcher
  const {
    productData: Data,
    selectedProperties,
    totalEnabledVariants,
    totalAllowedVariants,
    globallyDisabledProperties,
    variantsArrangedIndexesObject: uniqueValuesObject,
  } = useSelector((store) => store.productVariants);

  // hook called
  const {
    propertiesArray,
    uniqueValuesObject: uniqueValue,
    newImagesObject,
    imageKey,
  } = useVariantsData(Data);

  const { data } = Data;
  const { id } = useParams();
  const [productData, setProductData] = useState({});
  const [productPrice, setProductPrice] = useState({ price: 0, salePrice: 0 });
  const { product_variants } = data;
  const [propertiesLength, __] = useState(
    product_variants?.length
  );
  const [showSalePrice, setShowSalePrice] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [loading, _] = useState(false);
  const [editsectionImageUrl, setEditsectionImageUrl] = useState("");
  const [sectionsImages, setSectionsImages] = useState([]);
  const [aliexpressImages, setAliexpressImages] = useState([]);
  const [variantView, setVariantViewChange] = useState("Dropdown");
  const [step, setStep] = useState("Product template");
  const [shipsFrom, setShipsFrom] = useState();
  const [groupBy, setGroupBy] = useState(0);
  const { templateType, colorPalette, colorPaletteColors} = useSelector((store) => store.templateSlice);
  console.log(templateType,colorPalette,'detail of color pro')
  const colorPalettes = colorPaletteColors[templateType][colorPalette.toLowerCase()] || {}

  const [sectionClick, setSectionClick] = useState({
    bulletBenefits: false,
    productName: false,
    salesText1: false,
    salesText2: false,
    salesText3: false,
    salesTextHeadline1: false,
    salesTextHeadline2: false,
    salesTextHeadline3: false,
    socialProofHeadline: false,
    socialProofText: false,
    price: false,
    section1Image: false,
    section2Image: false,
    section3Image: false,
    section6Image: false,
    gallery: false,
    productVariants: false,
    tabsSection: false,
    carouselSection: false,
    reviewsIconSections: false,
    reviewsSection: false,
    featuresSection: false,
    featureHeadingSection: false,
    textOnImageSection: false,
    collapseContainerSection: false,
    tableHeadingSection: false,
    satisfactionGuaranteeHeadline: false,
    tableLabelSection: false,
    tableRowsSection: false,
    companyNameSection: false,
    addRowSection: false,
    collapseHeadingSection: false,
    subTitleSection: false,
    shippingLink: false,
  });

  const [selectedProperty, setSelectedProperty] = useState([]);
  const [savingLoading, setSavingLoading] = useState(false);
  const [showGenerateLanding, setShowGenerateLanding] = useState(false);
  const [saveOrPublish, setSaveOrPublish] = useState("");

  // cache images
  const [imageCache, setImageCache] = useState({});
  const [isImgCachingLoading, setIsImgCachingLoading] = useState(true);

  const preloadImages = async (srcArray) => {
    const cache = {};

    const promises = srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;

        img.onload = () => {
          cache[src] = img;
          resolve(img);
        };

        img.onerror = (error) => {
          console.error(`Error loading image: ${src}`, error);
          reject(error);
        };
      });
    });

    await Promise.all(promises);
    return cache;
  };

  useEffect(() => {
    if (Object.keys(uniqueValuesObject).length === 0) {
      dispatch(setArragedVariantsData(uniqueValue));
    }
  }, [uniqueValue]);

  useEffect(() => {
    const variantImages = imageKey
      ? Object.values(newImagesObject[imageKey])
      : [];
    const sectionImgArray = Object.values(sectionsImages) || [];
    const allImages = [...variantImages, ...sectionImgArray, ...productImages];
    const allUniqueImages = [...new Set(allImages)]; // Remove duplicates

    // Cache all images
    const preloadAndCacheImages = async (allUniqueImages) => {
      try {
        // setIsImgCachingLoading(true);
        const cache = await preloadImages(allUniqueImages);
        setImageCache(cache);
        setIsImgCachingLoading(false);
      } catch (error) {
        console.error("Error preloading images:", error);
        setIsImgCachingLoading(false);
      }
    };
    preloadAndCacheImages(allUniqueImages);
  }, [sectionsImages, productImages]);


  useEffect(() => {
    if (!(product_variants?.length <= 1)) {
      const filtered = varinatsShippedFrom(product_variants, shipsFrom);
      const priceFromFiltered =
        filtered.length > 0 ? filtered : product_variants;
      const variant = getPriceOfUniquProperties(
        selectedProperties,
        Data,
        priceFromFiltered,
        shipsFrom
      );
      setProductPrice({
        price: variant?.price,
        salePrice: variant?.discounted_price,
      });
      setShowSalePrice(variant?.enableSalePrice);
      if(variant?.enableSalePrice){
        if(variant?.price>0){
          setProductPrice({
            price: variant?.price,
            salePrice: variant?.discounted_price,
          });
        }else{
          setProductPrice({
            price: variant?.discounted_price,
            salePrice: 0
          });
          setShowSalePrice(false);
        }
      }
    }
  }, [Data, selectedProperties]);

  useEffect(() => {
    if (Object.keys(uniqueValuesObject).length !== 0) {
      let ind = 0;
      const startingSelection = propertiesArray?.map((select) => {
        uniqueValuesObject[select]?.map((pro, i) => {
          const disable =
            globallyDisabledProperties[select]?.includes(pro) ?? false;
          if (!disable) {
            ind = i;
            return;
          }
        });
        return { [select]: uniqueValuesObject[select][ind] };
      });

      const startSelectionObject = startingSelection?.reduce((acc, item) => {
        Object.entries(item).forEach(([key, value]) => {
          acc[key] = value;
        });

        return acc;
      }, {});

      setSelectedProperty(startSelectionObject);
    }
  }, [Data, uniqueValuesObject]);

  useEffect(() => {
    const reviewImages = data?.productTemplate?.reviews?.map((review) => ({ show: review.show, img: review.img }))
    setReviews(prev => ({
      reviewImages: reviewImages,
      ...data?.productTemplate?.reviews?.reduce((acc, review, index) => {
        acc[index] = review.details;
        return acc;
      }, {})
    }));

    setTestimonials(data?.productTemplate?.testimonials)

    setTabsContent(data?.productTemplate?.tabsContent)
    setTextOnImageDetails({ ...data?.productTemplate?.textOnImageDetails,
       img: data.sectionImages.section4Image })

    setFAQData(data?.productTemplate?.FAQData)
    setFeatures(data?.productTemplate?.features)
    setTableData(data?.productTemplate?.tableData)
    setShipsFrom(Data?.shipsFrom);
    setProductData({
      ...convertResponseJsonToObject(data.productTemplate),
      ["productStatus"]: Data.product_status,
      ["satisfactionGuaranteeHeadline"]: data?.productTemplate?.textOnImageDetails.title,
      ["satisfactionGuaranteeText"]: data?.productTemplate?.textOnImageDetails.details
    });
    setSectionsImages(data.sectionImages);
    //setProductImages(data.media_images );
    setProductImages(data.external_images ? data.external_images : data.aliexpress_images ? data.aliexpress_images : data.media_images);

    setAliexpressImages(data.media_images);

    dispatch(setStaticMediaImagesInStateimport(data.media_images));

    if (product_variants?.length <= 1) {
      setShowSalePrice(product_variants[0]?.enableSalePrice);
      setProductPrice({ price: product_variants[0]?.price });
      if (product_variants[0]?.enableSalePrice) {
        setProductPrice({
          price: product_variants[0]?.price,
          salePrice: product_variants[0]?.discounted_price,
        });
      }
    }

  }, []);


  const handleSectionEditChange = (keys) => {
    const allFalse = Object.keys(sectionClick).reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {});

    const newSectionClick = { ...allFalse };
    keys.forEach((key) => {
      newSectionClick[key] = true;
    });

    setSectionClick(newSectionClick);
  };
  const handleTableComponentsClick = ({ keys, tableComponent, index }) => {
    if (tableComponent === "tableRows") {
      setTableRowIndex(index);
    }
    handleSectionEditChange(keys);
  };
  const hanldeCollapseContentClick = ({ keys, index }) => {
    setCollapseIndex(index);
    handleSectionEditChange(keys);
  };


  const [labelName, setLabelName] = useState(
    propertiesArray?.reduce((acc, prop) => {
      acc[prop] = prop;
      return acc;
    }, {})
  );
  const [tabsContent, setTabsContent] = useState([
    { details: "first tab", heading: "Tab1" },
    { details: "second tab", heading: "Tab2" },
    { details: "third tab", heading: "Tab3" },
  ]);
  const [testimonials, setTestimonials] = useState([
    {
      img: img,
      name: "testing2",
      details: details,
    },
    {
      img: img,
      name: "testing3",
      details: details,
    },
    {
      img: img,
      name: "testing3",
      details: details,
    },
  ]);
  const [reviews, setReviews] = useState({
    '1': `"Lorem Ipsum is simply dummy text of the printing 1"`,
    '2': `"Lorem Ipsum is simply dummy text of 2 "`,
    '3': `"Lorem Ipsum is simply dummy text of 3 "`,
    "reviewImages": [
      { img: imgSmall, show: true },
      { img: "https://qtlbfsuc9dj5tkh0-61932142812.shopifypreview.com/cdn/shop/files/Crags_NotSticky_200x_15ee609d-96fe-4c58-a418-6bebf4252dc6.png?v=1730283852&width=100", show: true },
      { img: "https://qtlbfsuc9dj5tkh0-61932142812.shopifypreview.com/cdn/shop/files/2_f1289625-7de4-4691-a811-ba74d26ddc13.png?v=1730187096&width=100", show: true },
    ]
  });
  const [reviewKey, setReviewKey] = useState('1')
  const [textOnImageDetails, setTextOnImageDetails] = useState({
    title: "60-Day Risk-Free Guarantee",
    details: "Not satisfied with your product? Return it to us, risk-free in 60 days. That’s how confident we are that you’ll like the product.",
    img: img,
  });
  const [FAQData, setFAQData] = useState({
    heading: "Frequently Asked Questions",
    data: [
      {
        title: "Title1",
        details: "Details will be here ",
      },
      {
        title: "Title2",
        details: "detailss will be here ",
      },
      {
        title: "Title3",
        details: "Details will be here ",
      },
    ],
  });
  const [features, setFeatures] = useState({
    heading: "Amazing Features!",
    data: [
      {
        title: "Fast Featue",
        details: `Lorem Ipsum is simply dummy text of the printing and typesetting .`,
      },
      {
        title: "Smooth Featue",
        details: `Lorem Ipsum is simply dummy text of the printing and typesetting .`,
      },
      {
        title: "Fast Featue3",
        details: `Lorem Ipsum is simply dummy text of the printing and typesetting .`,
      },
      {
        title: "Fast Featue4",
        details: `Lorem Ipsum is simply dummy text of the printing and typesetting .`,
      },
    ],
  });
  const [tableData, setTableData] = useState({
    heading: 'We Do It Better',
    tableRows: [
      ["Food", true, false],
      ["Drink", false, true],
      ["Dairy", false, false],
      ["Vegetable", false, false],
      ["Fruit", false, false],
      ["Bakery", false, false],
      ["Meat", false, false],
    ],
    tableProperties: ["Eatable", "combustible"],
  });
  const [companyName, setCompanyName] = useState("Sofia");
  const [tableRowIndex, setTableRowIndex] = useState(null);
  const [collapseIndex, setCollapseIndex] = useState(null);

  const antIcon = (
    <LoadingOutlined
      className="custom-spin-icon"
      style={{ fontSize: "50px" }}
      spin
    />
  );

  // payload for pro template
  const proTemplatePayload = {
    tabsContent,
    testimonials,
    reviews: Object.keys(reviews)
      .filter(key => !isNaN(key))
      .map(key => ({
        details: reviews[key],
        ...reviews.reviewImages[key]
      })),


    textOnImageDetails ,
    FAQData,
    features,
    tableData,
  }

  return isImgCachingLoading ? (
    <div className="spinner-overlay">
      <div className="spinner-container">
        <Spin className="custom-spin" indicator={antIcon} />
      </div>
    </div>
  ) : (
    <div className="product-edit-view-main-container">
      <TopBar
        proTemplatePayload={proTemplatePayload}
        setSaveOrPublish={setSaveOrPublish}
        loading={savingLoading}
        setLoading={setSavingLoading}
        showGenerateLanding={showGenerateLanding}
        setShowGenerateLanding={setShowGenerateLanding}
        shipsFrom={shipsFrom}
        setShipsFrom={setShipsFrom}
        propertiesLength={propertiesLength}
        showSalePrice={showSalePrice}
        productData={productData}
        productPrice={productPrice}
        productImages={productImages}
        sectionsImages={sectionsImages}
        id={id}
        step={step}
        setStep={setStep}
        labelName={labelName}
        setLabelName={setLabelName}
      />

      {step === "Product template" ? (
        <div className="edit-product-content-container">
          <div className="product-main-left-div">
            {
              totalEnabledVariants > totalAllowedVariants ? (
                <Alert
                  message={`Enable Variants (${totalEnabledVariants}) exceed the allowed limit (${totalAllowedVariants})`}
                  type="error"
                  className={"variants-count-error-message"}
                />
              ) : (
                ""
              )
              // <Alert message={`Enable Variants (${totalEnabledVariants}) Grand Total Variants are with ships from (${totalVriants})`} type="success" className={"variants-count-error-message"} />
            }
            <div className="product-main-left-container">
              <div className="edit-product-first-section">
                <PrductGallery
                  setProductImages={setProductImages}
                  imageCache={imageCache}
                  images={productImages}
                  galleryImages={sectionClick?.gallery}
                  handleSectionEditChange={handleSectionEditChange}
                />
                <ProTemplatePriceAndHeading
                  tabsContent={tabsContent}
                  setTabsContent={setTabsContent}
                  imageCache={imageCache}
                  shipsFrom={shipsFrom}
                  propertiesLength={propertiesLength}
                  labelName={labelName}
                  setLabelName={setLabelName}
                  variantView={variantView}
                  productName={productData?.productName}
                  productSubTitle={productData?.productSubTitle}
                  productNameSection={sectionClick?.productName}
                  shippingLink={productData?.shippingLink}
                  shippingLinkSection={sectionClick?.shippingLink}
                  subTitleSection={sectionClick?.subTitleSection}
                  showSalePrice={showSalePrice}
                  setShowSalePrice={setShowSalePrice}
                  productPrice={productPrice}
                  productPriceSection={sectionClick?.price}
                  guaranteeHeading={productData?.guaranteeHeadline}
                  guaranteeSection={sectionClick?.guaranteeHeadline}
                  guaranteeText={productData?.guaranteeText}
                  handleSectionEditChange={handleSectionEditChange}
                  productVariants={sectionClick?.productVariants}
                  selectedProperty={selectedProperty}
                  setSelectedProperty={setSelectedProperty}
                  tabsSection={sectionClick?.tabsSection}
                  colorsPallet={colorPalettes}>
                  {propertiesLength > 1 ? (
                    <ProductVariantsSection
                      labelName={labelName}
                      setLabelName={setLabelName}
                      variantView={variantView}
                      handleSectionEditChange={handleSectionEditChange}
                      productVariants={sectionClick?.productVariants}
                      selectedProperty={selectedProperty}
                      setSelectedProperty={setSelectedProperty}
                      shipsFrom={shipsFrom}
                    />
                  ) : (
                    ""
                  )}
                </ProTemplatePriceAndHeading>
              </div>
              <div className="w-full px-[50px]">
                <ProTemplateCarousel
                  carouselSection={sectionClick?.carouselSection}
                  testimonials={testimonials}
                  handleSectionEditChange={handleSectionEditChange}
                />
              </div>
              <div className="product-middle-main-container">
                <ProTemplateFirstContentBox
                  imageCache={imageCache}
                  heading={productData?.salesTextHeadline1}
                  text={productData?.salesText1}
                  image={sectionsImages?.section1Image}
                  salesTextHeadline1={sectionClick?.salesTextHeadline1}
                  handleSectionEditChange={handleSectionEditChange}
                  editsectionImageUrl={editsectionImageUrl}
                  setEditsectionImageUrl={setEditsectionImageUrl}
                  section1Image={sectionClick?.section1Image}
                />

                {loading && (
                  <div className="spinner-overlay">
                    <div className="spinner-container">
                      <Spin className="custom-spin" indicator={antIcon} />
                    </div>
                  </div>
                )}

                <ProTemplateSecondContentBox
                  imageCache={imageCache}
                  heading={productData?.salesTextHeadline2}
                  text={productData?.salesText2}
                  image={sectionsImages?.section2Image}
                  handleSectionEditChange={handleSectionEditChange}
                  editsectionImageUrl={editsectionImageUrl}
                  setEditsectionImageUrl={setEditsectionImageUrl}
                  section2Image={sectionClick?.section2Image}
                  salesTextHeadline2={sectionClick?.salesTextHeadline2}
                />
                <ProTemplateThirdContentBox
                  imageCache={imageCache}
                  heading={productData?.salesTextHeadline3}
                  text={productData?.salesText3}
                  image={sectionsImages?.section3Image}
                  handleSectionEditChange={handleSectionEditChange}
                  editsectionImageUrl={editsectionImageUrl}
                  setEditsectionImageUrl={setEditsectionImageUrl}
                  section3Image={sectionClick?.section3Image}
                  salesTextHeadline3={sectionClick?.salesTextHeadline3}

                />
              </div>
              <div className="w-full">
                <ProTemplatePrecautionDeatils
                  reviewKey={reviewKey}
                  setReviewKey={setReviewKey}
                  handleSectionEditChange={handleSectionEditChange}
                  reviewsSection={sectionClick?.reviewsSection}
                  reviews={reviews}
                  reviewsIconSections={sectionClick?.reviewsIconSections}

                />
                <ProTemplateComparisonTable
                  tableData={tableData}
                  tableRowIndex={tableRowIndex}
                  setTableRowIndex={setTableRowIndex}
                  tableLabelSection={sectionClick?.tableLabelSection}
                  tableHeadingSection={sectionClick?.tableHeadingSection}
                  addRowSection={sectionClick?.addRowSection}
                  tableRowsSection={sectionClick?.tableRowsSection}
                  handleTableComponentsClick={handleTableComponentsClick}
                />
                <ProTemplateFeatures
                  handleSectionEditChange={handleSectionEditChange}
                  featureSection={sectionClick?.featuresSection}
                  featureHeadingSection={sectionClick?.featureHeadingSection}
                  features={features}
                  colorsPallet={colorPalettes}
                />
                {/* <ProTemplateTexOnImage
                  companyName={companyName}
                  handleSectionEditChange={handleSectionEditChange}
                  textOnImageSection={sectionClick?.textOnImageSection}
                  companyNameSection={sectionClick?.companyNameSection}
                  textOnImageDetails={textOnImageDetails} /> */}
                <div className="!mb-0">

                  <ProductFooter
                    imageCache={imageCache}
                    heading={textOnImageDetails?.title}
                    productFooterSection={
                      sectionClick?.satisfactionGuaranteeHeadline
                    }
                    text={textOnImageDetails?.details}
                    handleSectionEditChange={handleSectionEditChange}
                    section6Image={sectionClick?.section6Image}
                    image={textOnImageDetails?.img}
                  />
                </div>
                <ProTemplateCollapseContainer
                  collapseHeadingSection={sectionClick?.collapseHeadingSection}
                  collapseContainerSection={
                    sectionClick?.collapseContainerSection
                  }
                  hanldeCollapseContentClick={hanldeCollapseContentClick}
                  handleSectionEditChange={handleSectionEditChange}
                  FAQData={FAQData}
                  setCollapseIndex={setCollapseIndex}
                  setFaqData={setFAQData}
                  colorsPalette={colorPalettes}
                />
              </div>

            </div>
          </div>
          <ProTemplateRightSection
            imageCache={imageCache}
            showSalePrice={showSalePrice}
            setShowSalePrice={setShowSalePrice}
            productPrice={productPrice}
            setProductPrice={setProductPrice}
            productData={productData}
            updateProductData={setProductData}
            sectionClick={sectionClick}
            setSectionClick={setSectionClick}
            handleSectionEditChange={handleSectionEditChange}
            editsectionImageUrl={editsectionImageUrl}
            setEditsectionImageUrl={setEditsectionImageUrl}
            sectionsImages={sectionsImages}
            setSectionsImages={setSectionsImages}
            productImages={productImages}
            setProductImages={setProductImages}
            aliexpressImages={aliexpressImages}
            setVariantViewChange={setVariantViewChange}
            variantView={variantView}
            productVariants={product_variants}
            shipsFrom={shipsFrom}
            tabsContent={tabsContent}
            setTabsContent={setTabsContent}
            testimonials={testimonials}
            setTestimonials={setTestimonials}
            reviews={reviews}
            setReviews={setReviews}
            reviewKey={reviewKey}
            setReviewKey={setReviewKey}
            features={features}
            setFeatures={setFeatures}
            textOnImageDetails={textOnImageDetails}
            setTextOnImageDetails={setTextOnImageDetails}
            tableData={tableData}
            setTableData={setTableData}
            tableRowIndex={tableRowIndex}
            setTableRowIndex={setTableRowIndex}
            companyName={companyName}
            setCompanyName={setCompanyName}
            FAQData={FAQData}
            setFAQData={setFAQData}
            collapseIndex={collapseIndex}
            colorsPalette={colorPalettes}
            propertiesLength={propertiesLength}
             />
        </div>
      ) : step === "Variant pricing" ? (
        <VariantPricingTable
          imageCache={imageCache}
          shipsFrom={shipsFrom}
          setShipsFrom={setShipsFrom}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          labelName={labelName}
          setLabelName={setLabelName}
          productPrice={productPrice}
        />
      ) : (
        <ShippingConfigurationDetails shipsFrom={shipsFrom} />
      )}

      <SavePublishModal
        OpenModal={savingLoading}
        saveOrPublish={saveOrPublish}
      />

      <GeneratePreLandingConfirmModal
        openModal={showGenerateLanding}
      />
    </div>
  );
};

export default ProTemplateEditView;

import { createSlice } from "@reduxjs/toolkit";

const preLanderPageSlice = createSlice({
  name: "preLanderPageSlice",

  initialState: {
    data: null,
    lastPublishProduct: null,
    preLanderId: null,
    productHandler: null,
  },
  reducers: {
    setPreLanderData: (state, action) => {
      state.data = action.payload
    },
    setLastPublishProduct: (state, action) => {
      state.lastPublishProduct = action.payload
    },
    setPreLanderId: (state, action) => {
      state.preLanderId = action.payload
    },
    setProductHandler: (state, action) => {
      state.productHandler = action.payload
    },
  }
});

export const {
  setPreLanderData,
  setLastPublishProduct,
  setPreLanderId,
  setProductHandler,
} = preLanderPageSlice.actions;

export default preLanderPageSlice.reducer;


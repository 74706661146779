import React from "react";
import modalTemplateIcon from "../../../../assets/modal-template-icon.svg";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setGeneratePreLander } from "../../../../redux/slices/publish-product-slice";

const GenerateLanderSelector = () => {
  const dispatch = useDispatch();
  const { generatePreLander } = useSelector((store) => store.publishProduct);

  const onChange = (checked) => {
    dispatch(setGeneratePreLander(checked));
  };

  return (
    <div className="pr-[10px] w-full">
      <div className="w-full border border-[#E0E0E0] rounded-lg p-4 flex flex-col gap-2">
        <div
          className="sticky flex flex-row justify-between items-center pr-4 cursor-pointer select-none gap-4"
        >
          <div className="flex flex-row items-start gap-4">
            <img src={modalTemplateIcon} alt="modalTemplateIcon" />
            <div>
              <div className="modal-content-container mt-4">
                <h4>Generate the pre-lander page</h4>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4 flex-wrap">
            <Switch value={generatePreLander} onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
    )
}

export default GenerateLanderSelector;

import React from "react";
import DOMPurify from "dompurify";
import "./Sections.css";

const TestimonialSection = ({
  blockId,
  settings,
  selectedBlock,
  handleSelectBlock,
}) => {
  const sectionText = DOMPurify.sanitize(settings.text);

  return (
    <div
      style={{ background: settings.background_color, borderColor: settings.border_color, borderWidth: settings.border_width }}
      className={`testimonial-pre-lander mb-5 outline-border1`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <div style={{ fontSize: settings.text_size, color: settings.text_color }} dangerouslySetInnerHTML={{ __html: sectionText }}></div>
      <div style={{ color: settings.user_color }} className="testimonial-user-pre-lander">
        {settings.user}
      </div>
    </div>
  )
}

export default TestimonialSection;

export default function getAliExpressPrices(productData) {
  const {data} = productData
  let pricesObject = {}
  // console.log(data?.aliexpress_response,"productData?.aliexpress_response?.ae_item_sku_info_dtos==>");
  if (data?.aliexpress_response?.ae_item_sku_info_dtos) {
    const {aliexpress_response} = data
    const {ae_item_sku_info_dtos} = aliexpress_response
    const {ae_item_sku_info_d_t_o} = ae_item_sku_info_dtos
    let pricesObject = {}
    ae_item_sku_info_d_t_o.forEach(f => {
      pricesObject[f.sku_attr === '' ? f.sku_id : f.sku_attr] = {
        price: (f.offer_sale_price && f.offer_sale_price > 0) || f.offer_sale_price !== "" ? f.offer_sale_price :
          f.sku_price || 0,
        sale: f.offer_sale_price || 0,
        actualPrice: f.sku_price || 0
      }
    })
    return pricesObject
  } else {
    productData?.data.shopify_response?.variants.forEach((f) => {
      pricesObject[f.id] = {
        price: Number(f.discounted_price) || 0,
        sale: f.price ? Number(f.discounted_price) - Number(f.price) : 0,
        actualPrice: Number(f.price) || 0
      }
    })
    return pricesObject
  }
}

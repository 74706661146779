import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ErrorMsg from "../AuthComponents/ErrorMessage";
import { message, Card, Input } from 'antd'
import currentAuthenticatedUser, { getCurrentAuthenticatedSession } from "../../authUser";
import Layout from "../Layout";
import { useSearchParams } from "react-router-dom";

// import { Spin, message } from "antd";

import errorField from "../../assets/alert-circle.svg";
// import { SignIn } from "../../../redux/slices/auth-slice";
import { useNavigate } from "react-router-dom";

import LoaderWrapper from "../LoaderWrapper/LoaderWrapper";
import { URLS } from '../../URLS'
const ConnectStore = ({ storeName,isAliExpressAuthorize }) => {
  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(false);
  const [userId, setUserId] = useState(false);
  const [shopName, setShopName] = useState("");
  const [error, setError] = useState("");

  // for ali express
  const appKey = '506940';
  const callbackUrl = URLS.BACKEND_API + URLS.ALIEXP_CALLBACK_URL;

  const handleShopNameInput = async (event) => {
    setShopName(event.target.value)
    setError("")
  }

  const handleAliExpressConnect = async () => {
    const {userId} = await currentAuthenticatedUser();
    const newUrl = `https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${callbackUrl}&client_id=${appKey}&state=${userId}`;
    window.location.href = newUrl;
  }


  const handleShopifyConnect = async (event) => {
    if(shopName.length<=0){
      setError("Shop name is required")
    }else{
      setPageLoading(true);
      const {userId} = await currentAuthenticatedUser(); 
      setUserId(userId);       
      try {
        const token = localStorage.getItem("token");
        const accessToken= await getCurrentAuthenticatedSession();
        // api call
        const response = await fetch(
          `${URLS.BACKEND_API}/store/auth/shopify?shop=${encodeURIComponent(shopName)}&userId=${userId}`,
          {
            method: "GET",
            headers: userId ? {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`, // Replace with your auth token format
            } : {
              "Content-Type": "application/json",
            },
          }
        );
      const data = await response?.json();

    if (response.status === 200) {

      return (window.location.href = data.url);

      } else {
        setError(data.error)
        return message.error(`${data.error}`);
      }

      } catch (err) {
        setError(err)
        console.log("error in login:", err);

      }finally{
      setPageLoading(false);
      }
    }
  };



  return (
    <LoaderWrapper loading={pageLoading} loadingText='Connecting...'>

      {storeName == "shopify" ? (
        <div className="card mt-5"  style={{minHeight:"1em"}}>
          {/* <div className=" w-full pt-[150px] flex justify-center items-center"> */}
          {/* <div className="  w-[60%] h-[150px] shadow-lg flex flex-col   p-5 gap-2 rounded-lg bg-[#eceaea] "> */}
          <label className="text-[#474545] font-semibold">
            Enter Store Name
          </label>
          {/* <div className="flex gap-4"> */}
          <div className=" w-[75%] h-[60px] m-0">
            <div className="relative w-full">
              <Input
                type="text"
                id="shopName"
                name="shopName"
                placeholder="Enter store name"
                onChange={handleShopNameInput}
              />
            </div>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <div className="W-[150px] h-[50px]">
            <button
              type="submit"
              onClick={handleShopifyConnect}
              className=" continue-btn h-full flex justify-center items-center px-4"
            >
              <span>Connect Store</span>
            </button>
          </div>
        </div>


      ) : (
        <div className="card mt-5" style={{minHeight:"1rem"}}>
          <label className="text-[#474545] font-semibold">
            You are currently not connected with AliExpress.
          </label>
          <div className="w-[250px] h-[50px]  ">
            <button onClick={handleAliExpressConnect} className="continue-btn h-full flex justify-center items-center">
              <span>Connect with AliExpress</span>
            </button>
          </div>
        </div>
      )}
    </LoaderWrapper>

  );
};

export default ConnectStore;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreLandedTopBar from "./PreLandedTopBar/PreLandedTopBar";
import RightSectionPreLander from "./RightSection/RightSectionPreLander";
import TitleSection from "./Sections/TitleSection";
import TextImageSection from "./Sections/TextImageSection";
import ImageSection from "./Sections/ImageSection";
import TextSection from "./Sections/TextSection";
import ButtonSection from "./Sections/ButtonSection";
import RatingSectionPreLander from "./Sections/RatingSectionPreLander";
import NotifySection from "./Sections/NotifySection";
import TestimonialSection from "./Sections/TestimonialSection";
import RightBarPreLanderSection from "./Sections/RightBarPreLanderSection";
import BottomStickyBarSection from "./Sections/BottomStickyBarSection";
import NotificationSection from "./Sections/NotificationSection";
import { templatePreLanderData } from "./defaultTemplate";
import SavePreLanderModal from "./SavePreLanderModal";
import { callBackendAPI } from "../../helpers/apiCalls";
import { URLS } from "../../URLS";
import getUpdatedArraysAfterCanvaEditing from "../../Utilities/getUpdatedArraysAfterCanvaEditing";
import { updateVariantImageFromCanvaEdit } from "../../redux/slices/product-variants-slice";

export const reformatText = (text) => {
  return text
    .replace(/^<p>|<\/p>$/g, '')
    .split(/<br\s*\/?>/g)
    .map(sentence => sentence.trim() ? `<p>${sentence.trim()}</p>` : '')
    .join('');
}

const PreLanderEditView = () => {
  const { productData } = useSelector(store => store.publishProduct.publisPayload)
  const { productData: publishData , toUpdateImageDetails} = useSelector(store => store.productVariants)
  const { preLanderId, productHandler } = useSelector((store) => store.preLanderPageSlice);
  const { data } = useSelector((store) => store.preLanderPageSlice);
  const { shopifyShopDetails } = useSelector((store) => store.authUser);
  const [templateDefaultData, setTemplateDefaultData] = useState();
  const [pageData, setPageData] = useState()
  const [rightBarData, setRightBarDataData] = useState();
  const [bottomBlock, setBottomBlock] = useState();
  const [topNotifyBlock, setTopNotifyBlock] = useState();
  const [selectedEditBlock, setSelectedEditBlock] = useState(null);
  const [savePreLander, setSavePreLander] = useState(false);
  const [productImages, setProductImages] = useState(publishData.data.media_images);
  const [canvaAuthWindow, setCanvaAuthWindow] = useState(null);
  const [isEditCanvaLoading, setIsEditCanvaLoading] = useState(false);
  const [awaitingRefresh, setAwaitingRefresh] = useState(false);
  const [updatedUrl, setUpdatedUrl] = useState("");
  const [isCanvaConnected, setIsCanvaConnected] = useState(false);
  const [sectionsImages, setSectionsImages] = useState({});

  const dispatch = useDispatch();

  const [textOnImageDetails, setTextOnImageDetails] = useState({
    title: "60-Day Risk-Free Guarantee",
    details:
      "Not satisfied with your product? Return it to us, risk-free in 60 days. That’s how confident we are that you’ll like the product.",
    img: "https://miro.medium.com/v2/resize:fit:2400/0*hDAyhnOx767w5qma.jpg",
  });

  useEffect(() => {
    if (updatedUrl !== "") {
      // update every where
      const { updatedProductImages, updatedSectionsImages } =
        getUpdatedArraysAfterCanvaEditing({
          productImages,
          sectionsImages,
          imageUrl: toUpdateImageDetails?.imageUrl,
          updatedUrl,
        });

      // Set the state for both
      setProductImages(updatedProductImages);
      setSectionsImages(updatedSectionsImages);
      dispatch(
        updateVariantImageFromCanvaEdit({
          existingUrl: toUpdateImageDetails?.imageUrl,
          newUrl: updatedUrl,
        })
      );
    }
  }, [updatedUrl]);

  const handleSelectBlock = (blockId) => {
    setSelectedEditBlock(blockId);
  }

  const getPreLanderById = async () => {
    try {
      const response = await callBackendAPI(
        "prelander/get-template",
        "GET",
        {
          product_id: Number(publishData.product_id)
        },
      );
      if (response.status === "success") {
        // sorting block in main layouts according to block order
        const sortedMainLayoutBlocks = Object.fromEntries(
          response.template.prelander_template.sections.main_layout.block_order
            .filter(key => key in response.template.prelander_template.sections.main_layout.blocks)
            .map(key => [key, response.template.prelander_template.sections.main_layout.blocks[key]])
        )

        // adding removed blocks after sort
       Object.keys(response.template.prelander_template.sections.main_layout.blocks).forEach(key => {
          if (!(key in sortedMainLayoutBlocks)) {
            sortedMainLayoutBlocks[key] = response.template.prelander_template.sections.main_layout.blocks[key];
          }
        });

        setTemplateDefaultData({
          ...response.template.prelander_template,
          sections: {
            ...response.template.prelander_template.sections,
            main_layout: {
              ...response.template.prelander_template.sections.main_layout,
              blocks: sortedMainLayoutBlocks,
            }
          },
        });
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (data) {
      setTemplateDefaultData(templatePreLanderData({
        productTitle: productData?.product?.title || 'null',
        aiData: JSON.parse(JSON.stringify(data).replaceAll("\\n", '<br>')),
        publishData: publishData,
        shopifyShopDetails: shopifyShopDetails,
        productHandler: productHandler,
      }))
    }
  }, [data]);

  useEffect(() => {
    if (preLanderId) {
      getPreLanderById()
    }
  }, [preLanderId]);

  useEffect(() => {
    if (templateDefaultData) {
      setPageData(templateDefaultData.sections['main_layout'].blocks);
      setRightBarDataData(templateDefaultData.sections['main_layout'].blocks.rightbar);
      setBottomBlock(Object.entries(templateDefaultData.sections).find((section) => section[1].type === "sofia-stickybar"));
      setTopNotifyBlock(Object.entries(templateDefaultData.sections).find((section) => section[1].type === "sofia-notify"));
    }
  },[templateDefaultData])

  const handleChangePageData = (blockId, type, value, valueType) => {
    switch (type) {
      case "top-notify": {
        setTopNotifyBlock((prevState) => ({
          ...prevState,
          [1]: {
            ...topNotifyBlock[1],
            settings: {
              ...topNotifyBlock[1].settings,
              background_color: valueType === 'notifBGColor' ? value : topNotifyBlock[1].settings.background_color,
              border_color: valueType === 'notifBCColor' ? value : topNotifyBlock[1].settings.border_color,
              border_radius: valueType === 'notifBorderRadius' ? value : topNotifyBlock[1].settings.border_radius,
              border_width: valueType === 'notifBorderWidth' ? value : topNotifyBlock[1].settings.border_width,
              text: valueType === 'text' ? value : topNotifyBlock[1].settings.text,
              text_size: valueType === 'notifTextSize' ? value : topNotifyBlock[1].settings.text_size,
              text_color: valueType === 'notifTextColor' ? value : topNotifyBlock[1].settings.text_color,
              icon_color: valueType === 'notifIconColor' ? value : topNotifyBlock[1].settings.icon_color,
            },
          }
        }))
      }
      break;

      case "sofia-rightbar": {
        setRightBarDataData({
          ...rightBarData,
          settings: {
            ...pageData[blockId].settings,
            width: valueType === 'width' ? value : pageData[blockId].settings.width,
            default_image: valueType === 'image' ? value : pageData[blockId].settings.default_image,
            text: valueType === 'text' ? value : pageData[blockId].settings.text,
            text_size: valueType === 'textSize' ? value : pageData[blockId].settings.text_size,
            text_color: valueType === 'textColor' ? value : pageData[blockId].settings.text_color,
            button_text: valueType === 'button_text' ? value : pageData[blockId].settings.button_text,
            button_text_size: valueType === 'button_text_size' ? value : pageData[blockId].settings.button_text_size,
            button_text_color: valueType === 'button_text_color' ? value : pageData[blockId].settings.button_text_color,
            button_url: valueType === 'button_url' ? value : pageData[blockId].settings.button_url,
            review_title: valueType === 'review_title' ? value : pageData[blockId].settings.review_title,
            review_detail: valueType === 'review_detail' ? value : pageData[blockId].settings.review_detail,
            review_sub_title: valueType === 'review_sub_title' ? value : pageData[blockId].settings.review_sub_title,            
          },
          blocks: valueType === 'blocks' ? value : pageData[blockId].blocks,
        })
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              width: valueType === 'width' ? value : pageData[blockId].settings.width,
              default_image: valueType === 'image' ? value : pageData[blockId].settings.default_image,
              text: valueType === 'text' ? value : pageData[blockId].settings.text,
              text_size: valueType === 'textSize' ? value : pageData[blockId].settings.text_size,
              text_color: valueType === 'textColor' ? value : pageData[blockId].settings.text_color,
              button_text: valueType === 'button_text' ? value : pageData[blockId].settings.button_text,
              button_text_size: valueType === 'button_text_size' ? value : pageData[blockId].settings.button_text_size,
              button_text_color: valueType === 'button_text_color' ? value : pageData[blockId].settings.button_text_color,
              button_url: valueType === 'button_url' ? value : pageData[blockId].settings.button_url,
              review_title: valueType === 'review_title' ? value : pageData[blockId].settings.review_title,
              review_detail: valueType === 'review_detail' ? value : pageData[blockId].settings.review_detail,
              review_sub_title: valueType === 'review_sub_title' ? value : pageData[blockId].settings.review_sub_title,              
            },
            blocks: valueType === 'blocks' ? value : pageData[blockId].blocks,
          }
        }))
      }
        break;

      case "sofia-title": {
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              title: valueType === 'title' ? value : pageData[blockId].settings.title,
              text_size: valueType === 'textSize' ? value : pageData[blockId].settings.text_size,
              text_color: valueType === 'textColor' ? value : pageData[blockId].settings.text_color,
            },
          }
        }))
      }
      break;

      case "sofia-text": {
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              text: valueType === 'text' ? value : pageData[blockId].settings.text,
              text_size: valueType === 'fontSize' ? value : pageData[blockId].settings.text_size,
              text_color: valueType === 'textColor' ? value : pageData[blockId].settings.text_color,
            },
          }
        }))
      }
      break;

      case "sofia-button": {
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              text: valueType === 'text' ? value : pageData[blockId].settings.text,
              text_size: valueType === 'textSize' ? value : pageData[blockId].settings.text_size,
              text_color: valueType === 'textColor' ? value : pageData[blockId].settings.text_color,
              radius: valueType === 'BtnRadius' ? value : pageData[blockId].settings.radius,
              background_color: valueType === 'BGColor' ? value : pageData[blockId].settings.background_color,
              url: valueType === 'BtnUrl' ? value : pageData[blockId].settings.url,
            },
          }
        }))
      }
        break;

      case "sofia-stickybar": {
        setBottomBlock((prevState) => ({
          ...prevState,
          [1]: {
            ...bottomBlock[1],
            settings: {
              ...bottomBlock[1].settings,
              text: valueType === 'text' ? value : bottomBlock[1].settings.text,
              text_size: valueType === 'textSize' ? value : bottomBlock[1].settings.text_size,
              text_color: valueType === 'textColor' ? value : bottomBlock[1].settings.text_color,
              radius: valueType === 'BtnBorderRadius' ? value : bottomBlock[1].settings.radius,
              button_color: valueType === 'BtnColor' ? value : bottomBlock[1].settings.button_color,
              background_color: valueType === 'BGColor' ? value : bottomBlock[1].settings.background_color,
              button_url: valueType === 'BtnUrl' ? value : bottomBlock[1].settings.button_url,
            },
          }
        }))
      }
        break;

      case "sofia-rating": {
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              rating: valueType === 'ratingNumber' ? value : pageData[blockId].settings.rating,
              detail: valueType === 'ratingDetail' ? value : pageData[blockId].settings.detail,
            },
          }
        }))
      }
        break;

      case "sofia-notify": {
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              background_color: valueType === 'notifBGColor' ? value : pageData[blockId].settings.background_color,
              border_color: valueType === 'notifBCColor' ? value : pageData[blockId].settings.border_color,
              border_radius: valueType === 'notifBorderRadius' ? value : pageData[blockId].settings.border_radius,
              border_width: valueType === 'notifBorderWidth' ? value : pageData[blockId].settings.border_width,
              text: valueType === 'text' ? value : pageData[blockId].settings.text,
              text_size: valueType === 'notifTextSize' ? value : pageData[blockId].settings.text_size,
              text_color: valueType === 'notifTextColor' ? value : pageData[blockId].settings.text_color,
            },
          }
        }))
      }
        break;

      case "sofia-testimonial": {
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              text: valueType === 'text' ? value : pageData[blockId].settings.text,
              user: valueType === 'user' ? value : pageData[blockId].settings.user,
              user_color: valueType === 'userColor' ? value : pageData[blockId].settings.user_color,
              border_color: valueType === 'BCColor' ? value : pageData[blockId].settings.border_color,
              border_width: valueType === 'testBorderWidth' ? value : pageData[blockId].settings.border_width,
              text_color: valueType === 'textColor' ? value : pageData[blockId].settings.text_color,
              text_size: valueType === 'testTextSize' ? value : pageData[blockId].settings.text_size,
              background_color: valueType === 'BGColor' ? value : pageData[blockId].settings.background_color,
            },
          }
        }))
      }
        break;

      case "sofia-text-image": {
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              default_url: valueType === 'image' ? value : pageData[blockId].settings.default_url,
              title: valueType === 'title' ? value : pageData[blockId].settings.title,
              title_size: valueType === 'titleSize' ? value : pageData[blockId].settings.title_size,
              title_color: valueType === 'titleColor' ? value : pageData[blockId].settings.title_color,
              text: valueType === 'text' ? value : pageData[blockId].settings.text,
              text_size: valueType === 'textSize' ? value : pageData[blockId].settings.text_size,
              text_color: valueType === 'textColor' ? value : pageData[blockId].settings.text_color,
              show_image: valueType === 'showImage' ? value : pageData[blockId].settings.show_image,
            },
          }
        }))
      }
        break;

      case "sofia-image": {
        setPageData((prevState) => ({
          ...prevState,
          [blockId]: {
            ...pageData[blockId],
            settings: {
              ...pageData[blockId].settings,
              default_url: valueType === 'image' ? value : pageData[blockId].settings.default_url,
            },
          }
        }))
      }
        break;
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    callBackendAPI("canva", "GET", {}, null, abortController.signal).then(
      (response) => {
        if (!!response.connected) {
          setIsCanvaConnected(true);
        }
      }
    );

    const canvaReturnFunc = (event) => {
      console.log("canva event on return= >", event);

      if (
        event.origin === URLS.CANVA_BACKEND_API &&
        event.data.event === "canva-return"
      ) {
        if (
          event.data.data &&
          event.data.data.design_id &&
          event.data.data.correlation_state?.length > 0
        ) {
          console.log("canva event on return if condition= >");
          setIsEditCanvaLoading(true);
          callBackendAPI("canva/design", "GET", {
            designId: event.data.data.design_id,
            update: true,
          })
            .then((response) => {
              if (response.status === "success") {
                if (response.accessToken) {
                  localStorage.setItem(
                    "canva_access_token",
                    response.accessToken
                  );
                }

                console.log("Canva return success");
                console.log("canva/design", response, toUpdateImageDetails);
                setUpdatedUrl(response?.url);
                console.log("awaitingRefresh", awaitingRefresh);
                setAwaitingRefresh(false);
              }
            })
            .finally(() => {
              setIsEditCanvaLoading(false);
            });
        }
      }
    };

    window.addEventListener("message", canvaReturnFunc);

    return () => {
      abortController.abort();

      if (canvaAuthWindow) {
        canvaAuthWindow.close();
      }
      window.removeEventListener("message", canvaReturnFunc);
    };
  }, []);

  if (pageData && rightBarData && bottomBlock && topNotifyBlock) {
    return (
      <div className="flex flex-col h-[100vh]">
        <PreLandedTopBar
          pageData={pageData}
          rightBarData={rightBarData}
          bottomBlock={bottomBlock}
          topNotifyBlock={topNotifyBlock}
          templateDefaultData={templateDefaultData}
          setSavePreLander={setSavePreLander}
          savePreLander={savePreLander}
        />
        <div>
          <div className="flex flex-row p-2 mt-14">
            <div className="w-[73%] prelander-view-page">
              <NotificationSection
                data={topNotifyBlock}
                blockId={topNotifyBlock[0]}
                selectedBlock={selectedEditBlock}
                handleSelectBlock={handleSelectBlock}
              />
              <div className="flex flex-row pl-10 pr-2">
                <div style={{ paddingBottom: 100 }}>
                  {pageData && Object.entries(pageData).map((item) => {
                    switch (item[1].type) {
                      case "sofia-title": {
                        return (
                          <TitleSection
                            blockId={item[0]}
                            settings={item[1].settings}
                            selectedBlock={selectedEditBlock}
                            handleSelectBlock={handleSelectBlock}
                          />
                        )
                      }

                      case "sofia-text-image": {
                        return (
                          <TextImageSection
                            blockId={item[0]}
                            settings={item[1].settings}
                            selectedBlock={selectedEditBlock}
                            handleSelectBlock={handleSelectBlock}
                          />
                        )
                      }

                      case "sofia-image": {
                        return (
                          <ImageSection
                            blockId={item[0]}
                            imgUrl={item[1].settings.default_url}
                            selectedBlock={selectedEditBlock}
                            handleSelectBlock={handleSelectBlock}
                          />
                        )
                      }

                      case "sofia-text": {
                        return (
                          <TextSection
                            blockId={item[0]}
                            settings={item[1].settings}
                            selectedBlock={selectedEditBlock}
                            handleSelectBlock={handleSelectBlock}
                          />
                        )
                      }

                      case "sofia-notify": {
                        return (
                          <NotifySection
                            blockId={item[0]}
                            text={item[1].settings.text}
                            settings={item[1].settings}
                            selectedBlock={selectedEditBlock}
                            handleSelectBlock={handleSelectBlock}
                          />
                        )
                      }

                      case "sofia-testimonial": {
                        return (
                          <TestimonialSection
                            blockId={item[0]}
                            settings={item[1].settings}
                            selectedBlock={selectedEditBlock}
                            handleSelectBlock={handleSelectBlock}
                          />
                        )
                      }

                      case "sofia-button": {
                        return (
                          <ButtonSection
                            blockId={item[0]}
                            text={item[1].settings.text}
                            url={item[1].settings.url}
                            style={{ 
                              fontSize: item[1].settings.text_size, 
                              color: item[1].settings.text_color, 
                              borderRadius: item[1].settings.radius,
                              background: item[1].settings.background_color,
                            }}
                            selectedBlock={selectedEditBlock}
                            handleSelectBlock={handleSelectBlock}
                          />
                        )
                      }

                      case "sofia-rating": {
                        return (
                          <RatingSectionPreLander
                            blockId={item[0]}
                            rating={item[1].settings.rating}
                            details={item[1].settings.detail}
                            selectedBlock={selectedEditBlock}
                            handleSelectBlock={handleSelectBlock}
                          />
                        )
                      }

                      default: return;
                    }
                  })}
                </div>
                {rightBarData && (
                  <RightBarPreLanderSection
                    rightBarData={rightBarData}
                    handleChangePageData={handleChangePageData}
                    blockId={'rightbar'}
                    selectedBlock={selectedEditBlock}
                    handleSelectBlock={handleSelectBlock}
                  />
                )}
                {bottomBlock && (
                  <BottomStickyBarSection
                    data={bottomBlock[1].settings}
                    blockId={bottomBlock[0]}
                    selectedBlock={selectedEditBlock}
                    handleSelectBlock={handleSelectBlock}
                  />
                )}
              </div>
            </div>
            <RightSectionPreLander
              selectedEditBlock={selectedEditBlock}
              pageData={pageData}
              bottomBlock={bottomBlock}
              topNotifyBlock={topNotifyBlock}
              handleChangePageData={handleChangePageData}
              productImages={productImages}
              setProductImages={setProductImages}
              setSectionsImages={setSectionsImages}
              sectionsImages={sectionsImages}
              toUpdateImageDetails={toUpdateImageDetails}
              setTextOnImageDetails={setTextOnImageDetails}
              isCanvaConnected={isCanvaConnected}
              setIsCanvaConnected={setIsCanvaConnected}
              awaitingRefresh={awaitingRefresh}
              setAwaitingRefresh={setAwaitingRefresh}
              isEditCanvaLoading={isEditCanvaLoading}
              setIsEditCanvaLoading={setIsEditCanvaLoading}
            />
            <SavePreLanderModal
              OpenModal={savePreLander}
            />
          </div>
        </div>
      </div>
    )
  }

  return (<></>)
}

export default PreLanderEditView;

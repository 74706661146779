

import errConnectedIcon from "../../assets/not-connected.svg";


const NotConnectedShopifyCard = () => {
    return (
        <div className="card mt-5 " style={{ minHeight: "1rem" }}>

            <img
                className="w-[30px] h-[30px]"
                src={errConnectedIcon}
                alt="success"
            />
            <span className="text-[16px] font-semibold text-[#474747]">
                Store Not Connected!{" "}
            </span>

        </div>
    )
}
export default NotConnectedShopifyCard

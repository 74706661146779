import "./Sections.css";
import ButtonSection from "./ButtonSection";
import { Progress, Rate } from "antd";

const RightBarPreLanderSection = ({
  rightBarData,
  handleChangePageData,
  blockId,
  selectedBlock,
  handleSelectBlock,
}) => {
  
  const featuresBlock   = Object.entries(rightBarData.blocks).find((block) => block[1].type === "sofia-features");
  const percentBlocks   = Object.entries(rightBarData.blocks)
                                    .filter((block) => block[1].type.includes('sofia-star-percent'))
                                    .sort((a, b) => {
                                      const titleA = a[1].settings.star_percent_title;
                                      const titleB = b[1].settings.star_percent_title;
                                      return titleA.localeCompare(titleB); 
                                    }).reverse();
  const renderRate = (mark) => {
    let rate = [];
    for (let index = 1; index < 6; index++) {
      if (mark >= index)
        rate.push(<span class="bg-success text-white star-box">★</span>)
      else
        rate.push(<span class="bg-gray text-white star-box">★</span>)
    }
    return rate;
  }

  return (
    <div
      style={{ width: rightBarData.settings.width, paddingBottom: 200 }}
      className={`right-bar-section-pre-lander cursor-pointer overflow-y-auto mt-5 ${selectedBlock === blockId ? "outline-border-selected" : "outline-border"}`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <div style={{ color: rightBarData.settings.text_color, fontSize: rightBarData.settings.text_size }}>
        <p className="right-bar-section-header-pre-lander mb-5">{rightBarData.settings.text}</p>
      </div>
      {rightBarData.settings.default_image && <img className="mb-5" src={rightBarData.settings.default_image} alt={"temp-img"}/>}
      <ButtonSection
        blockId={''}
        text={rightBarData.settings.button_text}
        url={rightBarData.settings.button_url}
        style={{
          fontSize: rightBarData.settings.button_text_size,
          color: rightBarData.settings.button_text_color
        }}
        selectedBlock={''}
        handleSelectBlock={() => {}}
        disabled
      />
       <div className="right-bar-section-cr-reviews-pre-lander">
        <h2>{rightBarData.settings.review_title}</h2>
      </div>
      <div className="right-bar-section-reviews-pre-lander">
        <p className="font-bold text-end">{rightBarData.settings.review_detail}</p>
        <p className="font-bold text-gray-600 mt-3 mb-3 text-lg">{rightBarData.settings.review_sub_title}</p>
        <div>
          {percentBlocks.map((block) => {
              return (
                <div className="flex flex-row mb-2" style={{ color: '#009926' }}>
                  <p className="w-[22%] text-green-700 font-bold">{block[1].settings.star_percent_title}</p>
                  <Progress className="w-[80%] ml-2" strokeColor="#ffc107" percent={Number(block[1].settings.star_percent)} size={[170, 15]} />
                </div>
              )
          })}
        </div>
        <div>
          {featuresBlock && <h2 className="font-bold text-xl my-5">{featuresBlock[1].settings.title}</h2>}
          {featuresBlock && Object.entries(featuresBlock[1].blocks).map((block) => (
            <div className="flex flex-row justify-between" >
              <p>{block[1].settings.feature_title}</p>
              <div className="flex flex-row mb-2" >
                {renderRate(block[1].settings.star_mark)}
                <p style={{ width: 22.5, marginLeft: 1 }}>{block[1].settings.star_mark.toFixed(1)}</p>
              </div>
            </div>
          ))}
        </div>
      </div> 
    </div>
  )
}

export default RightBarPreLanderSection;

import { useState, useEffect } from "react";
import logo from "../../assets/sofia-logo-transparent.png";

export default function AspectRatioOverlay() {
  const [isPortrait, setIsPortrait] = useState(window.innerWidth / window.innerHeight < 1);

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerWidth / window.innerHeight < 1);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isPortrait && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-md flex flex-col gap-4 items-center justify-center text-white text-center z-50">
          <img src={logo} alt="Sofia Logo" />
          <p className="text-xl font-medium text-white">Turn your screen to landscape</p>
        </div>
      )}
    </>
  );
}

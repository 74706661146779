import { Tabs, ConfigProvider } from "antd";
import { useState } from "react";

const { TabPane } = Tabs;

const ProTemplateTabs = ({
  tabsContent,
  tabsSection,
  handleSectionEditChange,
  colorsPallet
}) => {
  const [activeKey, setActiveKey] = useState("1");


  const handleTabChange = (key) => {
    setActiveKey(key);
    console.log(key)
  };

  const getTabStyle = (key) => {
    if (activeKey == key && colorsPallet.active_tab_color) {
      return { color: `rgb(${colorsPallet.active_tab_color})`, border: 'red' };
    }
    return {};
  };


  return (
    <div
      onClick={() => handleSectionEditChange(["tabsSection"])}
      className={`w-full ${tabsSection ? "outline-border-selected" : "outline-border"
        }`}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: `rgb(${colorsPallet.active_tab_color})`
            },
          },
        }}
      >
        <Tabs activeKey={activeKey} onChange={handleTabChange}

        >
          {tabsContent.map((content, index) => {
            return (
              <TabPane

                // tab={`${content.heading}`} 
                tab={<span style={getTabStyle(index + 1)}>{content.heading}</span>} // Apply dynamic color only to active tab
                inkBarColor="#ffffff"

                key={index + 1}>
                {content.details}
              </TabPane>
            );
          })}
        </Tabs>
      </ConfigProvider>
    </div>
  );
};
export default ProTemplateTabs;

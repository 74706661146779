import React from "react";
import "./Sections.css";
import ButtonSection from "./ButtonSection";

const BottomStickyBarSection = ({
  data,
  blockId,
  selectedBlock,
  handleSelectBlock,
}) => {
  return (
    <div
      style={{ background: data.background_color }}
      className={`bottom-sticky-bar text-center ${selectedBlock === blockId ? "outline-border-selected" : "outline-border"}`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <ButtonSection
        blockId={''}
        text={data.text}
        url={''}
        style={{ fontSize: data.text_size, color: data.text_color, background: data.button_color, borderRadius: data.radius }}
        disabled
        className="!mb-0"
        classNameText="!w-1/2 h-[60px]"
        selectedBlock={selectedBlock}
        handleSelectBlock={handleSelectBlock}
      />
    </div>
  )
}

export default BottomStickyBarSection;

export const templatePreLanderData = ({
  productTitle = '',
  aiData,
  publishData,
  shopifyShopDetails,
  productHandler,
}) => {
  console.log('aiData : ', aiData);
  const defaultUrl = productHandler ?
    `https://${shopifyShopDetails.name}/products/${productHandler}` :
    `https://${shopifyShopDetails.name}`;
  
  let salesData = aiData.salesTexts;  
  
  const salesBlock = Object.fromEntries(
    salesData.map((item, index) => [
      `sofia_sales_${index}`,
      {
        type: "sofia-text-image",
        settings: {
          "margin-top": 10,
          "margin-bottom": 10,
          "padding": 10,
          "title": item.title || "Edit content!",
          "title_font": "assistant_n4",
          "title_size": 26,
          "title_color": "#000000",
          "title_weight": "bold",
          "show_image": index == 0,
          "default_url": `${publishData.data.media_images[4 + index] || publishData.data.media_images[0 + index]}`,
          "text": `<p>${item.description || "Edit content!"}</p>`,
          "text_font": "assistant_n4",
          "text_size": 16,
          "text_color": "#000000",
          "text_weight": "normal"
        }
      }
    ])
  );

  return {
    "sections": {
      "top_notify": {
        "type": "sofia-notify",
        "settings": {
          "margin_top": 10,
          "margin_bottom": 10,
          "padding": 20,
          "text": `${aiData?.notification || "Edit content!"}`,
          "text_font": "mono",
          "text_size": 17,
          "text_color": "#4d4646",
          "text_weight": "bold",
          "icon": "information",
          "icon_color": "#ef3434",
          "border_width": 2,
          "border_radius": 8,
          "border_color": "#df7304",
          "background_color": "#fefcdf"
        }
      },
      "main_layout": {
        "type": "sofia-layout",
        "blocks": {
          "sofia_title_introduction": {
            "type": "sofia-title",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 0,
              "padding": 10,
              "title": `${aiData.introduction.title || "Edit content!"}`,
              "text_font": "assistant_n4",
              "text_size": 35,
              "text_color": "#000000",
              "text_weight": "bold"
            }
          },
          "sofia_rating_top": {
            "type": "sofia-rating",
            "settings": {
              "margin_top": 0,
              "margin_bottom": 10,
              "padding": 10,
              "rating": 5,
              "detail": "3,791 Ratings"
            }
          },
          "sofia_image_1": {
            "type": "sofia-image",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "default_url": `${publishData.data.media_images[0]}`,
            }
          },
          "sofia_text_introduction": {
            "type": "sofia-text",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "text": `<p>${aiData.introduction.description || "Edit content!"}</p>`,
              "text_font": "assistant_n4",
              "text_size": 17,
              "text_color": "#000000",
              "text_weight": "normal"
            }
          },
          "sofia_title_claim": {
            "type": "sofia-title",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "title": `${aiData.claim.title || "Edit content!"}`,
              "text_font": "assistant_n4",
              "text_size": 29,
              "text_color": "#000000",
              "text_weight": "bold"
            }
          },
          "sofia_image_2": {
            "type": "sofia-image",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "default_url": `${publishData.data.media_images[1]}`
            }
          },
          "sofia_notify_claim": {
            "type": "sofia-notify",
            "settings": {
              "margin": 10,
              "padding": 10,
              "text": `<p>${aiData.claim.quote}</p>`,
              "text_font": "assistant_n4",
              "text_size": 20,
              "text_color": "#000000",
              "text_weight": "bold",
              "border_width": 2,
              "border_radius": 5,
              "border_color": "#195847",
              "background_color": "#ffffff"
            }
          },
          "sofia_text_claim": {
            "type": "sofia-text",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "text": `<p>${aiData.claim.description || "Edit content!"}</p>`,
              "text_font": "assistant_n4",
              "text_size": 16,
              "text_color": "#000000",
              "text_weight": "normal"
            }
          },
          "sofia_text_image_expert": {
            "type": "sofia-text-image",
            "settings": {
              "margin-top": 10,
              "margin-bottom": 10,
              "padding": 10,
              "title": `${aiData.expert.title || "Edit content!"}`,
              "title_font": "assistant_n4",
              "title_size": 26,
              "title_color": "#000000",
              "title_weight": "bold",
              "show_image": true,
              "default_url": `${publishData.data.media_images[2]}`,
              "text": `<p>${aiData.expert.description || "Edit content!"}</p>`,
              "text_font": "assistant_n4",
              "text_size": 16,
              "text_color": "#000000",
              "text_weight": "normal"
            }
          },
          "sofia_text_image_productIntroduction": {
            "type": "sofia-text-image",
            "settings": {
              "margin-top": 10,
              "margin-bottom": 10,
              "padding": 10,
              "title": `${aiData.productIntroduction.title || "Edit content!"}`,
              "title_font": "assistant_n4",
              "title_size": 26,
              "title_color": "#000000",
              "title_weight": "bold",
              "show_image": true,
              "default_url": `${publishData.data.media_images[3]}`,
              "text": `<p>${aiData.productIntroduction.description || "Edit content!"}</p>`,
              "text_font": "assistant_n4",
              "text_size": 16,
              "text_color": "#000000",
              "text_weight": "normal"
            }
          },
          "sofia_text_image_productClaim": {
            "type": "sofia-text-image",
            "settings": {
              "margin-top": 10,
              "margin-bottom": 10,
              "padding": 10,
              "title": `${aiData.productClaim.title || "Edit content!"}`,
              "title_font": "assistant_n4",
              "title_size": 26,
              "title_color": "#000000",
              "title_weight": "bold",
              "show_image": true,
              "default_url": `${publishData.data.media_images[3]}`,
              "text": `<p>${aiData.productClaim.description || "Edit content!"}</p>`,
              "text_font": "assistant_n4",
              "text_size": 16,
              "text_color": "#000000",
              "text_weight": "normal"
            }
          },
          "sofia_text_image_results": {
            "type": "sofia-text-image",
            "settings": {
              "margin-top": 10,
              "margin-bottom": 10,
              "padding": 10,
              "title": `${aiData.results.title || "Edit content!"}`,
              "title_font": "assistant_n4",
              "title_size": 26,
              "title_color": "#000000",
              "title_weight": "bold",
              "show_image": true,
              "text": `<p>${aiData.results.description || "Edit content!"}</p>`,
              "default_url": `${publishData.data.media_images[3]}`,
              "text_font": "assistant_n4",
              "text_size": 16,
              "text_color": "#000000",
              "text_weight": "normal"
            }
          },
          "sofia_image_3": {
            "type": "sofia-image",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "default_url": `${publishData.data.media_images[1]}`,
            }
          },
          "sofia_testimonial_customerReview": {
            "type": "sofia-testimonial",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 30,
              "text": `<p>${aiData.customerReview.text || "Edit content!"}</p>`,
              "user": `${aiData.customerReview.name || "Edit content!"} ${aiData.customerReview.age || "Edit content!"}`,
              "user_color": "#000000",
              "text_font": "assistant_n4",
              "font_style": "italic",
              "text_size": 17,
              "text_color": "#9c9696",
              "text_weight": "bold",
              "border_width": 5,
              "border_color": "#1e9941",
              "background_color": "#eeeeee"
            }
          },
          ...salesBlock,
          "sofia_button_discount": {
            "type": "sofia-button",
            "settings": {
              "margin": 5,
              "padding": 5,
              "text": "Apply Discount & Check Availability",
              "url": `${defaultUrl}`,
              "font": "assistant_n4",
              "text_weight": "bold",
              "text_size": 28,
              "radius": 8,
              "text_color": "#ffffff",
              "background_color": "#df7304"
            }
          },
          "sofia_text_guarantee": {
            "type": "sofia-text",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "text": `<p>${aiData.guarantee || "Edit content!"}</p>`,
              "text_font": "assistant_n4",
              "text_size": 16,
              "text_color": "#000000",
              "text_weight": "normal"
            }
          },
          "sofia_image_4": {
            "type": "sofia-image",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "default_url": `${publishData.data.media_images[2]}`,
            }
          },
          "sofia_title_urgency": {
            "type": "sofia-title",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "title": `${aiData.urgency.title || "Edit content!"}`,
              "text_font": "assistant_n4",
              "text_size": 26,
              "text_color": "#000000",
              "text_weight": "bold"
            }
          },
          "sofia_text_urgency": {
            "type": "sofia-text",
            "settings": {
              "margin_top": 10,
              "margin_bottom": 10,
              "padding": 10,
              "text": `<p>${aiData.urgency.description || "Edit content!"}</p>`,
              "text_font": "assistant_n4",
              "text_size": 16,
              "text_color": "#000000",
              "text_weight": "normal"
            }
          },
          "sofia_button_discount2": {
            "type": "sofia-button",
            "settings": {
              "margin": 5,
              "padding": 5,
              "text": "Apply Discount & Check Availability",
              "url": `${defaultUrl}`,
              "font": "assistant_n4",
              "text_weight": "bold",
              "text_size": 28,
              "radius": 8,
              "text_color": "#ffffff",
              "background_color": "#df7304"
            }
          },
          "rightbar": {
            "type": "sofia-rightbar",
            "static": true,
            "settings": {
              "width": 280,
              "padding": 15,
              "text": `${aiData.callToAction || 'Edit Content'}`,
              "text_font": "assistant_n4",
              "text_size": 25,
              "text_color": "#195847",
              "text_weight": "bold",
              "button_text": `Get Disount now!`,
              "button_font": "assistant_n4",
              "button_text_weight": "bold",
              "background_color": "#f3f3f3",
              "button_text_size": 25,
              "button_radius": 5,
              "button_text_color": "#ffffff",
              "button_color": "#df7304",
              "button_url": `${defaultUrl}`,
              "review_title": "Customer Reviews",
              "review_detail": "4.8 out of 5",
              "review_sub_title": "3,791 customer ratings"
            },
            "blocks": {
              "sofia_star_percent_5": {
                "type": "sofia-star-percent",
                "settings": {
                  "star_percent_title": "5 Star",
                  "star_percent": 63
                }
              },
              "sofia_star_percent_4": {
                "type": "sofia-star-percent",
                "settings": {
                  "star_percent_title": "4 Star",
                  "star_percent": 45
                }
              },
              "sofia_star_percent_3": {
                "type": "sofia-star-percent",
                "settings": {
                  "star_percent_title": "3 Star",
                  "star_percent": 30
                }
              },
              "sofia_star_percent_2": {
                "type": "sofia-star-percent",
                "settings": {
                  "star_percent_title": "2 Star",
                  "star_percent": 20
                }
              },
              "sofia_star_percent_1": {
                "type": "sofia-star-percent",
                "settings": {
                  "star_percent_title": "1 Star",
                  "star_percent": 65
                }
              },
              "sofia_features_BwBnAH": {
                "type": "sofia-features",
                "settings": {
                  "margin-top": 10,
                  "title": "Feature By"
                },
                "blocks": {
                  "sofia_feature_star_aXMByU": {
                    "type": "sofia-feature-star",
                    "settings": {
                      "feature_title": "Price",
                      "star_mark": 5
                    }
                  },
                  "sofia_feature_star_R3bHpV": {
                    "type": "sofia-feature-star",
                    "settings": {
                      "feature_title": "Effective",
                      "star_mark": 4
                    }
                  },
                  "sofia_feature_star_bYzUYy": {
                    "type": "sofia-feature-star",
                    "settings": {
                      "feature_title": "Comfort",
                      "star_mark": 3
                    }
                  },
                  "sofia_feature_star_xHX6b4": {
                    "type": "sofia-feature-star",
                    "settings": {
                      "feature_title": "Qualtity",
                      "star_mark": 4
                    }
                  }
                },
                "block_order": [
                  "sofia_feature_star_aXMByU",
                  "sofia_feature_star_R3bHpV",
                  "sofia_feature_star_bYzUYy",
                  "sofia_feature_star_xHX6b4"
                ]
              }
            },
            "block_order": [
              "sofia_star_percent_5",
              "sofia_star_percent_4",
              "sofia_star_percent_3",
              "sofia_star_percent_2",
              "sofia_star_percent_1",
              "sofia_features_BwBnAH"
            ]
          }
        },
        "block_order": [
          "sofia_title_introduction",
          "sofia_rating_top",
          "sofia_image_1",
          "sofia_text_introduction",          
          "sofia_title_claim",
          "sofia_image_2",
          "sofia_notify_claim",          
          "sofia_text_claim",
          "sofia_text_image_expert",
          "sofia_text_image_productIntroduction",
          "sofia_text_image_productClaim",
          "sofia_text_image_results",
          "sofia_image_3",
          "sofia_testimonial_customerReview",
          ...Object.keys(salesBlock),
          "sofia_button_discount",
          "sofia_text_guarantee",
          "sofia_image_4",          
          "sofia_title_urgency",
          "sofia_text_urgency",
          "sofia_button_discount2"
        ],
        "settings": {
          "margin_top": 10,
          "margin_bottom": 10,
          "padding": 10
        }
      },
      "sticky_bar": {
        "type": "sofia-stickybar",
        "settings": {
          "position": "bottom",
          "padding": 10,
          "text": `Get ${productTitle} now!`,
          "button_url": `${defaultUrl}`,
          "text_font": "assistant_n4",
          "text_size": 25,
          "text_color": "#ffffff",
          "text_weight": "bold",
          "button_color": "#df7304",
          "radius": 10,
          "background_color": "#cfd4e9"
        }
      }
    },
    "order": [
      "top_notify",
      "main_layout",
      "sticky_bar"
    ]
  }
}

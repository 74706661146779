import React, { useState } from "react";
import open from "../../assets/plus-collapse.svg";
import close from "../../assets/minus-collapse.svg";
const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    cursor: "pointer",
    backgroundColor: "#f5f5f5",
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  content: {
    backgroundColor: "#f5f5f5",

    padding: "10px",
  },
};
const ProTemplateCollapseContainer = ({
  collapseHeadingSection,
  collapseContainerSection,
  handleSectionEditChange,
  hanldeCollapseContentClick,
  FAQData,
  setFaqData,
  colorsPalette
}) => {
  const [openIndex, setOpenIndex] = useState(null); // Track which collapsible is open

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Close if already open, open if not
  };
  console.log('FAQData: ', FAQData);

  return (
    <>
    <div className="pro-temp-collapse-container">
      <div
        class="flex w-full !justify-center text-center"
      >
        <h2
          onClick={() => handleSectionEditChange(["collapseHeadingSection"])}
          className={`pro-tmp-heading ${collapseHeadingSection ? "outline-border-selected" : "outline-border"
            }`}
        >
          {FAQData?.heading}
        </h2>
      </div>

      {FAQData?.data?.map((item, index) => (
        <div
        style={
          colorsPalette.active_tab_color
            ? {
              border: `2px solid rgb(${colorsPalette.active_tab_color})`,
              }
            : {}
        }
          className={`mb-1 ${collapseContainerSection
              ? "outline-border-selected"
              : "outline-border"
            }`}
          onClick={() =>
            hanldeCollapseContentClick({
              keys: ["collapseContainerSection"],
              index,
            })
          }
        >
          <Collapsible
            title={item.title}
            isOpen={openIndex === index}
            onToggle={() => {handleToggle(index)}}
            iconOpen={close}
            iconClose={open}
          >
            <p className="text-[#0008]">{item.details}</p>
          </Collapsible>
        </div>
      ))}
      <div
        class="flex w-full !justify-center text-center pt-4"
      >
        <h4
          onClick={() => {
              setFaqData((prev) => ({
                  ...prev,
                  heading: prev.heading,
                  data: [
                    ...prev.data,
                    {
                      title: "",
                      details: "",
                    },
                  ],
                }))
              setOpenIndex(FAQData.data.length)
              hanldeCollapseContentClick({
                keys: ["collapseContainerSection"],
                index: FAQData.data.length,
              })
            }
          }
          class="text-lg font-bold border-solid border-2 border-black cursor-pointer p-2 rounded-md"
        >
          + Add FAQs
        </h4>
      </div>
    </div>
    </>
  );
};

export default ProTemplateCollapseContainer;

const Collapsible = ({
  title,
  isOpen,
  onToggle,
  iconOpen,
  iconClose,
  children,
}) => (
  <div style={styles.container}>
    {/* Header with title and toggle icon */}
    <div style={styles.header} onClick={onToggle}>
      <span className="text-[#000]">{title}</span>
      <img
        src={isOpen ? iconOpen : iconClose}
        alt="Toggle Icon"
        style={styles.icon}
      />
    </div>

    {/* Collapsible content */}
    {isOpen && <div style={styles.content}>{children}</div>}
  </div>
);

import React from "react";
import "./Sections.css";
import infoIcon from "../../../assets/info-icon.svg";
import DOMPurify from "dompurify";

const NotificationSection = ({
  data,
  selectedBlock,
  handleSelectBlock,
  blockId
}) => {
  const setting     = data[1].settings;
  return (
  <div
    className={`notification-block flex flex-row outline-border1`}
    style={{ 
      backgroundColor: setting.background_color,       
      borderRadius: `${setting.border_radius}px`,
      fontSize: `${setting.text_size}px`,
      border: `${setting.border_width}px solid ${setting.border_color}`,  
    }}
    onClick={() => handleSelectBlock(blockId)}
  >
    <div className="flex flex-row mr-3">
      <img src={infoIcon} alt="info" />
      <p className="text-red-500 ml-3 mt-[1px]">UPDATE:</p>
    </div>
    <div 
      className="text-black"
      style={{ color: setting.text_color }}  
      dangerouslySetInnerHTML={{ __html: setting.text }}></div>
  </div>
  )
}

export default NotificationSection;

import React, { useState } from "react";
import { message, Modal } from "antd";
import { useNavigate } from "react-router";
import waiting from '../../../assets/waiting.png';
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { setPreLanderData } from "../../../redux/slices/prelander-page-slice";
import CustomLoader from "../../CustomLoader";

const GeneratePreLandingConfirmModal = ({ openModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productData } = useSelector(store => store.productVariants)
  const { tone, language } = useSelector((store) => store.publishProduct);
  const [loading, setLoading] = useState(false);

  const createContext = (productTemplate) => {
    let result = [];
    const { productName } = productTemplate;

    const traverse = (value) => {
      if (value === null) return;
      if (typeof value === 'string') {
        result.push(value);
      } else if (Array.isArray(value)) {
        value.forEach(traverse);
      } else if (typeof value === 'object') {
        Object.values(value).forEach(traverse);
      }
    }

    traverse(productTemplate);
    result.join('\n').replace(/https?:\/\/[^\s]+/g, '');
    return `Title:${productName}\n${result}`
  }

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const response = await callBackendAPI(
        "prelander/generate-template",
        "POST",
        {},
        {
          payload: {
            context: createContext(productData.data.productTemplate),
            type: "health_prelander",
            tone: tone,
            language: language,
          }
        }
      );
      setLoading(false);
      dispatch(setPreLanderData(response.aiResponse));
      navigate('/pre-lander');
    } catch (e) {
      setLoading(false);
      console.log(e)
      return message.error('Something went wrong, please try again' )
    }
  }

  return (
    <div>
      <Modal
        closable={false}
        width={500}
        open={openModal}
        footer={null}
      >
        <div className={loading ? "fake-loading-bar" : ""}></div>
        <div className="delete-prduct-content-container px-5 !text-center !flex !flex-col !justify-center !items-center">
          <h4 style={{ alignSelf:'center', textAlign:'center' }}>
            Continue with pre-lander page generation?
          </h4>
          <img src={waiting} className="delete-prduct-content-container-img h-[80px] !mb-0" alt={'wait_icon'} />
          <div className="flex flex-row mt-10 justify-between w-full">
            <button
              type="button"
              className={`pre-landing-button bg-purple-500 ${loading && 'cursor-not-allowed'}`}
              disabled={loading}
              onClick={() => handleGenerate()}
            >
              {loading && <CustomLoader color='#FFFFFF' width='20px' />}
              {!loading && 'Generate'}
            </button>
            <button
              type="button"
              className={`pre-landing-button bg-gray-400 !border-none ${loading && 'cursor-not-allowed'}`}
              disabled={loading}
              onClick={() => navigate('/')}
            >
              Go to My Products
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GeneratePreLandingConfirmModal;

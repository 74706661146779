import React, { useCallback, useRef } from "react";
import ReactQuill from "react-quill";
import { processContent } from "../../../Utilities/ProcessContent";
import { Col, InputNumber, Row, Slider, Switch, ColorPicker, Input, Collapse } from "antd";
import "react-quill/dist/quill.snow.css";
import './RightSectionPreLander.css';
import SectionEditImageContainer from "../../ProductEditView/RightSection/SectionEditImageContainer";

const InputBlock = ({
  label,
  value,
  handleChange,
  type,
  blockId,
  className = '',
  dataType = '',
}) => {
  return (
    <div className={`input-container ${className}`}>
      <label className="input-label">{label}</label>
      <input
        className="form-input"
        type="text"
        value={value}
        onChange={(e) => handleChange(blockId, type, e.target.value, dataType)}
      />
    </div>
  )
}

const formats = ["font", "size", "bold", "italic", "underline", "strike"];

const modules = {
  toolbar: {
    container: [
      ["bold", "italic", "underline", "strike"],
    ],
  },
};

const RightSectionPreLander = ({
  selectedEditBlock,
  pageData,
  handleChangePageData,
  bottomBlock,
  topNotifyBlock,
  productImages,
  setProductImages,
  toUpdateImageDetails,
  setTextOnImageDetails,
  isCanvaConnected,
  setIsCanvaConnected,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  sectionsImages,
  setSectionsImages,
}) => {
  const foundBlock = pageData[selectedEditBlock];
  const foundBottomBlock = bottomBlock[0] === selectedEditBlock ? bottomBlock[1] : undefined;
  const foundTopNotifyBlock = topNotifyBlock[0] === selectedEditBlock ? topNotifyBlock[1] : undefined;

  const quillRef = useRef(null);
  const reactQuillRef = useCallback((node) => {
    if (node !== null) {
      quillRef.current = node.getEditor();
    }
  }, []);

  const handleEditorChange = useCallback(
    (content, delta, source, blockId, type) => {
      let hasSizeAttribute = false;

      delta.ops.forEach((op) => {
        if (op.attributes && op.attributes.size) {
          const finalContent = processContent(content);

          if (source === "user" && finalContent) {
            handleChangePageData(blockId, type, finalContent || content, 'text')
          }
        }
      });

      if (!hasSizeAttribute && source === "user") {
        handleChangePageData(blockId, type, content, 'text')
      }
    },
    [pageData]
  );

  const handleRightBarBlocksChange = (blocks, value, blockKey, valueType) => {
    const newBlocks = JSON.parse(JSON.stringify(blocks));
    Object.entries(newBlocks)
          .forEach(([key, block]) => {
            if (blockKey == key)
              block.settings[valueType] = value;
            if (block.blocks)
                Object.entries(block.blocks)
                        .forEach(([k, b]) => {
                            if (blockKey == k)
                                  b.settings[valueType] = value;
                        });
          });
    // console.log(blocks, newBlocks, blockKey);
    handleChangePageData('rightbar', 'sofia-rightbar', newBlocks, 'blocks');
      
  };

  if (!foundBlock?.type && !foundBottomBlock?.type && !foundTopNotifyBlock?.type) {
    return (
      <div className="w-[27%] sticky !h-[100vh] top-[4rem] bg-white"></div>
    )
  }

  if (foundBottomBlock?.type) {
    return (
        <div className="w-[27%] bg-white sticky !h-[100vh] top-[4rem]" style={{ paddingBottom: 50 }} >
          <InputBlock
            label="Button text"
            value={foundBottomBlock.settings.text}
            handleChange={handleChangePageData}
            type={"sofia-stickybar"}
            blockId={selectedEditBlock}
            dataType={'text'}
          />
          <p className="ml-5 mt-5 text-lg text-black">Button Text size</p>
          <Row className="ml-5">
            <Col span={12}>
              <Slider
                min={1}
                max={30}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-stickybar', value, 'textSize')}
                value={Number(foundBottomBlock.settings.text_size)}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={1}
                max={30}
                style={{ margin: '0 16px' }}
                value={Number(foundBottomBlock.settings.text_size)}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-stickybar', value, 'textSize')}
              />
            </Col>
          </Row>
          <p className="ml-5 mt-5 text-lg text-black">Button text Color</p>
          <ColorPicker
            value={foundBottomBlock.settings.text_color}
            onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-stickybar', value, 'textColor')}
            showText 
            style={{ margin: '3px 20px' }}
          />
          <p className="ml-5 mt-5 text-lg text-black">Button Color</p>
          <ColorPicker
            value={foundBottomBlock.settings.button_color}
            onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-stickybar', value, 'BtnColor')}
            showText 
            style={{ margin: '3px 20px' }}
          />
          <p className="ml-5 mt-5 text-lg text-black">Button border radius</p>
          <Row className="ml-5">
            <Col span={12}>
              <Slider
                min={1}
                max={30}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-stickybar', value, 'BtnBorderRadius')}
                value={Number(foundBottomBlock.settings.radius)}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={1}
                max={30}
                style={{ margin: '0 16px' }}
                value={Number(foundBottomBlock.settings.radius)}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-stickybar', value, 'BtnBorderRadius')}
              />
            </Col>
          </Row>
          <p className="ml-5 mt-5 text-lg text-black">Background Color</p>
          <ColorPicker
            value={foundBottomBlock.settings.background_color}
            onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-stickybar', value, 'BGColor')}
            showText 
            style={{ margin: '3px 20px' }}
          />
          <InputBlock
            label="Button url"
            value={foundBottomBlock.settings.button_url}
            handleChange={handleChangePageData}
            type={"sofia-stickybar"}
            blockId={selectedEditBlock}
            dataType={'BtnUrl'}
          />
        </div>
    )
  }

  if (foundTopNotifyBlock?.type) {
    return (
      <>
        <div className="w-[27%] bg-white sticky h-[100vh] overflow-y-auto top-[4rem]">
          <p className="ml-5 mt-3 text-lg text-black">Text</p>
          <ReactQuill
            ref={reactQuillRef}
            className="text-editor-input-pre-lander mt-3 ml-5"
            value={foundTopNotifyBlock.settings.text}
            onChange={(content, delta, source) =>
              handleEditorChange(content, delta, source, selectedEditBlock, 'top-notify')
            }
            placeholder="Write something..."
            modules={modules}
            formats={formats}
          />
          {/* Text size */}
          <p className="ml-5 mt-3 text-lg text-black">Notification text size</p>
          <Row className="ml-5">
            <Col span={12}>
              <Slider
                min={1}
                max={30}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifTextSize')}
                value={Number(foundTopNotifyBlock.settings.text_size)}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={1}
                max={30}
                style={{ margin: '0 16px' }}
                value={Number(foundTopNotifyBlock.settings.text_size)}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifTextSize')}
              />
            </Col>
          </Row>
          <p className="ml-5 mt-5 text-lg text-black">Notification text Color</p>
          <ColorPicker
            value={foundTopNotifyBlock.settings.text_color}
            onChange={(color, value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifTextColor')}
            showText 
            style={{ margin: '3px 20px' }}
          />
          {/* Border width */}
          <p className="ml-5 mt-3 text-lg text-black">Notification border width</p>
          <Row className="ml-5">
            <Col span={12}>
              <Slider
                min={1}
                max={30}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifBorderWidth')}
                value={Number(foundTopNotifyBlock.settings.border_width)}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={1}
                max={30}
                style={{ margin: '0 16px' }}
                value={Number(foundTopNotifyBlock.settings.border_width)}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifBorderWidth')}
              />
            </Col>
          </Row>
          {/* Border radius */}
          <p className="ml-5 mt-3 text-lg text-black">Notification border radius</p>
          <Row className="ml-5">
            <Col span={12}>
              <Slider
                min={1}
                max={30}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifBorderRadius')}
                value={Number(foundTopNotifyBlock.settings.border_radius)}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={1}
                max={30}
                style={{ margin: '0 16px' }}
                value={Number(foundTopNotifyBlock.settings.border_radius)}
                onChange={(value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifBorderRadius')}
              />
            </Col>
          </Row>
          <p className="ml-5 mt-5 text-lg text-black">Notification border color</p>
          <ColorPicker
            value={foundTopNotifyBlock.settings.border_color}
            onChange={(color, value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifBCColor')}
            showText 
            style={{ margin: '3px 20px' }}
          />
          <p className="ml-5 mt-5 text-lg text-black">Notification background color</p>
          <ColorPicker
            value={foundTopNotifyBlock.settings.background_color}
            onChange={(color, value) => handleChangePageData(selectedEditBlock, 'top-notify', value, 'notifBGColor')}
            showText 
            style={{ margin: '3px 20px' }}
          />
          {/* <InputBlock
            label={'Notification icon color'}
            className="!mt-5"
            value={foundTopNotifyBlock.settings.icon_color}
            handleChange={handleChangePageData}
            type={"top-notify"}
            blockId={selectedEditBlock}
            dataType={'notifIconColor'}
          /> */}
        </div>
      </>
    )
  }

  switch (foundBlock?.type) {
    case "sofia-rightbar": {
      const featuresBlock   = Object.entries(foundBlock.blocks).find((block) => block[1].type === "sofia-features");
      const percentBlocks   = Object.entries(foundBlock.blocks)
                                    .filter((block) => block[1].type.includes('sofia-star-percent'))
                                    .sort((a, b) => {
                                      const titleA = a[1].settings.star_percent_title;
                                      const titleB = b[1].settings.star_percent_title;
                                      return titleA.localeCompare(titleB); 
                                    }).reverse();
      
      const items = [
        {
          key: '1',
          label: <label class="input-label">Star Percents</label>,
          children: percentBlocks.map((block) => {
                        return (
                          <Row className="mb-3">
                            <Col span={8}>
                              <label className="input-label">{block[1].settings.star_percent_title}</label>
                            </Col>
                            <Col span={16}>
                              <InputNumber
                                min={0}
                                max={100}
                                style={{ marginLeft: '20%', width: '80%' }}
                                value={Number(block[1].settings.star_percent)}
                                onChange={(value) => handleRightBarBlocksChange(foundBlock.blocks, value, block[0], 'star_percent')}
                              />
                            </Col>
                          </Row>
                        )
                    }),
        },
        {
          key: '2',
          label: <label class="input-label">Star Features</label>,
          children: Object.entries(featuresBlock[1].blocks).map((block) => {
                      return (
                        <Row className="mb-3">
                          <Col span={12}>
                            <Input
                              value={block[1].settings.feature_title}
                              onChange={(e) => handleRightBarBlocksChange(foundBlock.blocks, e.target.value, block[0], 'feature_title')}
                            />
                          </Col>
                          <Col span={12}>
                            <InputNumber
                              min={0}
                              max={5}
                              style={{ marginLeft: '20%', width: '80%' }}
                              value={Number(block[1].settings.star_mark)}
                              onChange={(value) => handleRightBarBlocksChange(foundBlock.blocks, value, block[0], 'star_mark')}
                            />
                          </Col>
                        </Row>
                      )
                    })
        }
      ];
      return (
        <>
          <div className="w-[27%] bg-white sticky overflow-y-auto !h-[100vh] top-[4rem]" style={{ paddingBottom: 200 }}>
            {/* Text */}
            <InputBlock
              label="Text"
              value={foundBlock.settings.text}
              handleChange={handleChangePageData}
              type={"sofia-rightbar"}
              blockId={selectedEditBlock}
              dataType={'text'}
            />
            <p className="ml-5 mt-5 text-lg text-black">Width</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={200}
                  max={500}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-rightbar', value, 'width')}
                  value={Number(foundBlock.settings.width)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={200}
                  max={500}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.width)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-rightbar', value, 'width')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Text size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-rightbar', value, 'textSize')}
                  value={Number(foundBlock.settings.text_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.text_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-rightbar', value, 'textSize')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Text Color</p>
            <ColorPicker
              value={foundBlock.settings.text_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-rightbar', value, 'textColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <div className="w-[90%] mt-5 ml-4">
              <p className="mb-3 text-lg text-black">Image</p>
              <SectionEditImageContainer
                  productImages={productImages}
                  setProductImages={setProductImages}
                  toUpdateImageDetails={toUpdateImageDetails}
                  imageUrl={foundBlock.settings.default_image == undefined || foundBlock.settings.default_image == 'undefined' ? 'https://dummyimage.com/300x300' : foundBlock.settings.default_image }
                  fromPrelander={true}
                  dataType={'sofia-rightbar'}
                  handleChangePageData={handleChangePageData}
                  selectedEditBlock={selectedEditBlock}
                  sectionsImages={sectionsImages}
                  setSectionsImages={setSectionsImages}
                  setTextOnImageDetails={setTextOnImageDetails}
                  isCanvaConnected={isCanvaConnected}
                  awaitingRefresh={awaitingRefresh}
                  setAwaitingRefresh={setAwaitingRefresh}
                  isEditCanvaLoading={isEditCanvaLoading}
                  setIsEditCanvaLoading={setIsEditCanvaLoading}
                />
            </div>
            {/* Button */}
            <InputBlock
              label="Text"
              value={foundBlock.settings.button_text}
              handleChange={handleChangePageData}
              type={"sofia-rightbar"}
              blockId={selectedEditBlock}
              dataType={'button_text'}
            />
            <p className="ml-5 mt-5 text-lg text-black">Button text size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-rightbar', value, 'button_text_size')}
                  value={Number(foundBlock.settings.button_text_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.button_text_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-rightbar', value, 'button_text_size')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Button Text Color</p>
            <ColorPicker
              value={foundBlock.settings.button_text_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-rightbar', value, 'button_text_color')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <InputBlock
              label="Button url"
              value={foundBlock.settings.button_url}
              handleChange={handleChangePageData}
              type={"sofia-rightbar"}
              blockId={selectedEditBlock}
              dataType={'button_url'}
            />
            <InputBlock
              label="Review Title"
              value={foundBlock.settings.review_title}
              handleChange={handleChangePageData}
              type={"sofia-rightbar"}
              blockId={selectedEditBlock}
              dataType={'review_title'}
            />
            <InputBlock
              label="Review Detail"
              value={foundBlock.settings.review_detail}
              handleChange={handleChangePageData}
              type={"sofia-rightbar"}
              blockId={selectedEditBlock}
              dataType={'review_detail'}
            />
            <InputBlock
              label="Review Sub Title"
              value={foundBlock.settings.review_sub_title}
              handleChange={handleChangePageData}
              type={"sofia-rightbar"}
              blockId={selectedEditBlock}
              dataType={'review_sub_title'}
            />
            <div class="ml-5 mt-5">
              <label className="input-label">Feature Title</label>
              <input
                className="form-input mt-1"
                type="text"
                value={featuresBlock[1].settings.title}
                onChange={(e) => handleRightBarBlocksChange(foundBlock.blocks, e.target.value, featuresBlock[0], 'title' )}
              />            
            </div>
            <Collapse items={items} style={{ margin: 16 }} />
          </div>
        </>
      );
    }

    case "sofia-title": {
      return (
        <>
          <div className="w-[27%] bg-white sticky !h-[100vh] top-[4rem]">
            <InputBlock
              label="Title"
              value={foundBlock.settings.title}
              handleChange={handleChangePageData}
              type={"sofia-title"}
              blockId={selectedEditBlock}
              dataType={'title'}
            />
            <p className="ml-5 mt-5 text-lg text-black">Title size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={35}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-title', value, 'textSize')}
                  value={Number(foundBlock.settings.text_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={35}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.text_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-title', value, 'textSize')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Title Color</p>
            <ColorPicker
              value={foundBlock.settings.text_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-title', value, 'textColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
          </div>
        </>
      );
    }

    case "sofia-button": {
      return (
        <>
          <div className="w-[27%] bg-white sticky !h-[100vh] top-[4rem]" style={{ paddingBottom: 200 }}>
            <InputBlock
              label="Text"
              value={foundBlock.settings.text}
              handleChange={handleChangePageData}
              type={"sofia-button"}
              blockId={selectedEditBlock}
              dataType={'text'}
            />
            {/* Text size */}
            <p className="ml-5 mt-5 text-lg text-black">Text size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-button', value, 'textSize')}
                  value={Number(foundBlock.settings.text_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.text_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-button', value, 'textSize')}
                />
              </Col>
            </Row>
            {/* Text size */}
            <p className="ml-5 mt-5 text-lg text-black">Button radius</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-button', value, 'BtnRadius')}
                  value={Number(foundBlock.settings.radius)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.radius)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-button', value, 'BtnRadius')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Text Color</p>
            <ColorPicker
              value={foundBlock.settings.text_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-button', value, 'textColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <p className="ml-5 mt-5 text-lg text-black">Background Color</p>
            <ColorPicker
              value={foundBlock.settings.background_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-button', value, 'BGColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <InputBlock
              label="Button url"
              value={foundBlock.settings.url}
              handleChange={handleChangePageData}
              type={"sofia-button"}
              blockId={selectedEditBlock}
              dataType={'BtnUrl'}
            />
          </div>
        </>
      );
    }

    case "sofia-text": {
      return (
        <>
          <div className="w-[27%] bg-white sticky !h-[100vh] top-[4rem]" style={{ paddingBottom: 200 }}>
            <p className="ml-5 mt-3 text-lg text-black">Text</p>
            <ReactQuill
              ref={reactQuillRef}
              className="text-editor-input-pre-lander mt-2 ml-5 mb-5"
              value={foundBlock.settings.text}
              onChange={(content, delta, source) =>
                handleEditorChange(content, delta, source, selectedEditBlock, foundBlock.type)
              }
              placeholder="Write something..."
              modules={modules}
              formats={formats}
            />
            <p className="ml-5 mt-3 text-lg text-black">Font size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-text', value, 'fontSize')}
                  value={Number(foundBlock.settings.text_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.text_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-text', value, 'fontSize')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Text Color</p>
            <ColorPicker
              value={foundBlock.settings.text_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-text', value, 'textColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
          </div>
        </>
      );
    }

    case "sofia-testimonial": {
      return (
        <>
          <div className="w-[27%] bg-white sticky h-[100vh] overflow-y-auto overflow-y-auto top-[4rem]" style={{ paddingBottom: 200 }}>
            <InputBlock
              label="User name"
              value={foundBlock.settings.user}
              handleChange={handleChangePageData}
              type={"sofia-testimonial"}
              blockId={selectedEditBlock}
              dataType={'user'}
            />
            <p className="ml-5 mt-5 text-lg text-black">Text Color</p>
            <ColorPicker
              value={foundBlock.settings.user_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-testimonial', value, 'userColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <p className="ml-5 mt-3 text-lg text-black">Text</p>
            <ReactQuill
              ref={reactQuillRef}
              className="text-editor-input-pre-lander mt-5 ml-5"
              value={foundBlock.settings.text}
              onChange={(content, delta, source) =>
                handleEditorChange(content, delta, source, selectedEditBlock, foundBlock.type)
              }
              placeholder="Write something..."
              modules={modules}
              formats={formats}
            />
            {/* Text size */}
            <p className="ml-5 mt-5 text-lg text-black">Testimonial text size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-testimonial', value, 'testTextSize')}
                  value={Number(foundBlock.settings.text_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.text_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-testimonial', value, 'testTextSize')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Text Color</p>
            <ColorPicker
              value={foundBlock.settings.text_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-testimonial', value, 'textColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            {/* Border width */}
            <p className="ml-5 mt-5 text-lg text-black">Border width</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-testimonial', value, 'testBorderWidth')}
                  value={Number(foundBlock.settings.border_width)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.border_width)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-testimonial', value, 'testBorderWidth')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Border Color</p>
            <ColorPicker
              value={foundBlock.settings.border_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-testimonial', value, 'BCColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <p className="ml-5 mt-5 text-lg text-black">Background Color</p>
            <ColorPicker
              value={foundBlock.settings.background_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-testimonial', value, 'BGColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
          </div>
        </>
      );
    }

    case "sofia-notify": {
      return (
        <>
          <div className="w-[27%] bg-white sticky h-[100vh] overflow-y-auto overflow-y-auto top-[4rem]" style={{ paddingBottom: 200 }}>
            <p className="ml-5 mt-3 text-lg text-black">Text</p>
            <ReactQuill
              ref={reactQuillRef}
              className="text-editor-input-pre-lander mt-3 ml-5"
              value={foundBlock.settings.text}
              onChange={(content, delta, source) =>
                handleEditorChange(content, delta, source, selectedEditBlock, foundBlock.type)
              }
              placeholder="Write something..."
              modules={modules}
              formats={formats}
            />
            {/* Text size */}
            <p className="ml-5 mt-3 text-lg text-black">Notification text size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifTextSize')}
                  value={Number(foundBlock.settings.text_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.text_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifTextSize')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Notification Text Color</p>
            <ColorPicker
              value={foundBlock.settings.text_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifTextColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            {/* Border width */}
            <p className="ml-5 mt-3 text-lg text-black">Notification border width</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifBorderWidth')}
                  value={Number(foundBlock.settings.border_width)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.border_width)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifBorderWidth')}
                />
              </Col>
            </Row>
            {/* Border radius */}
            <p className="ml-5 mt-3 text-lg text-black">Notification border radius</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifBorderRadius')}
                  value={Number(foundBlock.settings.border_radius)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.border_radius)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifBorderRadius')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Notification Border Color</p>
            <ColorPicker
              value={foundBlock.settings.border_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifBCColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <p className="ml-5 mt-5 text-lg text-black">Notification Background Color</p>
            <ColorPicker
              value={foundBlock.settings.background_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-notify', value, 'notifBGColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
          </div>
        </>
      );
    }

    case "sofia-rating": {
      return (
        <>
          <div className="w-[27%] h-[100vh] overflow-y-auto top-[4rem] bg-white sticky">
            <p className="ml-5 mt-3 text-lg text-black">Rating</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={5}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-rating', value, 'ratingNumber')}
                  value={Number(foundBlock.settings.rating)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={5}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.rating)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-rating', value, 'ratingNumber')}
                />
              </Col>
            </Row>
            <InputBlock
              label={'Rating detail'}
              className="!mt-5"
              value={foundBlock.settings.detail}
              handleChange={handleChangePageData}
              type={"sofia-rating"}
              blockId={selectedEditBlock}
              dataType={'ratingDetail'}
            />
          </div>
        </>
      );
    }

    case "sofia-image": {
      return (
        <>
          <div className="w-[27%] h-[100vh] overflow-y-auto top-[4rem] bg-white sticky">
            <div className="w-[90%] mt-5 ml-4">
              <p className="mb-3 text-lg text-black">Image</p>
              <SectionEditImageContainer
                productImages={productImages}
                setProductImages={setProductImages}
                toUpdateImageDetails={toUpdateImageDetails}
                imageUrl={foundBlock.settings.default_url}
                fromPrelander={true}
                dataType={'sofia-image'}
                handleChangePageData={handleChangePageData}
                selectedEditBlock={selectedEditBlock}
                sectionsImages={sectionsImages}
                setSectionsImages={setSectionsImages}
                setTextOnImageDetails={setTextOnImageDetails}
                isCanvaConnected={isCanvaConnected}
                awaitingRefresh={awaitingRefresh}
                setAwaitingRefresh={setAwaitingRefresh}
                isEditCanvaLoading={isEditCanvaLoading}
                setIsEditCanvaLoading={setIsEditCanvaLoading}
              />
            </div>
          </div>
        </>
      );
    }


    case "sofia-text-image": {
      return (
        <>
          <div className="w-[27%] h-[100vh] overflow-y-auto top-[4rem] bg-white sticky" style={{ paddingBottom: 200 }}>
            <InputBlock
              label="Title"
              value={foundBlock.settings.title}
              handleChange={handleChangePageData}
              type={"sofia-title"}
              blockId={selectedEditBlock}
              dataType={'title'}
            />
            <p className="ml-5 mt-3 text-lg text-black">Title size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-text-image', value, 'titleSize')}
                  value={Number(foundBlock.settings.title_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.title_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-text-image', value, 'titleSize')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Title Color</p>
            <ColorPicker
              value={foundBlock.settings.title_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-text-image', value, 'titleColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <ReactQuill
              ref={reactQuillRef}
              className="text-editor-input-pre-lander mt-5 ml-5"
              value={foundBlock.settings.text}
              onChange={(content, delta, source) =>
                handleEditorChange(content, delta, source, selectedEditBlock, foundBlock.type)
              }
              placeholder="Write something..."
              modules={modules}
              formats={formats}
            />
            <p className="ml-5 mt-3 text-lg text-black">Text size</p>
            <Row className="ml-5">
              <Col span={12}>
                <Slider
                  min={1}
                  max={30}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-text-image', value, 'textSize')}
                  value={Number(foundBlock.settings.text_size)}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={30}
                  style={{ margin: '0 16px' }}
                  value={Number(foundBlock.settings.text_size)}
                  onChange={(value) => handleChangePageData(selectedEditBlock, 'sofia-text-image', value, 'textSize')}
                />
              </Col>
            </Row>
            <p className="ml-5 mt-5 text-lg text-black">Text Color</p>
            <ColorPicker
              value={foundBlock.settings.text_color}
              onChange={(color, value) => handleChangePageData(selectedEditBlock, 'sofia-text-image', value, 'textColor')}
              showText 
              style={{ margin: '3px 20px' }}
            />
            <p className="ml-5 mt-3 text-lg text-black">Show Image</p>
            <Switch
              style={{ marginLeft: 20 }}
              checked={foundBlock.settings.show_image}
              onChange={(checked) => handleChangePageData(selectedEditBlock, 'sofia-text-image', checked, 'showImage') }
            />
            {foundBlock.settings.show_image && (
              <div className="w-[90%] mt-5 ml-4">
                <p className="mb-3 text-lg text-black">Image</p>
                <SectionEditImageContainer
                  productImages={productImages}
                  setProductImages={setProductImages}
                  toUpdateImageDetails={toUpdateImageDetails}
                  imageUrl={foundBlock.settings.default_url}
                  fromPrelander={true}
                  dataType={'sofia-text-image'}
                  handleChangePageData={handleChangePageData}
                  selectedEditBlock={selectedEditBlock}
                  sectionsImages={sectionsImages}
                  setSectionsImages={setSectionsImages}
                  setTextOnImageDetails={setTextOnImageDetails}
                  isCanvaConnected={isCanvaConnected}
                  awaitingRefresh={awaitingRefresh}
                  setAwaitingRefresh={setAwaitingRefresh}
                  isEditCanvaLoading={isEditCanvaLoading}
                  setIsEditCanvaLoading={setIsEditCanvaLoading}
                />
              </div>
            )}
          </div>
        </>
      );
    }

    default: {
      return (
        <div className="w-[27%] !h-[100vh] top-[4rem] bg-white"></div>
      )
    }
  }
}

export default RightSectionPreLander

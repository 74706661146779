import React, { useEffect, useState } from "react";
import { Input, message, Segmented, Spin } from "antd";
import VariantsImageGallery from "./VariantsImageGallery/VariantsImageGallery";
import VariantsPropertiesContainer from "./VariantsProptiesContainer/VariantsProptiesContainer";
import useVariantsData from "../../hooks/useVariantsData";
import {
  changeLabelNameOfProperties,
  setEditViewStyle,
} from "../../../redux/slices/product-variants-slice";
import { useDispatch, useSelector } from "react-redux";
import checkDisableShipsFromOnEditView from "../../../Utilities/productVariantsHandlers/checkDisableShipsFromOnEditView";
import ConnectCanva from "../../Canva/ConvaConnect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ProductVariantsComponent = ({
  shipsFrom,
  isCanvaConnected,
  handleCanva,
  awaitingRefresh,
  setAwaitingRefresh,
  isEditCanvaLoading,
  setIsEditCanvaLoading,
  productImages,
  setProductImages,
  sectionsImages,
  setSectionsImages,
  setReviews,
  setTestimonials,
  setTextOnImageDetails,
  toUpdateImageDetails,
}) => {
  const dispatch = useDispatch();
  const {
    productData,
    globallyDisabledProperties,
    totalEnabledVariants,
    editViewStyle,
    variantsArrangedIndexesObject: uniqueValuesObject,
  } = useSelector((store) => store.productVariants);

  const { data } = productData;
  const {
    propertiesArray,
    formattedImagesObject,
    newImagesObject,
    aliexpressSkuObject,
  } = useVariantsData(productData);

  const [variantImages, setVariantImages] = useState(data.external_images);
  const [duplicateLabelNameError, setDuplicateLabelNameError] = useState({
    error: false,
    index: "",
  });
  const [updatePropertyName, setUpdatePropertyName] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setLocalLabels(
      propertiesArray.reduce((acc, property) => {
        acc[property] = property;
        return acc;
      }, {})
    );
  }, [update]);

  // Local state to handle labels independently from Redux
  const [localLabels, setLocalLabels] = useState(
    propertiesArray.reduce((acc, property) => {
      acc[property] = property;
      return acc;
    }, {})
  );
  const [localPropertiesLabel, setLocalPropertiesLabel] = useState(
    Object.fromEntries(
      Object.values(uniqueValuesObject)
        .flat()
        .map((property) => [property, property])
    )
  );
  useEffect(() => {
    setLocalPropertiesLabel(
      Object.fromEntries(
        Object.values(uniqueValuesObject)
          .flat()
          .map((property) => [property, property])
      )
    );
  }, [updatePropertyName]);
  // const arr = [];
  // Object.values(uniqueValuesObject).map((p) => {
  //   p.map((p) => arr.push(p));
  // });

  // const [localPropertiesLabel, setLocalPropertiesLabel] = useState(
  //   arr.reduce((acc, property) => {
  //     acc[property] = property;
  //     return acc;
  //   }, {})
  // );

  // Handle label change for local state
  const handleLabelChange = (property, value, index) => {
    if (value !== property && uniqueValuesObject.hasOwnProperty(value)) {
      setDuplicateLabelNameError({ error: true, index: index });
      //  return
    } else {
      setDuplicateLabelNameError({ error: false, index: "" });
    }
    setLocalLabels({
      ...localLabels,
      [property]: value,
    });
    console.log("here", localLabels[property]);
  };

  // Sync local label to Redux only on blur
  const handleLabelBlur = (property) => {
    const trimmedLabel = localLabels[property]?.trim();
    if (uniqueValuesObject.hasOwnProperty(trimmedLabel)) {
      setDuplicateLabelNameError({ error: false, index: "" });
      setLocalLabels((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
      return;
    } else if (trimmedLabel && trimmedLabel !== property) {
      dispatch(
        changeLabelNameOfProperties({
          property,
          value: trimmedLabel,
        })
      );
      setUpdate(!update);
      setLocalLabels((prevLabels) => ({
        ...prevLabels,
        [property]: trimmedLabel, // Reset to the default name if the input is empty
      }));
    } else if (!trimmedLabel) {
      setLocalLabels((prevLabels) => ({
        ...prevLabels,
        [property]: property, // Reset to the default name if the input is empty
      }));
    }
  };
  console.log("localLabels,", localLabels);

  return (
    <div className="right-variant-container">
      <div className="variant-header-container">
        <h6 className="variant-header">Variants ({totalEnabledVariants})</h6>
        <ConnectCanva
          handleCanva={handleCanva}
          isCanvaConnected={isCanvaConnected}
        />
      </div>
      <div>
        <h6 className="variant-view-text">Variant Display Style</h6>
        <Segmented
          style={{ width: "100%" }}
          className="variant-view-styles"
          defaultValue={editViewStyle}
          onChange={(value) => dispatch(setEditViewStyle(value))}
          options={["Dropdown", "Pills"]}
        />
      </div>
      {propertiesArray?.map((property, index) => (
        <React.Fragment key={index}>
          {uniqueValuesObject[property]?.length > 1 ? (
            <div>
              <h6 className="variant-view-text">{`Variant: ${
                localLabels[property] ? localLabels[property] : ""
              }`}</h6>
              <Input
                value={localLabels[property]}
                onChange={(e) =>
                  handleLabelChange(property, e.target.value, index)
                }
                onBlur={() => handleLabelBlur(property)}
                className={`${
                  duplicateLabelNameError.error &&
                  duplicateLabelNameError.index == index
                    ? "mb-1  !text-[red]"
                    : "mb-[16px]"
                } variant-name-container`}
              />
              {duplicateLabelNameError.error &&
                duplicateLabelNameError.index == index && (
                  <p className="text-[12px] text-[red] mb-[16px]">
                    Variant type already exists!
                  </p>
                )}

              {newImagesObject.hasOwnProperty(property) ? (
                <Spin spinning={isEditCanvaLoading}>
                  <DndProvider backend={HTML5Backend}>
                    <VariantsImageGallery
                      {...{
                        setReviews,
                        setTestimonials,
                        setTextOnImageDetails,
                      }}

                      toUpdateImageDetails={toUpdateImageDetails}
                      galleryImages={productImages}
                      setGalleryImages={setProductImages}
                      sectionsImages={sectionsImages}
                      setSectionsImages={setSectionsImages}
                      shipsFrom={shipsFrom}
                      toIterate={uniqueValuesObject}
                      property={property}
                      productImages={newImagesObject[property]}
                      productVariantDetails={formattedImagesObject}
                      setProductImages={setVariantImages}
                      aliexpressImages={variantImages}
                      isCanvaConnected={isCanvaConnected}
                      awaitingRefresh={awaitingRefresh}
                      setAwaitingRefresh={setAwaitingRefresh}
                      isEditCanvaLoading={isEditCanvaLoading}
                      setIsEditCanvaLoading={setIsEditCanvaLoading}
                      aliexpressSkuObject={aliexpressSkuObject}
                      localPropertiesLabel={localPropertiesLabel}
                      setLocalPropertiesLabel={setLocalPropertiesLabel}
                      setUpdatePropertyName={setUpdatePropertyName}
                    />
                  </DndProvider>
                </Spin>
              ) : (
                <div className="grid grid-cols-2 xl:grid-cols-3 gap-2 mb-[32px]">
                  {uniqueValuesObject[property]?.map((val, i) => {
                    let disable;
                    // if (shipsFromArray.length > 0) {
                    //   disable = checkDisableShipsFromOnEditView({
                    //     product_variants,
                    //     property_name: property,
                    //     property_value: val,
                    //     globallyDisabledProperties,
                    //     shipsFrom: shipsFrom,
                    //   });
                    // } else {
                    disable =
                      globallyDisabledProperties[property]?.includes(val) ??
                      false;
                    // }
                    return (
                      <DndProvider backend={HTML5Backend} key={i}>
                        <VariantsPropertiesContainer
                          localPropertiesLabel={localPropertiesLabel}
                          setLocalPropertiesLabel={setLocalPropertiesLabel}
                          index={i}
                          shipsFrom={shipsFrom}
                          property={property}
                          value={val}
                          disable={disable}
                          aliexpressSkuObject={aliexpressSkuObject}
                          setUpdatePropertyName={setUpdatePropertyName}
                        />
                      </DndProvider>
                    );
                  })}
                </div>
              )}
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProductVariantsComponent;

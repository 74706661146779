import React from "react";

const ImageSection = ({
  blockId,
  imgUrl,
  selectedBlock,
  handleSelectBlock,
}) => {
  return (
    <div
      className={`edit-formatted-text-pre-lander mb-5 ${selectedBlock === blockId ? "outline-border-selected" : "outline-border"}`}
      onClick={() => handleSelectBlock(blockId)}
      style={{ 
        backgroundImage: `url(${imgUrl})`, 
        paddingTop: '50%', 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: '100%', 
        backgroundPosition: 'center' 
      }}
    >
    </div>
  )
}

export default ImageSection;

export const colors = {
    "default": {
        "purple":{
            text_color:"55,59,66",
            background_color:"255,255,255",
            button_background:"55,59,66",
            button_text_color:"255,255,255",
            section_1_background:"0, 0, 0",
            section_1_text_color:"255,255,255"
        },
        "green":{
            text_color:"55,59,66",
            background_color:"255,255,255",
            button_background:"49,87,44",
            button_text_color:"255,255,255",
            section_1_background:"49,87,44",
            section_1_text_color:"255,255,255"
        }
    },
    "pro": {
        "purple":{
            text_color:"55,59,66",
            background_color:"255,255,255",
            button_background:"105,65,198",
            button_text_color:"255,255,255",
            feature_heading_color:"105,65,198",

        },
        "green":{
            text_color:"55,59,66",
            background_color:"255,255,255",
            button_background:"49,87,44",
            button_text_color:"255,255,255",
            feature_heading_color:"49,87,44",

        }
    },
    "kids": {
        "purple":{
            text_color:"55,59,66",
            background_color:"255,255,255",
            button_background:"105,65,198",
            button_text_color:"255,255,255",
            feature_heading_color:"105,65,198",
            dark_section_heading_color:"#ffffff",
            dark_section_background:"#7f56d9",
            steps_section_background:"#b18cd9",
            steps_section_text_color:"#fffbff",
            shipping_badge_background:"#f3edff"

        },
        "green":{
            text_color:"55,59,66",
            background_color:"255,255,255",
            button_background:"49,87,44",
            button_text_color:"255,255,255",
            feature_heading_color:"49,87,44",
            dark_section_heading_color:"#ffffff",
            dark_section_background:"#31572C",
            steps_section_background:"#4F772D",
            steps_section_text_color:"#fffbff",
            shipping_badge_background:"#ECF39E"

        }
    }
}
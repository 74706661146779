import React, { useEffect, useState } from "react";
import "./ProductEditView.css";
import TopBar from "./TopBar";
import RightSection from "./RightSection";
import PrductGallery from "./PrductGallery";
import PriceAndHeadingSection from "./PriceAndHeadingSection";
import FirstContentBox from "./FirstContentBox";
import SecondContentBox from "./SecondContentBox";
import ThirdContentBox from "./ThirdContentBox";
import FourthContentBox from "./FourthContentBox";
import FifthContentBox from "./FifthContentBox";
import ProductFooter from "./ProductFooter";
import { useParams } from "react-router-dom";
import { convertResponseJsonToObject } from "../../Utilities/ConvertInObjectForm";
import { Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import VariantPricingTable from "../VariantPricingTable";
import ShippingConfigurationDetails from "../ShippingConfigurationDetails";
import { useDispatch, useSelector } from "react-redux";

import getPriceOfUniquProperties from "../../Utilities/productVariantsHandlers/getPriceOfUniquProperties";
import useVariantsData from "../hooks/useVariantsData";
import varinatsShippedFrom from "../../Utilities/productVariantsHandlers/VariantsShippedFrom";

import SavePublishModal from "./TopBar/SavePublishModal";
import { setArragedVariantsData } from "../../redux/slices/product-variants-slice";
import { setStaticMediaImagesInStateimport } from "../../redux/slices/static-media-images-slice";
import GeneratePreLandingConfirmModal from "./TopBar/GeneratePreLandingConfirmModal";

const ProductEditView = () => {
  const dispatch = useDispatch();

  const {
    productData: Data,
    selectedProperties,
    totalEnabledVariants,
    totalVriants,
    totalAllowedVariants,
    globallyDisabledProperties,
    enableDisable,
    variantsArrangedIndexesObject: uniqueValuesObject,
  } = useSelector((store) => store.productVariants);

  const {
    propertiesArray,
    shipsFromArray,
    uniqueValuesObject: uniqueValue,
    newImagesObject,
    imageKey,
  } = useVariantsData(Data);

  const { templateType, colorPalette, colorPaletteColors} = useSelector((store) => store.templateSlice);
  const colorPalettes = colorPaletteColors[templateType][colorPalette.toLowerCase()] || {}
 
  useEffect(() => {
    const orderOfVariants = {
      color: ["Grey", "Black", "khaki"],
      images: [],
      "Shoe Size": ["39", "42", "40", "41", "44", "43"],
    };
    if (Object.keys(uniqueValuesObject).length === 0) {
      dispatch(setArragedVariantsData(uniqueValue));
    }
  }, [uniqueValue]);

  const { data } = Data;
  const { productTemplate } = data;
  const { id } = useParams();
  const [productData, setProductData] = useState({});
  const [productPrice, setProductPrice] = useState({ price: 0, salePrice: 0 });
  const { product_variants } = data;
  const [propertiesLength, setPropertiesLength] = useState(
    product_variants?.length
  );
  const [showSalePrice, setShowSalePrice] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editsectionImageUrl, setEditsectionImageUrl] = useState("");
  const [sectionsImages, setSectionsImages] = useState([]);
  const [aliexpressImages, setAliexpressImages] = useState([]);
  const [staticMediaImages, setStaticMediaImages] = useState([]);
  const [variantView, setVariantViewChange] = useState("Dropdown");
  const [step, setStep] = useState("Product template");
  const [shipsFrom, setShipsFrom] = useState();
  const [groupBy, setGroupBy] = useState(0);

  const [sectionClick, setSectionClick] = useState({
    bulletBenefits: false,
    guaranteeHeadline: false,
    guaranteeText: false,
    productName: false,
    salesText1: false,
    salesText2: false,
    salesText3: false,
    salesTextHeadline1: false,
    salesTextHeadline2: false,
    salesTextHeadline3: false,
    satisfactionGuaranteeHeadline: false,
    satisfactionGuaranteeText: false,
    socialProofHeadline: false,
    socialProofText: false,
    price: false,
    shippingLink: false,
    section1Image: false,
    section2Image: false,
    section3Image: false,
    section4Image: false,
    section5Image: false,
    section6Image: false,
    gallery: false,
    productVariants: false,
  });

  const [selectedProperty, setSelectedProperty] = useState([]);
  const [savingLoading, setSavingLoading] = useState(false);
  const [showGenerateLanding, setShowGenerateLanding] = useState(false);
  const [saveOrPublish, setSaveOrPublish] = useState("");

  // cache images
  const [imageCache, setImageCache] = useState({});
  const [isImgCachingLoading, setIsImgCachingLoading] = useState(true);
  const preloadImages = async (srcArray) => {
    const cache = {};

    const promises = srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;

        img.onload = () => {
          cache[src] = img;
          resolve(img);
        };

        img.onerror = (error) => {
          console.error(`Error loading image: ${src}`, error);
          reject(error);
        };
      });
    });

    await Promise.all(promises);
    return cache;
  };

  useEffect(() => {
    const variantImages = imageKey
      ? Object.values(newImagesObject[imageKey])
      : [];
    const sectionImgArray = Object.values(sectionsImages) || [];
    const allImages = [...variantImages, ...sectionImgArray, ...productImages];
    const allUniqueImages = [...new Set(allImages)]; // Remove duplicates

    // Cache all images
    const preloadAndCacheImages = async (allUniqueImages) => {
      try {
        // setIsImgCachingLoading(true);
        const cache = await preloadImages(allUniqueImages);
        console.log("Cached images:", cache);
        setImageCache(cache);
        setIsImgCachingLoading(false);
      } catch (error) {
        console.error("Error preloading images:", error);
        setIsImgCachingLoading(false);
      }
    };

    preloadAndCacheImages(allUniqueImages);
  }, [sectionsImages, productImages]);

  useEffect(() => {
    if (!(product_variants?.length <= 1)) {
      const filtered = varinatsShippedFrom(product_variants, shipsFrom);
      const priceFromFiltered =
        filtered.length > 0 ? filtered : product_variants;
      const variant = getPriceOfUniquProperties(
        selectedProperties,
        Data,
        priceFromFiltered,
        shipsFrom
      );
      setProductPrice({
        price: variant?.price,
        salePrice: variant?.discounted_price,
      });
      setShowSalePrice(variant?.enableSalePrice);
      console.log("selected variant =====> ", variant);
    }
  }, [Data, selectedProperties]);

  useEffect(() => {
    if (Object.keys(uniqueValuesObject).length !== 0) {
      const startingSelection = propertiesArray?.map((select) => {
        // Find the index of the first enabled property, or default to -1 if all are disabled
        let ind = uniqueValuesObject[select]?.findIndex(
          (pro) => !globallyDisabledProperties[select]?.includes(pro)
        );

        // If no enabled properties are found, set to a default value (e.g., 0), or handle as needed
        ind = ind !== -1 ? ind : 0;

        // Return the selected property or null if the uniqueValuesObject[select] is undefined or empty
        return { [select]: uniqueValuesObject[select]?.[ind] || null };
      });

      const startSelectionObject = startingSelection?.reduce((acc, item) => {
        Object.entries(item).forEach(([key, value]) => {
          acc[key] = value;
        });

        return acc;
      }, {});

      setSelectedProperty(startSelectionObject);
    }
  }, [Data, uniqueValuesObject]);

  useEffect(() => {
    if (product_variants?.length <= 1) {
      setShowSalePrice(product_variants[0]?.enableSalePrice);
      setProductPrice({ price: product_variants[0]?.price });
      if (product_variants[0]?.enableSalePrice) {
        if(product_variants[0]?.price>0){
          setProductPrice({
            price: product_variants[0]?.price,
            salePrice: product_variants[0]?.discounted_price,
          });
        }else{
          setProductPrice({
            price: product_variants[0]?.discounted_price,
            salePrice: 0,
          });
          setShowSalePrice(false);

        }
      }
    }
  }, []);

  useEffect(() => {
    setShipsFrom(Data?.shipsFrom);
    setProductData({
      ...convertResponseJsonToObject(data.productTemplate),
      ["productStatus"]: Data.product_status,
    });
    setSectionsImages(data.sectionImages);
    setProductImages(data.external_images ? data.external_images : data.media_images);
    setAliexpressImages(data.external_images);
    dispatch(setStaticMediaImagesInStateimport(data.media_images));
    // }
  }, []);
  const [labelName, setLabelName] = useState(
    propertiesArray?.reduce((acc, prop) => {
      acc[prop] = prop;
      return acc;
    }, {})
  );
  const handleSectionEditChange = (keys) => {
    const allFalse = Object.keys(sectionClick).reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {});

    const newSectionClick = { ...allFalse };
    keys.forEach((key) => {
      newSectionClick[key] = true;
    });

    setSectionClick(newSectionClick);
  };
  const antIcon = (
    <LoadingOutlined
      className="custom-spin-icon"
      style={{ fontSize: "50px" }}
      spin
    />
  );

  return isImgCachingLoading ? (
    <div className="spinner-overlay">
      <div className="spinner-container">
        <Spin className="custom-spin" indicator={antIcon} />
      </div>
    </div>
  ) : (
    <div className="product-edit-view-main-container">
      <TopBar
        setSaveOrPublish={setSaveOrPublish}
        loading={savingLoading}
        setLoading={setSavingLoading}
        showGenerateLanding={showGenerateLanding}
        setShowGenerateLanding={setShowGenerateLanding}
        shipsFrom={shipsFrom}
        setShipsFrom={setShipsFrom}
        propertiesLength={propertiesLength}
        showSalePrice={showSalePrice}
        productData={productData}
        productPrice={productPrice}
        productImages={productImages}
        sectionsImages={sectionsImages}
        id={id}
        step={step}
        setStep={setStep}
        labelName={labelName}
        setLabelName={setLabelName}
      />

      {step === "Product template" ? (
        <div className="edit-product-content-container">
          <div className="product-main-left-div">
            {
              totalEnabledVariants > totalAllowedVariants ? (
                <Alert
                  message={`Enable Variants (${totalEnabledVariants}) exceed the allowed limit (${totalAllowedVariants})`}
                  type="error"
                  className={"variants-count-error-message"}
                />
              ) : (
                ""
              )
              // <Alert message={`Enable Variants (${totalEnabledVariants}) Grand Total Variants are with ships from (${totalVriants})`} type="success" className={"variants-count-error-message"} />
            }
            <div className="product-main-left-container">
              <div className="edit-product-first-section">
                <PrductGallery
                  setProductImages={setProductImages}
                  imageCache={imageCache}
                  images={productImages}
                  galleryImages={sectionClick?.gallery}
                  handleSectionEditChange={handleSectionEditChange}
                />

                <PriceAndHeadingSection
                  imageCache={imageCache}
                  shipsFrom={shipsFrom}
                  propertiesLength={propertiesLength}
                  labelName={labelName}
                  setLabelName={setLabelName}
                  variantView={variantView}
                  productName={productData?.productName || ""}
                  productNameSection={sectionClick?.productName}
                  showSalePrice={showSalePrice}
                  setShowSalePrice={setShowSalePrice}
                  productPrice={productPrice}
                  productPriceSection={sectionClick?.price}
                  guaranteeHeading={productData?.guaranteeHeadline}
                  guaranteeSection={sectionClick?.guaranteeHeadline}
                  shippingLink={sectionClick?.shippingLink}
                  guaranteeText={productData?.guaranteeText}
                  handleSectionEditChange={handleSectionEditChange}
                  productVariants={sectionClick?.productVariants}
                  selectedProperty={selectedProperty}
                  setSelectedProperty={setSelectedProperty}
                  colorsPallet={colorPalettes}
                />
              </div>

              <div className="product-middle-main-container">
                <FirstContentBox
                  imageCache={imageCache}
                  heading={productData?.salesTextHeadline1}
                  text={productData?.salesText1}
                  image={sectionsImages?.section1Image}
                  salesTextHeadline1={sectionClick?.salesTextHeadline1}
                  handleSectionEditChange={handleSectionEditChange}
                  editsectionImageUrl={editsectionImageUrl}
                  setEditsectionImageUrl={setEditsectionImageUrl}
                  section1Image={sectionClick?.section1Image}
                  colorsPallet={colorPalettes }

                />

                {loading && (
                  <div className="spinner-overlay">
                    <div className="spinner-container">
                      <Spin className="custom-spin" indicator={antIcon} />
                    </div>
                  </div>
                )}

                <SecondContentBox
                  imageCache={imageCache}
                  heading={productData?.salesTextHeadline2}
                  text={productData?.salesText2}
                  image={sectionsImages?.section2Image ?? sectionsImages?.section1Image}
                  handleSectionEditChange={handleSectionEditChange}
                  editsectionImageUrl={editsectionImageUrl}
                  setEditsectionImageUrl={setEditsectionImageUrl}
                  section2Image={sectionClick?.section2Image}
                  salesTextHeadline2={sectionClick?.salesTextHeadline2}
                />
                <ThirdContentBox
                  imageCache={imageCache}
                  content={productTemplate?.bulletBenefits}
                  setSectionClick={setSectionClick}
                  image={sectionsImages?.section3Image ?? sectionsImages?.section1Image}
                  handleSectionEditChange={handleSectionEditChange}
                  editsectionImageUrl={editsectionImageUrl}
                  setEditsectionImageUrl={setEditsectionImageUrl}
                  section3Image={sectionClick?.section3Image}
                  bulletBenefits={sectionClick?.bulletBenefits}
                />
                <FourthContentBox
                  imageCache={imageCache}
                  heading={productData?.salesTextHeadline3}
                  text={productData?.salesText3}
                  image={sectionsImages?.section4Image ?? sectionsImages?.section1Image}
                  handleSectionEditChange={handleSectionEditChange}
                  editsectionImageUrl={editsectionImageUrl}
                  setEditsectionImageUrl={setEditsectionImageUrl}
                  section4Image={sectionClick?.section4Image}
                  salesTextHeadline3={sectionClick?.salesTextHeadline3}
                  colorsPallet={colorPalettes}

                />
                <FifthContentBox
                  imageCache={imageCache}
                  heading={productData?.socialProofHeadline}
                  text={productData?.socialProofText}
                  image={sectionsImages?.section5Image ?? sectionsImages?.section1Image}
                  handleSectionEditChange={handleSectionEditChange}
                  editsectionImageUrl={editsectionImageUrl}
                  setEditsectionImageUrl={setEditsectionImageUrl}
                  section5Image={sectionClick?.section5Image}
                  socialProofHeadline={sectionClick?.socialProofHeadline}
                  colorsPallet={colorPalettes}

                />
              </div>
              <ProductFooter
                imageCache={imageCache}
                heading={productData?.satisfactionGuaranteeHeadline}
                productFooterSection={
                  sectionClick?.satisfactionGuaranteeHeadline
                }
                text={productData?.satisfactionGuaranteeText}
                handleSectionEditChange={handleSectionEditChange}
                section6Image={sectionClick?.section6Image}
                image={sectionsImages?.section6Image}
              />
            </div>
          </div>
          <RightSection
            imageCache={imageCache}
            labelName={labelName}
            setLabelName={setLabelName}
            showSalePrice={showSalePrice}
            setShowSalePrice={setShowSalePrice}
            productPrice={productPrice}
            setProductPrice={setProductPrice}
            productData={productData}
            updateProductData={setProductData}
            sectionClick={sectionClick}
            setSectionClick={setSectionClick}
            handleSectionEditChange={handleSectionEditChange}
            editsectionImageUrl={editsectionImageUrl}
            setEditsectionImageUrl={setEditsectionImageUrl}
            sectionsImages={sectionsImages}
            setSectionsImages={setSectionsImages}
            productImages={productImages}
            setProductImages={setProductImages}
            aliexpressImages={aliexpressImages}
            // productVariantDetails={variantsDetails}
            setVariantViewChange={setVariantViewChange}
            variantView={variantView}
            productVariants={product_variants}
            shipsFrom={shipsFrom}
            colorsPallet={colorPalettes}
            propertiesLength={propertiesLength}
          />
        </div>
      ) : step === "Variant pricing" ? (
        <VariantPricingTable
          imageCache={imageCache}
          shipsFrom={shipsFrom}
          setShipsFrom={setShipsFrom}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          labelName={labelName}
          setLabelName={setLabelName}
          productPrice={productPrice}
        />
      ) : (
        <ShippingConfigurationDetails shipsFrom={shipsFrom} />
      )}

      <SavePublishModal
        OpenModal={savingLoading}
        saveOrPublish={saveOrPublish}
      />

      <GeneratePreLandingConfirmModal
        openModal={showGenerateLanding}
      />
    </div>
  );
};

export default ProductEditView;

import React from "react";
import DOMPurify from "dompurify";

const NotifySection = ({
  blockId,
  text,
  settings,
  selectedBlock,
  handleSelectBlock,
}) => {
  const sectionText = DOMPurify.sanitize(text);

  return (
    <div
      style={{
        border: `${settings.border_width}px solid ${settings.border_color}`,
        background: settings.background_color
      }}
      className={`notify-pre-lander mb-5 outline-border1`}
      onClick={() => handleSelectBlock(blockId)}
    >
      <div 
        style={{ 
          fontSize: settings.text_size,
          color: settings.text_color
        }} 
        className="text-black" 
        dangerouslySetInnerHTML={{ __html: sectionText }}>        
      </div>
    </div>
  )
}

export default NotifySection;

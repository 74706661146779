import React from 'react'

const PetsKidsTemplateSeventhSection = (
    {
        saleHeadline,
        saleSubSections,

        saleHeadlineSection,
        sectionClick,

        handleSectionEditChange,
        colorsPalette
    }
) => {
    return (
        <section className="scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr w-full"
        onClick={() => handleSectionEditChange(["saleColorSection"])}
        style={
            colorsPalette.sale_section_background
              ? {
                background: `rgb(${colorsPalette.sale_section_background})`,
                color: `rgb(${colorsPalette.sale_section_text_color})`,

              }
              : { }
          }
          >
            <div className="page-width">
                <div className='flex justify-center'>

                </div>
                <h2
                 style={
                    colorsPalette.sale_section_heading_color
                      ? {
                        color: `rgb(${colorsPalette.sale_section_heading_color})`,
        
                      }
                      : { }
                  }
                    onClick={(e) =>  { e.stopPropagation(); handleSectionEditChange(["saleHeadlineSection"])}}
                    className={` cursor-pointer section-title  ${saleHeadlineSection ? "border border-2 border-white"
                        : "border-dotted border-2 border-white"} `}>{saleHeadline}</h2>
                <div className="blocks-wrapper">
                    {
                      saleSubSections &&    saleSubSections.map((sale, index) => {
                            return (
                                <div key={index}
                                    onClick={(e) => { e.stopPropagation(); handleSectionEditChange([`saleSubSections${index + 1}`])}}
                                    className={`cursor-pointer content-block ${sectionClick[`saleSubSections${index + 1}`] ? "border border-2 border-white"
                                        : "border-dotted border-2 border-white"}`}>
                                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" color={`rgb(${colorsPalette.sale_section_heading_color})`}>
                                        <path stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="currentColor" d="M55.5 33.5C54.4309 42.0113 47.5781 48.0334 39 48C29.8874 47.9645 22.5 40.6127 22.5 31.5C22.5 22.3873 29.8873 15.0086 39 15C47.8409 14.9916 54.9563 21.6759 55.5 30.5M39 24.5V31.5H43H43.5H44M39 18.5V20.5M29.5 22L31.5 23.5M25.5 31.5H28.5M30 40.5L31.5 39L29.5 41M39 44.5V42M48.5 40.5L46.5 39M52 31.5H49.5M48 22.5L46.5 24L48.5 22M30 11.9687C32.7379 10.705 35.7866 10 39 10C50.8741 10 60.5 19.6259 60.5 31.5C60.5 43.3741 50.8741 53 39 53C35.5896 53 32.3647 52.206 29.5 50.7927M30 11.9687C22.6209 15.3746 17.5 22.8393 17.5 31.5C17.5 39.9637 22.3906 47.2852 29.5 50.7927M30 11.9687H14.5M16 18H6M16 24H11M12 28H2M12 34.5H2M16 39H11M16 44.5H6M29.5 50.7927H14"></path>
                                      </svg>

                                    <h3 className="block-heading blk-head-content_block_d7kbUB" 
                                    style={
                                        colorsPalette.sale_section_heading_color
                                          ? {
                                            color: `rgb(${colorsPalette.sale_section_heading_color})`,
                            
                                          }
                                          : { }
                                      }
                                      >{sale?.question}</h3>
                                    <p className="block-description blk-desc-content_block_d7kbUB font-medium"
                                     style={
                                        colorsPalette.sale_section_heading_color
                                          ? {
                                            color: `rgb(${colorsPalette.sale_section_heading_color})`,
                            
                                          }
                                          : { }
                                      }
                                      >`{sale?.answerTitle}`</p>
                                    <p  className="block-description blk-desc-content_block_d7kbUB font-normal" 
                                     style={
                                        colorsPalette.sale_section_heading_color
                                          ? {
                                            color: `rgb(${colorsPalette.sale_section_heading_color})`,
                            
                                          }
                                          : { }
                                      }
                                       >
                                        {sale?.answerText}
                                    </p>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
        </section>

    )
}

export default PetsKidsTemplateSeventhSection
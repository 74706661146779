import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
import { callBackendAPI } from "../../../helpers/apiCalls";
import CustomLoader from "../../CustomLoader";
import { useNavigate } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";

const CopyProductModal = ({ OpenModal, setOpModal, handleCopyFromEnd }) => {
  const navigate = useNavigate();

  const { product_id: productId, product_title: productName } =
    OpenModal?.productDetails;

  const [copyLoading, setCopyLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const [newProductId, setNewProductId] = useState(false);

  useEffect(() => {
    if (document.getElementById("link-input")) {
      document.getElementById("link-input").focus();
    }
    const modal = document.querySelector(".ant-modal-content");
    if (modal) {
      modal.setAttribute("style", "padding: 20px !important;");
    }
  }, [OpenModal]);

  const ConfirmButton = () => {
    return (
      <span className="">
        {!copyLoading ? (
          copied ? (
            "Edit Product"
          ) : (
            "Copy product"
          )
        ) : (
          <span className="flex justify-center items-center gap-2">
            <p>Copying product</p>
            <CustomLoader width="20px" />
          </span>
        )}
      </span>
    );
  };

  const handleCancel = () => {
    setOpModal({ open: false, productDetails: {} });
     handleCopyFromEnd();
  };
  const handleConfirm = async () => {
    setCopyLoading(true);

    if (newProductId) {
      navigate(`/edit-product/${newProductId}`);
    } else {
      copyProduct();
    }
  };

  const copyProduct = async () => {
    //calling api
    try {
      // Make API call to backend
      const response = await callBackendAPI(
        "store/product/copy",
        "POST",
        {},
        { payload: { id: productId } } // Pass your request body here
      );

      if (!response?.error && response?.product_id) {
        setCopyLoading(false);
        setNewProductId(response?.product_id);
        message.success("Product duplicate successfully!");
        setCopied(true);
        // handleCancel()
      } else {
        setCopyLoading(false);
        handleCopyFromEnd();
        handleCancel();
        return message.error(
          response?.message ||
            "Something went wrong while duplicate the product!"
        );
      }
    } catch (error) {
      setCopyLoading(false);
      handleCopyFromEnd();
      handleCancel();
      console.log("api failed:", error);
      return message.error(`api failed: ${error.message} `);
    } finally {
      //here
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirm();
    }
  };

  return (
    <div>
      <Modal closable={false} open={OpenModal} footer={false}>
        <div className="delete-product-modal-main-container !mb-0">
          <span className="product-copy-btn !border-violet-400 hover:!border-violet-700 !w-[60px] !h-[60px]">
            <CopyOutlined
              strokeWidth={2}
              className=" text-violet-700 text-4xl"
            />
          </span>
          <div className="delete-prduct-modal-content">
            <h4>Copy product</h4>
            {copied ? (
              <p>Continue to edit the product template?</p>
            ) : (
              <p>
                Do you really want to copy <b>{productName + " "}</b> into Sofia
                and Shopify?
              </p>
            )}
          </div>

          <div className="flex items-center gap-4 justify-center mt-4">
            <button
              onClick={handleCancel}
              type="button"
              className={`product-modal-cancel-button  ${
                copyLoading ? "product-modal-cancel-button-disabled" : ""
              }`}
              disabled={copyLoading}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              type="button"
              className={`modal-import-button  ${
                copyLoading ? "modal-import-button-disabled" : ""
              }`}
              disabled={copyLoading}
            >
              <ConfirmButton />
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CopyProductModal;

import React, { useEffect, useState } from "react";
//import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { setColorPaletteColors} from "../../../../redux/slices/template-slice";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker, Space } from 'antd';
 
const ColorsPicker = ({
  title,
  name,
  defaultValue

}) => {
  const dispatch = useDispatch();


  
    const [color, setColor] = useState(
      defaultValue && defaultValue.startsWith('#')
        ? defaultValue // If defaultValue is in hex format
        : `rgb(${defaultValue ? defaultValue.split(',').join(' ') : ''})` // If defaultValue is in RGB format
    );

    const rgbToHex = (rgb) => {
      if (rgb.includes("#")) {
        return rgb;
      } else {
        const result = rgb.match(/\d+/g);
        return result
          ? `#${result.map((x) => (+x).toString(16).padStart(2, "0")).join("")}`
          : "#ffffff";
      }
    };

    useEffect(() => {
      if( color.metaColor){
        let clr= color.metaColor
        let rgb = `rgb(${parseInt(clr?.r)},${parseInt(clr?.g)},${parseInt(clr?.b)})`
        if(name=='testimonial_section_heading_color' || name=='testimonial_section_background'){
            dispatch(setColorPaletteColors({ [name]:rgbToHex(rgb) }))
        }else{
            dispatch(setColorPaletteColors({ [name]:`${parseInt(clr?.r)},${parseInt(clr?.g)},${parseInt(clr?.b)}` }))
        }
      }
     },[color]);

  return (
    <>
     
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">{title}</label>
              <Space direction="vertical">
                  <ColorPicker defaultValue={color} showText onChangeComplete={setColor}   />
              </Space>
            </div>
          </div>
    </>
  )
}

export default ColorsPicker
